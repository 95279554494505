import { connect } from "react-redux";
import Hotels from "./Hotels";
import {
  getHotels,
  saveHotel,
  reorderHotel,
  unassignHotel,
  getOrgHotels,
  exportReservation,
} from "@actions/hotels";
import { showModal, hideModal, deleteItem } from "@actions";
import { addNotification } from "@actions";

const mapStateToProps = (state, ownProps) => {
  const mode = ownProps.hasOwnProperty("event") ? "eventHotels" : "orgHotels";
  return {
    eventId: mode === "eventHotels" ? ownProps.event.id : null,
    orgId:
      mode === "eventHotels" ? ownProps.event.orgId : state.appuser.data.orgId,
    hotels:
      mode === "eventHotels"
        ? state.api.hotels.list.data
        : state.api.hotels.orgList.data,
    meta:
      mode === "eventHotels"
        ? state.api.hotels.list.meta
        : state.api.hotels.orgList.meta,
    fetching:
      mode === "eventHotels"
        ? state.api.hotels.list.fetching
        : state.api.hotels.orgList.fetching,
    orgAdmin: state.appuser.data.orgAdmin ? true : false,
    mode,
    ready: state.appuser.ready,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHotels: (eventId, meta = { rpp: -1 }) =>
    dispatch(getHotels(eventId, meta)),
  hideModal: () => dispatch(hideModal()),
  showModal: (content) =>
    dispatch(
      showModal(
        content,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
    ),
  saveHotel: (
    orgId,
    data,
    hotelId = false,
    mode,
    endpoint,
    formData,
    orgMode
  ) => {
    dispatch(
      saveHotel(
        orgId,
        data,
        hotelId,
        mode,
        endpoint,
        formData,
        undefined,
        orgMode
      )
    );
  },
  exportReservation: (eventId, type) =>
    dispatch(exportReservation(eventId, type)),
  reorderHotel: (data, hotelId) => dispatch(reorderHotel(data, hotelId)),
  deleteItem: (orgId, hotelId, eventId) =>
    dispatch(deleteItem({ orgId, hotelId }, "hotels", eventId)),
  unassignHotel: (hotelId) => dispatch(unassignHotel(hotelId)),
  getOrgHotels: (orgId, meta) => {
    dispatch(getOrgHotels(orgId, meta));
  },
  addNotification: (msg, status) => dispatch(addNotification(msg, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);
