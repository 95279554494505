import { api } from "@actions/ajax";
import React from "react";
import ApiHelper from "@helpers/api-helper";
import { toast } from "react-toastify";
import PrinterHelper from "@helpers/printer-helper";
import { toggleFullScreen, isObject, downloadFile } from "@helpers";
import {
  changeSection,
  changeTab,
  resetMenu,
  CHANGE_SECTION,
  restoreSections,
} from "@actions/editmenu";
import { newNotification, getUser } from "@actions/appuser";
import accessLevels from "@config/accessLevels";
import { api as configuration } from "@config/conf";
import notificationObj from "@helpers/notification-object";
import { rootLevelObject } from "@helpers";
import { printCard } from "@actions/users";
import { getOrgHotels, getHotels } from "@actions/hotels";
import { getParticipants, SETUP_POLICY } from "./users";
import Notification from "../components/layout/Notification";
import { createConditionFilter } from "../helpers";

export const SET_REQUEST_CRITERIA = "SET_REQUEST_CRITERIA";
export const PAYMENTS_GET_STATISTICS = "PAYMENTS_GET_STATISTICS";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const UPDATING_TAB = "UPDATING_TAB";
export const TAB_UPDATED = "TAB_UPDATED";
export const SET_FILTERS = "SET_FILTERS";
export const SAVE_POLICY = "SAVE_POLICY";
export const MODAL_HIDED = "MODAL_HIDED";
export const IMPORT_USERS = "IMPORT_USERS";
export const CREATE_USER = "CREATE_USER";
export const SETUP_USERS = "SETUP_USERS";
export const SAVE_PRINTING_SETTINGS = "SAVE_PRINTING_SETTINGS";
export const TOGGLE_FULL_SCREEN = "TOGGLE_FULL_SCREEN";
export const USERS_IMPORTED = "USERS_IMPORTED";
export const SELECT_PRINTER = "SELECT_PRINTER";
export const PRINTER_DELETED = "PRINTER_DELETED";
export const EVENT_CREATED = "EVENT_CREATED";
export const ITEM_DELETED = "ITEM_DELETED";
export const SETUP_PRINTERS = "SETUP_PRINTERS";
export const DYMO_FRAMEWORK_ERROR = "DYMO_FRAMEWORK_ERROR";
export const FORM_CHANGED = "FORM_CHANGED";
export const PROMPT_HAS_CHANGES = "PROMPT_HAS_CHANGES";
export const HIDE_PROMPT_STAY = "HIDE_PROMPT_STAY";
export const HIDE_PROMT_PROCEED = "HIDE_PROMPT_PROCEED";
export const FORM_CANCELLED = "FORM_CANCELLED";
export const CLEAR_CHANGES = "CLEAR_CHANGES";
export const SHOW_NORMAL_MODAL = "SHOW_NORMAL_MODAL";
export const HIDE_NORMAL_MODAL = "HIDE_NORMAL_MODAL";
export const REGISTER_EVENT_ACCESS = "REGISTER_EVENT_ACCESS";
export const UNAUTHORIZED_ACCESS = "UNAUTHORIZED_ACCESS";
export const RESTRICT_MENU = "RESTRICT_MENU";
export const MENU_FULL_ACCESS = "MENU_FULL_ACCESS";
export const CHANGE_PRINTER_TYPE = "CHANGE_PRINTER_TYPE";
export const SET_SELECTED_POLICY = "SET_SELECTED_POLICY";

// export const SELECT_HOOK = 'SELECT_HOOK';

//////////// OLD CONSTANTS ///////////

export const SET_ADVANCED_SEARCH_OPTIONS = "SET_ADVANCED_SEARCH_OPTIONS";
export const APPLY_FILTER = "APPLY_FILTER";
export const LOG_USER = "LOG_USER";
export const EDIT_EVENT = "EDIT_EVENT";
export const EDIT_EVENT_FIELD = "EDIT_EVENT_FIELD";
export const EDIT_EVENT_POLICY_FIELD = "EDIT_EVENT_POLICY_FIELD";
export const EVENT_UPDATED = "EVENT_UPDATED";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
export const CONTENT_LOADED = "CONTENT_LOADED";
export const ADD_FILE_TO_QUEUE = "ADD_FILE_TO_QUEUE";
export const REMOVE_FILE_FROM_QUEUE = "REMOVE_FILE_FROM_QUEUE";
export const EMPTY_QUEUE = "EMPTY_QUEUE";
export const CHANGE_FILE_INPUT = "CHANGE_FILE_INPUT";
export const CHARTS_CREATED = "CHARTS_CREATED";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const CREATE_FORM = "CREATE_FORM";
export const UPDATE_FORM = "UPDATE_FORM";
export const CLEAR_FORM = "CLEAR_FORM";
export const POLICY_DELETED = "POLICY_DELETED";
export const POLICY_ADDED = "POLICY_ADDED";
export const SHOW_ACTIONBAR = "SHOW_ACTIONBAR";
export const HIDE_ACTIONBAR = "HIDE_ACTIONBAR";
export const VALIDATE_FORM = "VALIDATE_FORM";
export const SORT_EVENTS = "SORT_EVENTS";
export const FILTER_EVENTS = "FILTER_EVENTS";
export const FILTERS_ACTIVE = "FILTERS_ACTIVE";
export const FILTERS_INACTIVE = "FILTERS_INACTIVE";
export const ENABLE_NATIVE_PRINTING = "ENABLE_NATIVE_PRINTING";
export const DISABLE_NATIVE_PRINTING = "DISABLE_NATIVE_PRINTING";

export const setAdvancedSearchOptions =
  (eventId, policyId, options) => (dispatch) =>
    dispatch({ type: SET_ADVANCED_SEARCH_OPTIONS, eventId, policyId, options });

export const duplicateEvent = (eventId, data, endpoint = "events") => {
  return (dispatch) => {
    const request = {
      endpoint,
      action: "duplicate",
      params: {
        eventId,
      },
      body: { data },
    };
    return dispatch(api(request)).then(() => dispatch(getUser()));
  };
};

export const getClientPanelSettings = (eventId, section) => (dispatch) => {
  const request = {
    extra: { section },
    endpoint: "events",
    action: "getClientPanelSettings",
    params: {
      eventId,
    },
  };

  return dispatch(api(request));
};

export const updateClientPanelSettings =
  (eventId, data, section, sectionId = "", preserveModal = false) =>
  (dispatch) => {
    const filesToUpload = {
      clientHomeSponsorImage: Object.assign([], data.clientHomeSponsorImage),
      clientFooterSponsorImage: Object.assign(
        [],
        data.clientFooterSponsorImage
      ),
      clientVideoSponsorImage: Object.assign([], data.clientVideoSponsorImage),
      clientHomeJumbotron: Object.assign([], data.clientHomeJumbotron),
      clientHomeForegroundLogo: Object.assign(
        [],
        data.clientHomeForegroundLogo
      ),
      clientEbagImage: Object.assign([], data.clientEbagImage),
      clientEbagBrochure: Object.assign([], data.clientEbagBrochure),
      clientSponsorImage: Object.assign([], data.clientSponsorImage),
    };

    if (
      data.clientHomeSponsorImage?.length > 0 &&
      typeof data.clientHomeSponsorImage[0] !== "number"
    ) {
      data.clientHomeSponsorImage[0] = data.clientHomeSponsorImage[0].name;
    }

    if (
      data.clientVideoSponsorImage &&
      typeof data.clientVideoSponsorImage[0] !== "number"
    ) {
      data.clientVideoSponsorImage[0] = data.clientVideoSponsorImage[0].name;
    }
    if (
      data.clientFooterSponsorImage &&
      typeof data.clientFooterSponsorImage[0] !== "number"
    ) {
      data.clientFooterSponsorImage[0] = data.clientFooterSponsorImage[0].name;
    }

    if (
      data.clientHomeJumbotron &&
      typeof data.clientHomeJumbotron[0] !== "number"
    ) {
      data.clientHomeJumbotron[0] = data.clientHomeJumbotron[0].name;
    }

    if (
      data.clientHomeForegroundLogo &&
      typeof data.clientHomeForegroundLogo[0] !== "number"
    ) {
      data.clientHomeForegroundLogo[0] = data.clientHomeForegroundLogo[0].name;
    }

    if (data.clientEbagImage && typeof data.clientEbagImage[0] !== "number") {
      data.clientEbagImage[0] = data.clientEbagImage[0].name;
    }

    if (
      data.clientEbagBrochure &&
      typeof data.clientEbagBrochure[0] !== "number"
    ) {
      data.clientEbagBrochure[0] = data.clientEbagBrochure[0].name;
    }

    if (
      data.clientSponsorImage &&
      typeof data.clientSponsorImage[0] !== "number"
    ) {
      data.clientSponsorImage[0] = data.clientSponsorImage[0].name;
    }

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    if (section === "home") {
      data.homeShowNote = Boolean(data.homeShowNote);
      data.homeShowKeySpeakers = Boolean(data.homeShowKeySpeakers);
      data.homeShowPromoVideo = Boolean(data.homeShowPromoVideo);
      data.homeShowSubscriptions = Boolean(data.homeShowSubscriptions);
      data.homeShowSponsors = Boolean(data.homeShowSponsors);
    }

    const request = {
      preventNotification: true,
      extra: { preserveModal, type: section },
      endpoint: "events",
      action: "updateClientPanelSettings",
      params: {
        eventId,
        section,
        sectionId,
      },
      body: {
        data,
      },
    };

    const callback = () => {
      dispatch(getClientPanelSettings(eventId, section));
      return data;
    };
    if (!hasFiles) {
      return dispatch(api(request))
        .then((data) => {
          dispatch(addNotification(`Updated successfully.`, "success"));
          dispatch(getClientPanelSettings(eventId, section));
          return data;
        })
        .catch(() => {
          dispatch(getClientPanelSettings(eventId, section));
        });
    } else {
      return dispatch(api(request))
        .then((data) => {
          const promiseArr = [];
          Object.keys(filesToUpload).forEach((key) => {
            if (
              filesToUpload[key][0] &&
              typeof filesToUpload[key][0] !== "number"
            ) {
              const uploadParams = {};
              if (section === "videos-sponsors") {
                uploadParams["videoSponsorId"] = sectionId;
              } else if (section === "footer-sponsors") {
                uploadParams["footerSponsorId"] = sectionId;
              } else if (section === "ebag") {
                uploadParams["ebagId"] = sectionId;
              } else if (section === "sponsors") {
                uploadParams["sponsorId"] = sectionId;
              } else if (section === "home-sponsors") {
                uploadParams["clientHomeSponsorId"] = sectionId;
              }
              promiseArr.push(
                dispatch(
                  uploadFile(
                    eventId,
                    key,
                    filesToUpload[key][0],
                    uploadParams,
                    callback,
                    () => {
                      dispatch(getClientPanelSettings(eventId, section));
                    },
                    filesToUpload[key].length
                  )
                )
              );
            }
          });

          Promise.all(promiseArr)
            .then(() => {
              dispatch(getClientPanelSettings(eventId, section));
              dispatch(addNotification(`Updated successfully.`, "success"));
              callback();
            })
            .catch((err) => {
              console.warn(err);
            });
          return data;
        })
        .catch(() => {
          dispatch(getClientPanelSettings(eventId, section));
        });
    }
  };

export const deleteClientPanelVideoSponsor =
  (eventId, videoSponsorId) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "deleteClientPanelVideoSponsor",
      params: {
        eventId,
        videoSponsorId,
      },
      body: {
        data: {},
      },
    };

    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Video Sponsor deleted successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };
export const updateClientPanelVideoSponsorOrder =
  (eventId, data) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "updateClientPanelVideoSponsorOrder",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(
            `Video Sponsor order updated successfully.`,
            "success"
          )
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const createClientPanelVideoSponsor = (eventId, data) => (dispatch) => {
  const filesToUpload = {
    clientVideoSponsorImage: Object.assign([], data.clientVideoSponsorImage),
  };

  if (
    data.clientVideoSponsorImage &&
    typeof data.clientVideoSponsorImage[0] !== "number"
  ) {
    data.clientVideoSponsorImage[0] = data.clientVideoSponsorImage[0].name;
  }

  const filesToUploadProps = Object.keys(filesToUpload);
  let hasFiles = false;
  filesToUploadProps.forEach((key) => {
    if (filesToUpload[key]?.length > 0) hasFiles = true;
  });

  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "createClientPanelVideoSponsor",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  if (!hasFiles) {
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Video Sponsor created successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  } else {
    return dispatch(api(request))
      .then((data) => {
        const promiseArr = [];
        Object.keys(filesToUpload).forEach((key) => {
          if (filesToUpload[key][0]) {
            promiseArr.push(
              dispatch(
                uploadFile(
                  eventId,
                  key,
                  filesToUpload[key][0],
                  { videoSponsorId: data.data.id },
                  () => {
                    dispatch(getClientPanelSettings(eventId));
                  },
                  () => {
                    dispatch(getClientPanelSettings(eventId));
                    dispatch(
                      addNotification(
                        "An error occured while uploading your files",
                        "error"
                      )
                    );
                  },
                  filesToUpload[key].length
                )
              )
            );
          }
        });

        Promise.all(promiseArr)
          .then(() => {
            dispatch(
              addNotification(`Video Sponsor created successfully.`, "success")
            );
          })
          .catch((err) => {
            console.warn(err);
          });
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  }
};
export const deleteClientPanelFooterSponsor =
  (eventId, footerSponsorId, silent = false) =>
  (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "deleteClientPanelFooterSponsor",
      params: {
        eventId,
        footerSponsorId,
      },
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then((data) => {
        if (!silent) {
          dispatch(
            addNotification(`Footer Sposnor deleted successfully.`, "success")
          );
        }
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const createClientPanelFooterSponsor = (eventId, data) => (dispatch) => {
  const filesToUpload = {
    clientFooterSponsorImage: Object.assign([], data.clientFooterSponsorImage),
  };

  if (
    data.clientFooterSponsorImage &&
    typeof data.clientFooterSponsorImage[0] !== "number"
  ) {
    data.clientFooterSponsorImage[0] = data.clientFooterSponsorImage[0].name;
  }

  const filesToUploadProps = Object.keys(filesToUpload);
  let hasFiles = false;
  filesToUploadProps.forEach((key) => {
    if (filesToUpload[key]?.length > 0) hasFiles = true;
  });

  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "createClientPanelFooterSponsor",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  if (!hasFiles) {
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Footer Sponsor created successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  } else {
    return dispatch(api(request))
      .then((data) => {
        Object.keys(filesToUpload).forEach((key) => {
          if (filesToUpload[key][0]) {
            dispatch(
              uploadFile(
                eventId,
                key,
                filesToUpload[key][0],
                { footerSponsorId: data.data.id },
                () => {
                  dispatch(
                    addNotification(
                      `Footer Sponsor created successfully.`,
                      "success"
                    )
                  );
                  dispatch(getClientPanelSettings(eventId));
                },
                () => {
                  dispatch(
                    deleteClientPanelFooterSponsor(eventId, data.data.id, true)
                  );
                  dispatch(getClientPanelSettings(eventId));
                },
                filesToUpload[key].length
              )
            );
          }
        });
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  }
};
export const updateClientPanelFooterSponsorOrder =
  (eventId, data) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "updateClientPanelFooterSponsorOrder",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(
            `Footer Sponsor order updated successfully.`,
            "success"
          )
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const deleteClientPanelEbag = (eventId, ebagId) => (dispatch) => {
  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "deleteClientPanelEbag",
    params: {
      eventId,
      ebagId,
    },
    body: {
      data: {},
    },
  };
  return dispatch(api(request))
    .then((data) => {
      dispatch(addNotification(`Ebag item deleted successfully.`, "success"));
      dispatch(getClientPanelSettings(eventId));
      return data;
    })
    .catch(() => {
      dispatch(getClientPanelSettings(eventId));
    });
};

export const createClientPanelEbag = (eventId, data) => (dispatch) => {
  const filesToUpload = {
    clientEbagBrochure: Object.assign([], data.clientEbagBrochure),
    clientEbagImage: Object.assign([], data.clientEbagImage),
  };

  if (
    data.clientEbagBrochure &&
    typeof data.clientEbagBrochure[0] !== "number"
  ) {
    data.clientEbagBrochure[0] = data.clientEbagBrochure[0].name;
  }
  if (data.clientEbagImage && typeof data.clientEbagImage[0] !== "number") {
    data.clientEbagImage[0] = data.clientEbagImage[0].name;
  }

  const filesToUploadProps = Object.keys(filesToUpload);
  let hasFiles = false;
  filesToUploadProps.forEach((key) => {
    if (filesToUpload[key]?.length > 0) hasFiles = true;
  });

  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "createClientPanelEbag",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  const callback = (data) => {
    dispatch(getClientPanelSettings(eventId));
    return data;
  };

  if (!hasFiles) {
    return dispatch(api(request))
      .then((data) => {
        return callback(data);
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  } else {
    return dispatch(api(request))
      .then((data) => {
        const promiseArr = [];
        Object.keys(filesToUpload).forEach((key) => {
          if (
            filesToUpload[key][0] &&
            typeof filesToUpload[key][0] !== "number"
          ) {
            promiseArr.push(
              dispatch(
                uploadFile(
                  eventId,
                  key,
                  filesToUpload[key][0],
                  { ebagId: data.data.id },
                  callback,
                  () => {
                    dispatch(getClientPanelSettings(eventId));
                    dispatch(
                      addNotification(
                        "An error occured while uploading your files",
                        "error"
                      )
                    );
                  },
                  filesToUpload[key].length
                )
              )
            );
          }
        });

        Promise.all(promiseArr)
          .then(() => {
            callback();
            dispatch(
              addNotification(`Ebag item created successfully.`, "success")
            );
          })
          .catch((err) => {
            console.warn(err);
          });
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  }
};
export const updateClientPanelEbagOrder = (eventId, data) => (dispatch) => {
  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "updateClientPanelEbagOrder",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  return dispatch(api(request))
    .then((data) => {
      dispatch(
        addNotification(`Ebag item order updated successfully.`, "success")
      );
      dispatch(getClientPanelSettings(eventId));
      return data;
    })
    .catch(() => {
      dispatch(getClientPanelSettings(eventId));
    });
};
export const updateClientPanelSpeakersOrder = (eventId, data) => (dispatch) => {
  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "updateClientPanelSpeakersOrder",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  return dispatch(api(request))
    .then((data) => {
      dispatch(
        addNotification(`Speakers order updated successfully.`, "success")
      );
      dispatch(getClientPanelSettings(eventId));
      return data;
    })
    .catch(() => {
      dispatch(getClientPanelSettings(eventId));
    });
};
export const createClientPanelSponsor = (eventId, data) => (dispatch) => {
  const analyzed_data = { ...data };
  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "createClientPanelSponsor",
    extra: {
      withoutNotification: true,
    },
    params: {
      eventId,
    },
    body: {
      data: {
        eventClientPanelSponsorTypeId:
          analyzed_data.eventClientPanelSponsorTypeId,
        index: analyzed_data.index,
        url: analyzed_data.url,
      },
    },
  };
  return dispatch(api(request))
    .then((data) => {
      dispatch(
        uploadFile(
          eventId,
          "clientSponsorImage",
          analyzed_data.clientSponsorImage[0],
          { sponsorId: data.data.id },
          () => {
            dispatch(
              addNotification(`Sponsor created successfully.`, "success")
            );
            dispatch(getClientPanelSettings(eventId));
          },
          () => dispatch(deleteClientPanelSponsor(eventId, data.data.id, true)),
          analyzed_data.clientSponsorImage.length
        )
      );
      return data;
    })
    .then((data) => {
      return data;
    })
    .catch(() => {
      dispatch(getClientPanelSettings(eventId));
    });
};
export const updateClientPanelSponsorsOrder = (eventId, data) => (dispatch) => {
  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "updateClientPanelSponsorsOrder",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  return dispatch(api(request))
    .then((data) => {
      dispatch(
        addNotification(`Sponsor order updated successfully.`, "success")
      );
      dispatch(getClientPanelSettings(eventId));
      return data;
    })
    .catch(() => {
      dispatch(getClientPanelSettings(eventId));
    });
};
export const deleteClientPanelSponsor =
  (eventId, sponsorId, silent = false) =>
  (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "deleteClientPanelSponsor",
      params: {
        eventId,
        sponsorId,
      },
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then((data) => {
        if (!silent) {
          dispatch(addNotification(`Sponsor deleted successfully.`, "success"));
        }
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };
export const createClientPanelSponsorType = (eventId, data) => (dispatch) => {
  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "createClientPanelSponsorType",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };
  return dispatch(api(request))
    .then((data) => {
      dispatch(
        addNotification(`Sponsor Type created successfully.`, "success")
      );
      dispatch(getClientPanelSettings(eventId));
      return data;
    })
    .catch(() => {
      dispatch(getClientPanelSettings(eventId));
    });
};
export const deleteClientPanelSponsorType =
  (eventId, sponsorTypeId) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "deleteClientPanelSponsorType",
      params: {
        eventId,
        sponsorTypeId,
      },
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Sponsor Type deleted successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const updateClientPanelSponsorsTypesOrder =
  (eventId, data) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "updateClientPanelSponsorsTypesOrder",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Sponsor Type order updated successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const updateClientPanelSponsorsTypes = (eventId, data) => (dispatch) => {
  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "updateClientPanelSponsorsTypes",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  return dispatch(api(request)).then((data) => {
    dispatch(addNotification(`Sposor Type updated successfully.`, "success"));
    return data;
  });
};

export const deleteClientPanelHomeCallToAction =
  (eventId, callToActionId) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "deleteClientPanelHomeCallToAction",
      params: {
        eventId,
        callToActionId,
      },
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Call To Action deleted successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const createClientPanelHomeCallToAction =
  (eventId, data) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "createClientPanelHomeCallToAction",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Call To Action created successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };
export const updateClientPanelHomeCallToActionsOrder =
  (eventId, data) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "updateClientPanelHomeCallToActionsOrder",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(
            `Call To Action order updated successfully.`,
            "success"
          )
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const deleteClientPanelHomeSponsor =
  (eventId, sponsorId) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "deleteClientPanelHomeSponsor",
      params: {
        eventId,
        sponsorId,
      },
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Home Sponsor deleted successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const createClientPanelHomeSponsor = (eventId, data) => (dispatch) => {
  const filesToUpload = {
    clientHomeSponsorImage: Object.assign([], data.clientHomeSponsorImage),
  };

  if (
    data.clientHomeSponsorImage?.length > 0 &&
    typeof data.clientHomeSponsorImage[0] !== "number"
  ) {
    data.clientHomeSponsorImage[0] = data.clientHomeSponsorImage[0].name;
  }

  const filesToUploadProps = Object.keys(filesToUpload);
  let hasFiles = false;
  filesToUploadProps.forEach((key) => {
    if (filesToUpload[key]?.length > 0) hasFiles = true;
  });

  const request = {
    preventNotification: true,
    endpoint: "events",
    action: "createClientPanelHomeSponsor",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  if (!hasFiles) {
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Home Sponsor created successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  } else {
    return dispatch(api(request))
      .then((data) => {
        Object.keys(filesToUpload).forEach((key) => {
          if (filesToUpload[key][0]) {
            dispatch(
              uploadFile(
                eventId,
                key,
                filesToUpload[key][0],
                { clientHomeSponsorId: data.data.id },
                () => {
                  dispatch(
                    addNotification(
                      `Home Sponsor created successfully.`,
                      "success"
                    )
                  );
                  dispatch(getClientPanelSettings(eventId));
                },
                () => {
                  dispatch(getClientPanelSettings(eventId));
                },
                filesToUpload[key].length
              )
            );
          }
        });
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  }
};
export const updateClientPanelHomeSponsorsOrder =
  (eventId, data) => (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "updateClientPanelHomeSponsorsOrder",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification(`Home Sponsor order updated successfully.`, "success")
        );
        dispatch(getClientPanelSettings(eventId));
        return data;
      })
      .catch(() => {
        dispatch(getClientPanelSettings(eventId));
      });
  };

export const filtersActive = (active = true) => {
  return {
    type: active ? FILTERS_ACTIVE : FILTERS_INACTIVE,
  };
};

export const enableNativePrinting = (item, content, callback) => {
  return {
    type: ENABLE_NATIVE_PRINTING,
    item: item,
    content,
    callback,
  };
};

export const disableNativePrinting = () => {
  return (dispatch) => {
    dispatch({ type: DISABLE_NATIVE_PRINTING });
  };
};

export const hideNormalModal = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_NORMAL_MODAL });
  };
};

export const showNormalModal = (
  content,
  title = "",
  footer = false,
  className = ""
) => {
  return (dispatch, getState) => {
    const state = getState();

    const modal = {
      type: SHOW_NORMAL_MODAL,
      title,
      content,
      footer,
      className,
    };

    if (state.page.normalModal.content !== null) {
      setTimeout(() => {
        dispatch(modal);
      }, 400);
    } else {
      dispatch(modal);
    }
  };
};

export const promptChanges = (page, changeType = "page", ce = false) => {
  return {
    type: PROMPT_HAS_CHANGES,
    changeType,
    section: null,
    tab: null,
    page,
    ce,
  };
};

export const clearChanges = () => {
  return {
    type: CLEAR_CHANGES,
  };
};

export const confirmChanges = (proceed) => {
  return (dispatch, getState) => {
    if (proceed) {
      const state = getState();
      const tempRoute = state.page.tempRoute;

      switch (tempRoute.changeType) {
        case "section": {
          dispatch({ type: HIDE_PROMT_PROCEED });
          const { activeSection, activeTab } = state.editmenu;
          if (activeSection === "basic_info" && activeTab === "Options") {
            dispatch(restoreSections(state.api.events.edit.data));
          }
          dispatch({
            type: CHANGE_SECTION,
            section: tempRoute.section,
            tab: tempRoute.tab,
          });
          break;
        }

        case "page": {
          dispatch({ type: HIDE_PROMT_PROCEED });
          dispatch(
            changePage({
              title: tempRoute.title,
              pathname: tempRoute.pathname,
              params: tempRoute.params,
              navigate: tempRoute.navigate,
            })
          );
          break;
        }

        case "modal": {
          dispatch({ type: HIDE_PROMT_PROCEED });
          dispatch(hideModal());
          break;
        }

        case "tab": {
          dispatch({ type: HIDE_PROMT_PROCEED });
          dispatch(changeTab(tempRoute.tab));
          break;
        }
      }
    } else {
      dispatch({
        type: HIDE_PROMPT_STAY,
      });
    }
  };
};

export const formCancelled = (form) => {
  return {
    type: FORM_CANCELLED,
    form,
  };
};

export const formChanged = (form) => {
  return (dispatch) => {
    dispatch({
      type: FORM_CHANGED,
      form,
    });
  };
};

export const getHits = (eventId, policyId, userId) => (dispatch) => {
  const request = {
    endpoint: "users",
    action: "getHits",
    params: { eventId, policyId, userId },
    query: `?rpp=-1`,
  };

  dispatch(api(request));
};

export const loadScans = (
  eventId,
  p = "1",
  endpoint = "events",
  rpp = "50",
  queryParams = ""
) => {
  return (dispatch, getState) => {
    const request = {
      endpoint,
      action: "totalScans",
      params: {
        eventId,
      },
      query: `?rpp=${rpp}&p=${p}`,
      cache: false,
    };

    if (queryParams) {
      request.query += queryParams;
    }

    const state = getState();

    if (state.api.accesspolicies.list.data.length === 0) {
      dispatch(loadPolicies(eventId));
    }

    dispatch(api(request)).catch((err) => {
      console.warn(err);
    });
  };
};

export const loadStatistics = (eventId, socketFetch, endpoint = "events") => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: "statistics",
        params: {
          eventId,
        },
        cache: false,
      };

      if (socketFetch) {
        query.extra = { socketFetch };
      }

      dispatch(api(query))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.warn(err);
          reject(err);
        });
    });
  };
};

export const setupPrinters = (hideMessages = false) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const printerType = PrinterHelper.getPrinterType();

      PrinterHelper.init(printerType)
        .then((data) => {
          const status = PrinterHelper.getStatus(data);
          if (printerType === "generic") {
            const data = {
              printers: ["Generic"],
              selectedPrinter: "Generic",
              ready: true,
            };
            dispatch({
              type: SETUP_PRINTERS,
              data,
            });
            return resolve(data);
          }

          if (!status.success) {
            const state = getState();
            const exists =
              [...state.appuser.notifications].filter(
                (n) => n.id === DYMO_FRAMEWORK_ERROR
              )[0] || false;
            if (!exists) {
              const notification = notificationObj.init(
                {
                  type: "danger",
                  title: "Dymo Printer Error",
                  message: status.messages.join(","),
                  date: new Date(),
                  id: DYMO_FRAMEWORK_ERROR,
                  resolveCallback: () => {
                    dispatch(changeSection("settings"));
                    dispatch(changeTab("Printers"));
                  },
                },
                state
              );
              dispatch(newNotification(notification));
            }

            if (!hideMessages) {
              status.messages.map((m) => dispatch(addNotification(m, "error")));
            }

            if (status.errorCodes.includes(1)) {
              localStorage.removeItem("selectedPrinter");
            }
          }

          dispatch({
            type: SETUP_PRINTERS,
            data,
          });

          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: DYMO_FRAMEWORK_ERROR,
            error,
          });

          const state = getState();
          const exists =
            [...state.appuser.notifications].filter(
              (n) => n.id === DYMO_FRAMEWORK_ERROR
            )[0] || false;
          if (!exists) {
            const notification = notificationObj.init(
              {
                type: "danger",
                title: "Dymo Framework is not installed.",
                message: 'Please click "Download", to get the latest version',
                date: new Date(),
                dismissable: false,
                resolveLabel: "Download",
                id: DYMO_FRAMEWORK_ERROR,
                resolveCallback: () => {
                  const { dymoFrameworkUrl } = configuration;
                  downloadFile(dymoFrameworkUrl, "Dymo Label Framework");
                },
              },
              state
            );
            dispatch(newNotification(notification));
          }
          reject(error);
        });
    });
  };
};

export const deleteItem = (params, endpoint, eventId) => {
  return (dispatch) => {
    const query = {
      endpoint,
      action: "delete",
      params,
    };

    return dispatch(api(query))
      .then((response) => {
        if (response.data.deleted === 1) {
          dispatch({
            type: ITEM_DELETED,
            itemName: ApiHelper.getNameByEndpoint(endpoint),
            endpoint,
            itemId: ApiHelper.getItemIdByMeta(response.meta),
          });
        }
      })
      .then(() => {
        dispatch(addNotification("Deleted successfully"));
        if (endpoint === "events") {
          dispatch(loadEvents());
        } else if (endpoint === "hotels") {
          dispatch(getOrgHotels(params.orgId));
          if (eventId) {
            dispatch(getHotels(eventId));
          }
        }
      })
      .catch((err) => console.warn(err));
  };
};

export const createEvent = (data, endpoint = "events") => {
  const value = Object.assign({}, data);

  if (value.hasOwnProperty("duration")) {
    value["startDate"] = `${value.duration.from} ${value.duration.fromTime}:00`;
    value["endDate"] = `${value.duration.to} ${value.duration.toTime}:00`;

    delete value.duration;
  }

  return (dispatch) => {
    const query = {
      endpoint,
      action: "create",
      body: {
        data: value,
      },
    };

    dispatch(api(query))
      .then((response) => {
        dispatch({
          type: EVENT_CREATED,
          event: response.data,
        });
        dispatch(hideModal());
      })
      .catch((err) => console.warn(err));
  };
};

export const changePrinterType = (printerType) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_PRINTER_TYPE,
      printerType,
    });
    PrinterHelper.changePrinterType(printerType);
    if (printerType === "generic") {
      dispatch(selectPrinter("Generic"));
    } else {
      dispatch(selectPrinter(""));
    }
  };
};

export const selectPrinter = (printer, notFound) => {
  return (dispatch) => {
    let message = "",
      className = "warning";

    if (notFound) {
      localStorage.removeItem("selectedPrinter");
      message =
        "The previously selected printer was not found on your system. Please select a printer.";
      dispatch({
        type: PRINTER_DELETED,
      });
    } else {
      if (printer !== "") {
        localStorage.setItem("selectedPrinter", printer);
        message = `Printer ${printer} is now the selected printer.`;
        className = "success";
        dispatch({
          type: SELECT_PRINTER,
          printer,
        });
      } else {
        localStorage.removeItem("selectedPrinter");
        message =
          "Selected printer deleted successfully. Please select a printer.";
        dispatch({
          type: PRINTER_DELETED,
        });
      }
    }

    dispatch(addNotification(message, className));
  };
};

export const fullScreen = () => {
  return (dispatch) => {
    toggleFullScreen();
    dispatch({ type: TOGGLE_FULL_SCREEN });
  };
};

export const savePrintingSettings = (
  data,
  key,
  eventId,
  files = false,
  bannerId,
  certOrCard = "card"
) => {
  return (dispatch) => {
    delete data.certificationImages;
    let obj = { [key]: JSON.stringify(data) };

    if (files) {
      obj[
        certOrCard === "cert" ? "certificationImages" : "cardPrintingBanners"
      ] = files;
    }
    if (files === "delete") {
      obj = {};
      obj[
        certOrCard === "cert" ? "certificationImages" : "cardPrintingBanners"
      ] = [bannerId];
      dispatch(updateEventDelete(eventId, obj));
    } else {
      dispatch(updateEvent(eventId, obj));
    }
  };
};

export const importUsers = (info, policyId, eventId) => {
  const endpoint = "users";

  return (dispatch) => {
    dispatch({
      type: IMPORT_USERS,
    });
    const query = {
      endpoint,
      action: "bulkImport",
      params: {
        policyId,
        eventId,
      },
      body: {
        fieldsName: info.fields.join(","),
        users: info.file,
        ignoreFirstRow: info.ignoreFirstRow ? 1 : 0,
      },
      contentType: "multipart/form-data",
    };

    if (info.seperator) {
      query.body["splitChar"] = info.seperator === "comma" ? "," : ";";
    }

    dispatch(api(query))
      .then((response) => {
        dispatch({
          type: USERS_IMPORTED,
        });
        dispatch(
          addNotification(
            `${response.data.numberOfUsers} users imported successfully.`,
            "success"
          )
        );
        return dispatch(
          getParticipants({
            eventId,
            policyId,
            query: {
              info: true,
              currentRoom: true,
              minutes: true,
              points: true,
              abstracts: true,
              withAbstracts: true,
              accomodation: true,
              products: true,
              subscriptions: true,
              questionnaires: true,
            },
          })
        );
      })
      .catch((err) => console.warn(err));
  };
};

export const loadEvents = (
  criteria,
  filters = {},
  customArgs = { action: "list" },
  additional = false,
  cache = true
) => {
  const endpoint = "events";
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let state = getState();
      const existingCriteria = state.api.events.list.meta;
      let buildCriteria = Object.assign({}, existingCriteria, criteria);
      buildCriteria = ApiHelper.normalizeCriteria(buildCriteria);
      const { p, rpp, orderBy, order, search, archived } = buildCriteria;
      if (cache) {
        for (const [key, c] of Object.entries(criteria ?? {})) {
          if (
            existingCriteria.hasOwnProperty(key) &&
            existingCriteria[key] !== c
          ) {
            cache = false;
          }
        }
      }

      let query = `?rpp=${rpp}&p=${p}&orderBy=${orderBy}&order=${order}&search=${search}&archived=${archived}`;
      dispatch(setCriteria(endpoint, customArgs.action, buildCriteria));

      if (Object.keys(filters).length > 0) {
        dispatch(setFilters(filters));
        const buildFilters = ApiHelper.normalizeFilters(
          getState().api.events.list.filters
        );
        query += buildFilters;
        cache = false;
      }

      dispatch(
        api({
          endpoint,
          action: customArgs.action,
          extra: { additional },
          query,
          cache: false,
        })
      )
        .then((data) => {
          resolve(data);
          state = getState();
          if (!state.page.contentLoaded) {
            dispatch(contentLoaded());
          }
        })
        .catch((err) => {
          console.warn(err);
          reject(err);
        });
    });
  };
};

export const setFilters = (filters) => {
  return {
    type: SET_FILTERS,
    filters,
  };
};

export const setupMenu = (accessRole) => {
  return (dispatch, getState) => {
    if (!accessLevels.hasOwnProperty(accessRole.role)) {
      return dispatch(unauthorizedAccess());
    }

    const role = accessLevels[accessRole.role];
    if (!role.hasOwnProperty("editmenu")) {
      return dispatch(unauthorizedAccess());
    }

    const access = role.editmenu;

    if (isObject(access)) {
      // calculate access
      dispatch({
        type: RESTRICT_MENU,
        access,
      });
    } else if (access === 1) {
      // full access
      const state = getState();
      let activeSection,
        activeTab = null;
      switch (state.page.path) {
        case "/events/:id/statistics": {
          activeSection = "statistics";
          activeTab = "Statistics";
          break;
        }
      }

      const action = { type: MENU_FULL_ACCESS };
      if (activeSection && activeTab) {
        action.activeSection = activeSection;
        action.activeTab = activeTab;
      }

      action.activeSection = state.editmenu.activeSection;

      dispatch(action);
    }
  };
};

export const unauthorizedAccess = () => {
  return (dispatch, getState) => {
    dispatch({
      type: UNAUTHORIZED_ACCESS,
    });
    getState().page.navigate(`/adminstrator/events`);
  };
};

export const determineAccess = (eventId) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const user = state.appuser.data;

      if (!user.accessEvents.hasOwnProperty(eventId)) {
        dispatch(unauthorizedAccess());
        return reject(new Error("Unauthorized Access"));
      }
      dispatch({
        type: REGISTER_EVENT_ACCESS,
        eventAccess: user.accessEvents[eventId],
      });
      dispatch(setupMenu(user.accessEvents[eventId]));
      resolve(true);
    });
  };
};

export const loadEvent = (eventId, endpoint = "events", action = "edit") => {
  return (dispatch) => {
    dispatch(
      api({
        endpoint,
        action,
        params: {
          section: "info",
          eventId,
        },
        cache: false,
      })
    )
      .then(() => {
        const promiseArr = [
          dispatch(determineAccess(eventId)),
          dispatch(loadPolicies(eventId)),
        ];
        return Promise.all(promiseArr);
      })
      .then(([, policies]) => {
        const { id } = policies.data.filter((policy) => policy.default)[0];
        dispatch(setSelectedPolicy(id));
        return dispatch(contentLoaded());
      })
      .catch((e) => {
        console.error("unauthorized access", e);
      });
  };
};

export const loadEventPayments = (
  eventId,
  endpoint = "events",
  action = "editPayments"
) => {
  return (dispatch) => {
    dispatch(
      api({
        endpoint,
        action,
        params: {
          section: "info",
          eventId,
        },
      })
    );
  };
};

export const updateEvent = (
  eventId,
  data,
  _, // contentType = "application/json",
  endpoint = "events"
) => {
  const event = Object.assign({}, data);
  if (event.hasOwnProperty("duration")) {
    event["startDate"] = `${event.duration.from} ${event.duration.fromTime}:00`;
    event["endDate"] = `${event.duration.to} ${event.duration.toTime}:00`;

    delete event.duration;
  }

  if (event.collaborator == null) delete event.collaborator;
  if (event.paypalAccount == null) delete event.paypalAccount;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: UPDATING_TAB });

      const filesToUpload = {
        banners: Object.assign([], data.banners),
        scanningBanners: Object.assign([], data.scanningBanners),
        cardPrintingBanners: Object.assign([], data.cardPrintingBanners),
        emailHeaderBanners: Object.assign([], data.emailHeaderBanners),
        certificationImages: Object.assign([], data.certificationImages),
        scheduleFile: Object.assign([], data.scheduleFile),
      };

      if (
        event.scanningBanners?.length > 0 &&
        typeof event.scanningBanners[0] !== "number"
      ) {
        event.scanningBanners[0] = data.scanningBanners[0].name;
      }

      if (
        event.emailHeaderBanners?.length > 0 &&
        typeof event.emailHeaderBanners[0] !== "number"
      ) {
        event.emailHeaderBanners[0] = data.emailHeaderBanners[0].name;
      }

      if (
        event.scheduleFile?.length > 0 &&
        typeof event.scheduleFile[0] !== "number"
      ) {
        event.scheduleFile[0] = data.scheduleFile[0].name;
      }

      if (event.banners?.length > 0 && typeof event.banners[0] !== "number") {
        event.banners[0] = data.banners[0].name;
      }

      if (
        event.certificationImages?.length > 0 &&
        typeof event.certificationImages[0] !== "number"
      ) {
        event.certificationImages[0] = data.certificationImages[0].name;
      }

      if (
        event.cardPrintingBanners?.length > 0 &&
        typeof event.cardPrintingBanners[0] !== "number"
      ) {
        event.cardPrintingBanners[0] = data.cardPrintingBanners.name;
      }

      const query = {
        endpoint,
        action: "update",
        params: {
          section: "info",
          eventId,
        },
        body: {
          data: event,
        },
        cache: false,
      };

      const filesToUploadProps = Object.keys(filesToUpload);
      let hasFiles = false;
      filesToUploadProps.forEach((key) => {
        if (filesToUpload[key]?.length > 0) hasFiles = true;
      });

      if (!hasFiles) {
        return dispatch(api(query))
          .then((event) => {
            dispatch({ type: TAB_UPDATED, event });
            resolve(event);
          })
          .catch((err) => {
            console.warn(err);
            reject(new Error(err));
          });
      }

      filesToUploadProps.forEach((key) => {
        if (!filesToUpload[key] || typeof filesToUpload[key][0] === "number") {
          dispatch(api(query)).then((event) => {
            dispatch({ type: TAB_UPDATED, event });
            resolve(event);
          });
          return;
        }
        filesToUpload[key].forEach((file) => {
          dispatch(
            uploadFile(
              eventId,
              key,
              file,
              {},
              () => {
                dispatch(api(query)).then((event) => {
                  dispatch({ type: TAB_UPDATED, event });
                  resolve(event);
                });
              },
              null,
              filesToUpload[key].length
            )
          );
        });
      });
    });
  };
};

export const uploadFile = (
  eventId,
  action,
  file,
  params,
  callback = () => {},
  errorCallback = () => {},
  quantity,
  orgUpload = false,
  orgId
) => {
  return async (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "storage",
      action: orgUpload ? "orgPresignedURL" : "presignedURL",
      params: {
        eventId,
        orgId,
      },
      body: {
        data: {
          quantity,
          action,
          name: file.name,
          type: file.type,
          size: file.size,
          ...params,
        },
      },
    };
    return dispatch(api(request))
      .then(async (response) => {
        return dispatch(
          uploadWithUrl(
            response.data.url,
            file,
            params,
            eventId,
            action,
            callback,
            errorCallback,
            response.data.fileName,
            orgUpload,
            orgId
          )
        );
      })
      .catch((e) => {
        if (errorCallback) errorCallback(e);
      });
  };
};

export const uploadWithUrl = (
  url,
  file,
  save_params,
  eventId,
  action,
  callback,
  errorCallback,
  fileName,
  orgUpload,
  orgId
) => {
  return async (dispatch) => {
    const params = {
      method: "PUT",
      body: file,
    };
    return fetch(url, params)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Error: Something went wrong while uploading your file. Status - ${response.status}: ${response.statusText}`
          );
        }
        return dispatch(
          saveUploadedFile(
            eventId,
            save_params,
            action,
            file,
            callback,
            errorCallback,
            fileName,
            orgUpload,
            orgId
          )
        );
      })
      .catch((err) => {
        dispatch(addNotification(err.message, "error"));
        if (errorCallback) errorCallback(err);
      });
  };
};

export const saveUploadedFile = (
  eventId,
  params,
  action,
  file,
  callback,
  errorCallback,
  fileName,
  orgUpload,
  orgId
) => {
  return async (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "storage",
      action: orgUpload ? "orgSaveFile" : "saveFile",
      params: {
        eventId,
        orgId,
      },
      body: {
        data: {
          fieldName: action,
          size: file.size,
          type: file.type,
          name: fileName,
          ...params,
        },
      },
    };

    return dispatch(api(request))
      .then((res) => {
        if (callback) return callback(res);
      })
      .catch((e) => {
        if (errorCallback) errorCallback(e);
      });
  };
};

export const updateEventFeatureStatus = (eventId, feature, status) => {
  return (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: "events",
      action: "updateEventFeatureStatus",
      params: {
        eventId,
      },
      body: {
        data: { featureId: feature.id, status },
      },
    };
    return dispatch(api(request))
      .then((event) => {
        dispatch({ type: TAB_UPDATED, event });
        dispatch(
          addNotification(
            `Event feature ${feature.label} status has been updated to ${
              status ? `"enabled"` : `"disabled"`
            } successfully.`,
            "success"
          )
        );
      })
      .catch((err) => {
        dispatch(addNotification(err.message, "error"));
      });
  };
};

export const updateEventFeaturesOrder = (eventId, sortedFeatures) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        preventNotification: true,
        endpoint: "events",
        action: "updateEventFeaturesOrder",
        params: {
          eventId,
        },
        body: {
          data: { sortedFeatures },
        },
      };
      dispatch(api(request))
        .then((event) => {
          dispatch({ type: TAB_UPDATED, event });
        })
        .then((event) => {
          dispatch(
            addNotification(
              `Event feature order has been updated successfully.`,
              "success"
            )
          );
          resolve(event);
        })
        .catch((err) => {
          dispatch(addNotification(err.message, "error"));
          reject(err);
        });
    });
  };
};

export const updateEventDelete = (eventId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: UPDATING_TAB });

      const query = {
        endpoint: "events",
        action: "update",
        params: {
          section: "info",
          eventId,
        },
        body: {
          data,
        },
        cache: false,
      };

      dispatch(api(query))
        .then((event) => {
          dispatch({ type: TAB_UPDATED, event });
          resolve(event);
        })
        .catch((err) => {
          console.warn(err);
          reject(new Error(err));
        });
    });
  };
};
export const setCriteria = (endpoint, action, criteria) => {
  return {
    type: SET_REQUEST_CRITERIA,
    endpoint,
    action,
    criteria,
  };
};

export const changeEvent = () => {
  return (dispatch) => {
    dispatch(resetMenu());
  };
};

export const changePage = ({ title, pathname, params, navigate }) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.page.hasChanges) {
      return new Promise((resolve) => {
        dispatch({
          type: PROMPT_HAS_CHANGES,
          title,
          pathname,
          params,
          navigate,
          changeType: "page",
        });
        resolve(true);
      });
    } else {
      dispatch({
        type: CHANGE_PAGE,
        title,
        pathname,
        params,
        navigate,
      });
    }
  };
};

export const setSelectedPolicy = (policyId) => (dispatch, getState) => {
  const state = getState();
  const event = state.api.events.edit.data;
  const [policy] = state.api.accesspolicies.list.data.filter(
    (policy) => policy.id == policyId
  );

  dispatch({
    type: SET_SELECTED_POLICY,
    policyId,
  });

  dispatch({
    type: SETUP_POLICY,
    policy: { ...policy, eventId: event.id },
    event,
  });
};
export const loadPolicies = (eventId) => {
  const endpoint = "accesspolicies";

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: "list",
        params: {
          eventId,
        },
      };

      dispatch(api(query))
        .then((policies) => {
          resolve(policies);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };
};

export const savePolicy = (eventId, request, policyId) => {
  const endpoint = "accesspolicies";
  if (request.maxUsers == "") request.maxUsers = null;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: policyId ? "edit" : "create",
        params: {
          eventId,
        },
        body: {
          data: request,
        },
      };

      if (policyId) {
        query.params["policyId"] = policyId;
        localStorage.removeItem(`event_${eventId}_policy_${policyId}`);
      }
      return dispatch(api(query))
        .then((policies) => {
          const state = getState();
          const event = state.api.events.edit.data;
          const selectedPolicy = state.api.accesspolicies.selectedPolicy;

          dispatch({
            type: "SETUP_POLICY",
            policy: policies.data,
            event,
            reset: true,
          });
          if (selectedPolicy.id === policyId) {
            dispatch(setSelectedPolicy(policyId));
          }
          resolve(policies);
        })
        .catch((err) => reject(err));
    });
  };
};

export const createPolicyExtraSettingCondition =
  (eventId, policyId, item, groups) => (dispatch) => {
    const hasEmptyGroup =
      groups.filter((group) => {
        return group.length === 0;
      }).length > 0;
    if (hasEmptyGroup)
      return dispatch(addNotification("Please Select a condition", "error"));

    const dto = createConditionFilter(groups, []);
    return dispatch(
      savePolicy(
        eventId,
        {
          extraSettings: {
            [item.key]: {
              ...item,
              id: undefined,
              key: undefined,
              disabled: undefined,
              notUsed: undefined,
              conditionSchema: dto.length
                ? JSON.stringify({
                    name: "Default Name",
                    filter: {
                      filters: dto,
                      connectionType: "or",
                    },
                  })
                : null,
            },
          },
        },
        policyId
      )
    );
  };

export const createUser = (
  info,
  policyId,
  eventId,
  saveAndPrint = false,
  action = "create",
  selectedUserId = null
) => {
  const endpoint = "users";
  let data = {};
  if (selectedUserId !== null) {
    data = { info, orgCrmUserId: selectedUserId };
  } else {
    data = { info };
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Object.keys(info).forEach((key) => {
        if (info[key] === "") {
          delete info[key];
        }
      });
      if (data.info.crmUserProfilePhoto) {
        data.crmUserProfilePhoto = data.info.crmUserProfilePhoto;
        delete data.info.crmUserProfilePhoto;
      }

      const query = {
        endpoint,
        action,
        params: {
          policyId,
          eventId,
        },
        body: { data },
      };

      return dispatch(api(query))
        .then((user) => {
          if (saveAndPrint) {
            const rootObjectUser = rootLevelObject(user.data);
            dispatch(printCard(rootObjectUser)).then((printerType) =>
              resolve({ user: rootObjectUser, printerType })
            );
          } else {
            resolve({ user });
          }
          dispatch(hideModal());
        })
        .catch((err) => reject(err));
    });
  };
};

///////////////////////////////////////////// OLD ACTIONS //////////////////////

export const sortEvents = (sortby) => {
  return {
    type: SORT_EVENTS,
    sortby,
  };
};

export const validateFormThunk = (form) => {
  return (dispatch, getState) => {
    dispatch(validateForm(form));
    const state = getState().form;
    const response = {
      validated: state.validated,
      errors: state.errors,
    };
    return Promise.resolve(response);
  };
};

export const validateForm = (form) => {
  return {
    type: VALIDATE_FORM,
    form,
  };
};

export const showActionbar = (onSave, onDiscard) => {
  return {
    type: SHOW_ACTIONBAR,
    onSave,
    onDiscard,
  };
};

export const hideActionbar = () => {
  return {
    type: HIDE_ACTIONBAR,
  };
};

export const policyAdded = (policyData) => {
  return {
    type: POLICY_ADDED,
    policyData,
  };
};

export const policyDeleted = (policyID) => {
  return {
    type: POLICY_DELETED,
    policyID,
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const updateForm = (
  element,
  value,
  deepMerge = false,
  objectIndex = false
) => {
  return {
    type: UPDATE_FORM,
    element,
    value,
    deepMerge,
    objectIndex,
  };
};

export const createForm = (formData) => {
  return {
    type: CREATE_FORM,
    formData,
  };
};

export const hideModal = (checkForChanges = true) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.page.modal.showModal === false) {
      return;
    }
    if (!state.page.hasChanges || !checkForChanges) {
      dispatch({ type: HIDE_MODAL });
      setTimeout(() => {
        dispatch({ type: MODAL_HIDED });
      }, 300);
    } else {
      dispatch(promptChanges(null, "modal"));
      return;
    }
    state.page.modal.onHide && state.page.modal.onHide();
  };
};

export const showModal = (
  content,
  header,
  onHide,
  onSave = false,
  onExited = false,
  modalType = "sideModal",
  large = false,
  className = ""
) => {
  return (dispatch, getState) => {
    const state = getState();
    const modal = {
      type: SHOW_MODAL,
      content,
      header,
      onHide,
      onSave,
      onExited,
      modalType,
      large,
      className,
    };

    if (state.page.modal.content !== null) {
      setTimeout(() => {
        dispatch(modal);
      }, 400);
    } else {
      dispatch(modal);
    }
  };
};

export const prepareForm = (plainData) => {
  return (dispatch, getState) => {
    const data = new FormData();
    const state = getState();
    let excludeFields = [];
    if (state.filemanager.queue.length) {
      // handle files.
      for (const fileObj of state.filemanager.queue) {
        data.append(fileObj.entity, fileObj.file);
        excludeFields = [...excludeFields, fileObj.entity];
      }
    }

    // handle plain data

    for (const [name, value] of Object.entries(plainData)) {
      if (typeof value !== "object") {
        if (!excludeFields.includes(name)) data.append(name, value);
      }
    }

    return Promise.resolve(data);
  };
};

export const changeFileInput = (endpoint) => {
  return {
    type: CHANGE_FILE_INPUT,
    endpoint,
  };
};

export const emptyQueue = () => {
  return {
    type: EMPTY_QUEUE,
  };
};

export const addFileToQueue = (file) => {
  return {
    type: ADD_FILE_TO_QUEUE,
    file,
  };
};

export const addNotification = (message, status = "success") => {
  if (message === "Invalid Username or Password!") return { type: "false" };
  const isSuccess = status === "success";
  toast[status](<Notification message={message} />, {
    position: toast.POSITION.TOP_CENTER,
    icon: (
      <span
        className={isSuccess ? "icon-success" : "icon-notification-info-1"}
        style={{ fontSize: 20, color: isSuccess ? "#12b76a" : "#f04438" }}
      ></span>
    ),
  });

  return {
    type: "ADD_NOTIFICATION_TOAST",
    message,
    status,
  };
};

export const dismissNotification = (notification) => {
  return {
    type: DISMISS_NOTIFICATION,
    notification,
  };
};

export const contentLoaded = () => {
  return {
    type: CONTENT_LOADED,
    contentLoaded: true,
  };
};

export const chartsCreated = (data) => {
  return {
    type: CHARTS_CREATED,
    endpoint: "event",
    data,
  };
};

export const visibilityFilter = (filter, tableTitle) => {
  return {
    type: APPLY_FILTER,
    filter,
    tableTitle,
  };
};

export const logUser = (user) => {
  return {
    type: LOG_USER,
    user,
  };
};

export const editEvent = (event) => {
  return {
    type: EDIT_EVENT,
    event,
  };
};

export const editEventField = (name, value) => {
  return {
    type: EDIT_EVENT_FIELD,
    endpoint: "event",
    name,
    value,
  };
};

export const editEventPolicy = (policyIndex, name, value) => {
  return {
    type: EDIT_EVENT_POLICY_FIELD,
    endpoint: "event",
    name,
    value,
    policyIndex,
  };
};

export const eventUpdated = (files) => {
  return {
    type: EVENT_UPDATED,
    endpoint: "event",
    files,
  };
};
