import { api } from "@actions/ajax";
import { addNotification } from "@actions";
import { isArray } from "@helpers";
import ApiHelper from "@helpers/api-helper";

export const RECEIVED_ROLES_LIST = "RECEIVED_ROLES_LIST";
export const RECEIVED_ROLES_CREATE = "RECEIVED_ROLES_CREATE";
export const RECEIVED_ROLES_EDIT = "RECEIVED_ROLES_EDIT";
export const RECEIVED_ROLES_DELETE = "RECEIVED_ROLES_DELETE";
export const RECEIVED_STAFF_GET = "RECEIVED_STAFF_GET";
export const ROLE_FILTERS_ACTIVE = "ROLE_FILTERS_ACTIVE";
export const ROLE_FILTERS_INACTIVE = "ROLE_FILTERS_INACTIVE";
export const REQUEST_ROLES_LIST = "REQUEST_ROLES_LIST";

export const filtersActive = (active = true) => {
  return {
    type: active ? ROLE_FILTERS_ACTIVE : ROLE_FILTERS_INACTIVE,
  };
};

export const getPerson = (orgId, personId, endpoint = "staff") => {
  return (dispatch) => {
    const query = {
      endpoint,
      action: "get",
      params: {
        orgId,
        personId,
      },
    };
    dispatch(api(query));
  };
};

export const changeRole = (
  eventId,
  orgId,
  personId,
  prevRole,
  newRole,
  endpoint = "roles"
) => {
  let eventPoliciesId = [];

  return (dispatch) => {
    if (isArray(newRole)) {
      eventPoliciesId = [...newRole];
      eventPoliciesId.shift();
      eventPoliciesId = eventPoliciesId.map((e) => {
        return e.substring(7);
      });
      newRole = newRole[0];
    }

    const query = {
      endpoint,
      action: prevRole ? "edit" : "create",
      params: {
        orgId,
        personId,
      },
      body: {
        data: {
          role: newRole,
        },
      },
    };

    if (eventPoliciesId.length > 0) {
      query.body.data["eventPoliciesId"] = eventPoliciesId.join(",");
    }

    if (prevRole) {
      query.params["roleId"] = prevRole.id;
    } else {
      query.body.data["eventId"] = eventId;
    }

    if (newRole === "none") {
      query.action = "delete";
      delete query["body"];
    }

    dispatch(api(query))
      .then((r) => {
        if (r.meta.method === "DELETE" && r.data.deleted === 1) {
          dispatch(addNotification("Role removed successfully.", "success"));
        }
      })
      .catch(() => {
        // console.log(e)
      });
  };
};

export const getEventRoles = (
  orgId,
  personId,
  params = false,
  filters = false,
  endpoint = "roles"
) => {
  return (dispatch) => {
    const request = {
      endpoint,
      action: "list",
      params: {
        orgId,
        personId,
      },
    };
    let query = "";

    if (params) {
      const buildCriteria = ApiHelper.normalizeCriteria(params);
      const { p, rpp, orderBy, order, search, status } = buildCriteria;
      query += `rpp=${rpp}&p=${p}&orderBy=${orderBy}&order=${order}&search=${search}&status=${status}`;
    }

    if (filters) {
      query += ApiHelper.normalizeFilters(filters).substring(1);
    }

    if (query) {
      request.query = `?${query}`;
    }

    dispatch(api(request)).then(() => {
      query ? dispatch(filtersActive()) : dispatch(filtersActive(false));
    });
  };
};

export const getRoleTypes = (endpoint = "roles") => {
  return (dispatch) => {
    const query = {
      endpoint,
      action: "types",
    };

    dispatch(api(query));
  };
};
