import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import { connect } from "react-redux";
import { showModal, addNotification, loadEvents, hideModal } from "@actions";
import {
  getUserById,
  createReservation,
  updateReservation,
} from "@actions/reservations";
import { changeTab } from "@actions/editmenu";
import KMBLoader from "@layout/KMBLoader";
import { getHotels } from "@actions/hotels";
import { loadCrmUsers } from "@actions/crm";
import DateRange from "@layout/DateRange";
import { loadUsers } from "@actions/users";
import Search from "@layout/Search";
import Select2 from "@layout/Select2";
import Input from "@layout/Input";
import SubmitContainer from "@layout/SubmitContainer";

class ReservationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelName: this.props.item ? this.props.item.orgHotelName : null,
      hotelRooms: this.props.item ? null : null,
      hotelRoomsReady: this.props.item ? true : false,
      hotelRoom: this.props.item ? this.props.item.orgHotelRoomName : null,
      noteValue: this.props.item ? this.props.item.notes : "",
      userId: this.props.item ? this.props.item.eventUserId : "",
      startDate: this.props.item ? this.props.item.startDate.tz : "",
      endDate: this.props.item ? this.props.item.endDate.tz : "",
      hotelId: this.props.item ? this.props.item.eventHotelId : null,
      hotelRoomId: this.props.item ? this.props.item.eventHotelRoomId : null,
      adminNote: this.props.item ? this.props.item.adminNotes : null,
      dates: { from: "", to: "" },
    };
  }
  changeHotel(val) {
    this.props.hotels.map((hotel) => {
      if (hotel.name === val) {
        if (hotel.rooms.length === 0) {
          this.setState({
            hotelRoom: null,
            hotelRooms: null,
            hotelRoomId: null,
            hotelRoomsReady: true,
          });
        }
      }
    });
    this.setState({ hotelName: val }, () =>
      this.props.hotels.map((hotel) => {
        const rooms = {};
        if (hotel.name === val) {
          hotel.rooms.map((room) => {
            rooms[room.name] = room.name;
          });
          this.setState({
            hotelRoom:
              hotel.id !== this.state.hotelId ? null : this.state.hotelRoom,
            hotelRoomId:
              hotel.id !== this.state.hotelRoomId
                ? null
                : this.state.hotelRoomId,
            hotelRooms: rooms,
            hotelRoomsReady: true,
            hotelId: hotel.id,
          });
        }
      })
    );
  }

  changeRoom(val) {
    let id = null;
    this.props.hotels.map((hotel) => {
      if (hotel.name === this.state.hotelName) {
        hotel.rooms.map((room) => {
          if (room.name === val) {
            id = room.orgHotelRoomId;
          }
        });
      }
    });
    this.setState({ hotelRoom: val, hotelRoomId: id });
  }
  userIdOnChange(event) {
    this.setState({ userId: event.target.value });
    this.props.getUserById(this.props.eventId, event.target.value);
  }

  componentDidMount() {
    this.props.loadCrmUsers(this.props.accessEvents[this.props.eventId].orgId);
    this.props.getHotels(this.props.eventId);
    this.setState({
      dates: {
        from: this.state.startDate,
        to: this.state.endDate,
      },
    });
  }
  debouncedCall(query) {
    this.setState(
      {
        userName: query,
      },
      () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.props.searchUsers(
            query,
            this.props.policyId,
            this.props.eventId
          );
        }, 400);
      }
    );
  }
  render() {
    const hotels = {};
    this.props.hotels &&
      this.props.hotels.map((hotel) => {
        hotels[hotel.name] = hotel.name;
      });

    return (
      <div className="form-container">
        <h2>{this.props.item ? "Edit Reservation" : "Create Reservation"} </h2>
        {this.props.item ? (
          <>
            <div className="form-group">
              <label>Edit the dates of the Reservation</label>
              <DateRange
                onChange={(data) => this.setState({ dates: data })}
                from={{ value: this.state.startDate }}
                to={{ value: this.state.endDate }}
                name={"date-pick"}
              />
            </div>
            <div className="form-group">
              <label>Edit the Reservation notes</label>
              <Input
                placeholder="Type the note you want to add for the reservation"
                defaultValue={this.state.noteValue}
                onChange={(e) => this.setState({ noteValue: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Edit the Reservation admin notes</label>
              <Input
                placeholder="Type the admin note you want to add for the reservation"
                defaultValue={this.state.adminNote}
                onChange={(e) => this.setState({ adminNote: e.target.value })}
              />
            </div>
            <SubmitContainer
              onSubmit={() =>
                this.props.updateReservation(
                  this.props.eventId,
                  this.state.hotelId,
                  this.props.item.id,
                  this.state.dates,
                  this.state.noteValue,
                  this.state.adminNote
                )
              }
              onCancel={() => {
                this.props.hideModal();
              }}
            />
          </>
        ) : (
          <>
            {this.props.hotels.length === 0 ? (
              <div>There are no hotels available for this event </div>
            ) : (
              <>
                {this.props.hotelsReady ? (
                  <>
                    <div className="form-group">
                      <label>Select a Hotel</label>
                      <Select2
                        options={hotels}
                        onChange={(val) => {
                          this.changeHotel(val);
                        }}
                        placeholder={
                          this.state.hotelName ? this.state.hotelName : "Select"
                        }
                        value={this.state.hotelName ? this.state.hotelName : ""}
                      />
                    </div>
                    <div className="form-group">
                      <label>Select a Room</label>
                      {!this.state.hotelRoomsReady ? (
                        <div> Select a hotel first!</div>
                      ) : (
                        <>
                          {isEmpty(this.state.hotelRooms) ? (
                            <div>No rooms are available for this hotel.</div>
                          ) : (
                            <Select2
                              options={this.state.hotelRooms}
                              onChange={(val) => {
                                this.changeRoom(val);
                              }}
                              placeholder={
                                this.state.hotelRoom
                                  ? this.state.hotelRoom
                                  : "Select"
                              }
                              value={this.state.hotelRoom}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Search and select the user from the list*</label>
                      <div>
                        <div
                          onBlur={() => this.setState({ focused: false })}
                          onFocus={() => this.setState({ focused: true })}
                          style={{ padding: 0 }}
                        >
                          <Search
                            onSearch={(queryString) => {
                              this.debouncedCall(queryString);
                            }}
                            visible={true}
                            advancedSearch={true}
                            value={this.state.userName}
                          />
                          <div>
                            <div
                              className="autocomplete"
                              style={{ position: "absolute" }}
                            >
                              {this.props.allPolicyUsers.length > 0 &&
                                this.state.focused &&
                                this.props.allPolicyUsers
                                  .slice(0, 4)
                                  .map((item, index) => {
                                    return (
                                      <div
                                        className="item"
                                        key={index}
                                        style={{ paddingTop: 3 }}
                                        onMouseDown={() =>
                                          this.setState({
                                            userId: item.id,
                                            userName: `${item.info.firstName} ${item.info.lastName}`,
                                          })
                                        }
                                      >
                                        {item.info.firstName}{" "}
                                        {item.info.lastName}
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Select a Start Date and an End Date</label>
                      <DateRange
                        // min = {this.state.startDate}
                        // max= {this.state.endDate}
                        onChange={(data) => this.setState({ dates: data })}
                        from={{ value: this.state.startDate }}
                        to={{ value: this.state.endDate }}
                        name={"date-pick"}
                      />
                    </div>
                    <div className="form-group">
                      <label>Add a note</label>
                      <Input
                        placeholder="Type the note you want to add for the reservation"
                        defaultValue={this.state.noteValue}
                        onChange={(e) =>
                          this.setState({ noteValue: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Admin Notes</label>
                      <Input
                        placeholder="Notes from the admin"
                        defaultValue={this.state.adminNote}
                        onChange={(e) =>
                          this.setState({ adminNote: e.target.value })
                        }
                      />
                    </div>
                    <SubmitContainer
                      onSubmit={() => {
                        this.props.createReservation(
                          this.props.eventId,
                          this.state.hotelId,
                          this.state.hotelRoomId,
                          this.state.dates,
                          this.state.noteValue,
                          this.state.userId,
                          this.state.adminNote
                        );
                      }}
                      onCancel={() => {
                        this.props.hideModal();
                      }}
                    />
                  </>
                ) : (
                  <KMBLoader rows={15} padding={24} height={53} />
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

ReservationModal.propTypes = {
  eventId: PropTypes.number,
  item: PropTypes.object,
  showModal: PropTypes.func,
  getHotels: PropTypes.func,
  hotels: PropTypes.array.isRequired,
  hotelsReady: PropTypes.bool.isRequired,
  loadCrmUsers: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  getUserById: PropTypes.func.isRequired,
  events: PropTypes.any.isRequired,
  loadEvents: PropTypes.func.isRequired,
  user: PropTypes.object,
  createReservation: PropTypes.func.isRequired,
  updateReservation: PropTypes.func,
  searchUsers: PropTypes.func,
  allPolicyUsers: PropTypes.array,
  policyId: PropTypes.number,
  accessEvents: PropTypes.object,
  hideModal: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    hotels: state.api.hotels.list.data,
    hotelsReady: state.api.hotels.list.ready,
    orgId: state.appuser.ready ? state.appuser.data.orgId : null,
    events: state.api.events.list.data,
    user: state.users.userReservation,
    allPolicyUsers: state.users.allPolicyUsers.data,
    policyId: state.page.params.pid,
    accessEvents: state.appuser.data.accessEvents,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    changeTab: (tab) => {
      return dispatch(changeTab(tab));
    },
    getHotels: (eventId, meta = {}) => {
      dispatch(getHotels(eventId, meta));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    loadCrmUsers: (orgId) => {
      dispatch(loadCrmUsers(orgId));
    },
    getUserById: (eventId, userId) => {
      dispatch(getUserById(eventId, userId));
    },
    createReservation: (
      eventId,
      hotelId,
      hotelRoomId,
      dates,
      notes,
      userId,
      adminNotes
    ) => {
      dispatch(
        createReservation(
          eventId,
          hotelId,
          hotelRoomId,
          dates,
          notes,
          userId,
          adminNotes
        )
      );
    },
    updateReservation: (
      eventId,
      hotelId,
      reservationId,
      dates,
      notes,
      adminNotes
    ) => {
      dispatch(
        updateReservation(
          eventId,
          hotelId,
          reservationId,
          dates,
          notes,
          adminNotes
        )
      );
    },
    loadEvents: (
      criteria = { p: 1, rpp: 20, orderBy: "id", order: "DESC", search: "" },
      filters = {},
      additional = false,
      cache = true
    ) => {
      return new Promise((resolve, reject) => {
        dispatch(loadEvents(criteria, filters, undefined, additional, cache))
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      });
    },
    searchUsers: (queryString, policyId, eventId) => {
      dispatch(
        loadUsers(
          eventId,
          policyId,
          { search: queryString, policyTypes: "private,crm" },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReservationModal);
