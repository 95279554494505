import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@layout/Select2";
import Datetime from "react-datetime";
import moment from "moment";
import { getSponsors } from "@actions/sponsors";
import { createPayment, updatePayment } from "@actions/payments";
import { loadUsers, loadUser } from "@actions/users";
import SearchAndSelect from "@layout/SearchAndSelect";
import SubmitContainer from "@layout/SubmitContainer";

class SinglePayment extends React.Component {
  constructor(props) {
    super(props);
    this.getUserProducts = this.getUserProducts.bind(this);
    this.normalizeWayOrType = this.normalizeWayOrType.bind(this);
    this.paymentMethodOptions = {
      POS: "POS",
      Cash: "Cash",
      Discount: "Discount",
    };
    if (this.props.bankSupported === 1) {
      this.paymentMethodOptions["Bank"] = "Bank";
    }
    this.state = {
      paymentType: this.normalizeWayOrType(this.props.item?.type),
      paymentMethod: this.normalizeWayOrType(this.props.item?.way),
      paymentAmount: this.props.item?.amount || "",
      paymentNotes: this.props.item?.notes || "",
      transactionId: this.props.item?.transactionId || "",
      products: [],
      selectedProduct: this.props.item?.typeId,
      userId: this.props.item?.eventUserId || "",
      userName: this.props.item?.eventUserFullName || "",
      sponsorId: this.props.item?.orgSponsorId || "",
      sponsorName: this.props.item?.orgSponsorName || "",
      transactionDate:
        this.props.item?.transactionDate || moment().format("YYYY-MM-DD HH:mm"),
    };
  }

  normalizeWayOrType(wayOrType) {
    switch (wayOrType) {
      case "session": {
        return "Session";
      }
      case "accomodation": {
        return "Accomodation";
      }
      case "registration": {
        return "Registration";
      }
      case "product": {
        return "Product";
      }
      case "sponsorship": {
        return "Sponsorship";
      }
      case "on_site": {
        return "On Site";
      }
      case "paypal": {
        return "PayPal";
      }
      case "card": {
        return "Card";
      }
      case "bank": {
        return "Bank";
      }
      case "cash": {
        return "Cash";
      }
      case "pos": {
        return "POS";
      }
      case "discount": {
        return "Discount";
      }
      default:
        return null;
    }
  }
  getUserProducts() {
    this.props
      .loadUser(this.props.eventId, this.state.userId)
      .then(({ data }) => {
        this.user = data;
        this.setState({ products: data.products });
      });
  }
  componentDidMount() {
    if (this.state.userId && this.state.paymentType === "Product") {
      this.getUserProducts();
    }
  }

  getProductOptions() {
    const productOptions = {};

    if (this.state.products && this.state.products.length > 0) {
      this.state.products.map((product) => {
        productOptions[product.id] = product.name;
      });
    }
    return productOptions;
  }

  render() {
    return (
      <div className="form-container">
        <h2>{this.props.item ? "Edit Payment" : "Create Payment"}</h2>
        <div className="form-group">
          <label>Select a Payment Type*</label>
          <Select
            placeholder={
              this.state.paymentType === null
                ? "Payment Type"
                : this.state.paymentType
            }
            className="more-actions"
            isDropDownButton={true}
            placeholderInsideSelect={false}
            options={{
              Accomodation: "Accomodation",
              Registration: "Registration",
              Product: "Product",
              Sponsorship: "Sponsorship",
            }}
            onChange={(val) => {
              this.setState({
                paymentType: val,
                userId: this.props.item?.eventUserId || "",
                sponsorId: this.props.item?.orgSponsorId || "",
              });
            }}
          />
        </div>
        <div className="form-group">
          <label>Select a Payment Method*</label>
          <Select
            placeholder={
              this.state.paymentMethod === null
                ? "Payment Method "
                : this.state.paymentMethod
            }
            className="more-actions"
            isDropDownButton={true}
            placeholderInsideSelect={false}
            options={this.paymentMethodOptions}
            onChange={(val) => {
              this.setState({ paymentMethod: val });
            }}
          />
        </div>
        <div className="form-group">
          <label>Select a Transaction Date</label>
          <Datetime
            dateFormat="DD-MM-YYYY HH:mm"
            timeFormat={false}
            value={this.state.transactionDate}
            closeOnSelect={true}
            inputProps={{
              placeholder: `Transaction date`,
              readOnly: true,
            }}
            onChangeRaw={(e) => e.preventDefault()}
            onChange={(val) => {
              if (!moment(val).isValid()) return;
              this.setState({
                transactionDate: moment(val).format("YYYY-MM-DD HH:mm"),
              });
            }}
          />
        </div>
        {this.state.paymentType === "Sponsorship" ? (
          <div
            className="form-group"
            key={`sponsor-search-${this.state.paymentType}`}
          >
            <label>Search and select the sponsor from the list*</label>
            <SearchAndSelect
              placeholder={
                this.state.sponsorId
                  ? `${this.state.sponsorId} - ${this.state.sponsorName}`
                  : "Search sponsor..."
              }
              setName={(item) => `${item.id} - ${item.name}`}
              onSelectChange={(value) => {
                this.setState({
                  sponsorId: value,
                });
              }}
              selectValue={this.state.sponsorId}
              onSearch={(query) => {
                if (!query)
                  this.setState({
                    sponsorId: this.props.item?.orgSponsorId || "",
                  });
                return this.props.loadSponsors(
                  this.props.accessEvents[this.props.eventId].orgId,
                  {
                    search: query,
                  }
                );
              }}
            />
          </div>
        ) : (
          <div
            className="form-group"
            key={`user-search-${this.state.paymentType}`}
          >
            <label>Search and select the user from the list*</label>
            <SearchAndSelect
              placeholder={
                this.state.userId
                  ? `${this.state.userId} - ${this.state.userName}`
                  : "Search participant..."
              }
              onSelectChange={(value) => {
                this.setState(
                  {
                    userId: value,
                  },
                  () => {
                    if (this.state.paymentType === "Product") {
                      this.getUserProducts();
                    }
                  }
                );
              }}
              selectValue={this.state.userId}
              onSearch={(query) => {
                if (!query)
                  this.setState({ userId: this.props.item?.eventUserId || "" });
                return this.props.searchUsers(
                  query,
                  this.props.policyId,
                  this.props.eventId
                );
              }}
            />
          </div>
        )}
        {this.state.paymentType === "Product" && this.state.userId !== "" ? (
          this.state.products.length === 0 ? (
            <div>
              <i>This user is not eligible for any purchase. </i>
              <i>Please select another user, or change the payment type. </i>
            </div>
          ) : (
            <div className="form-group">
              <label>Select a Product*</label>
              <Select
                placeholder={
                  this.state.selectedProduct === null
                    ? "Product "
                    : this.state.selectedProduct
                }
                className="more-actions"
                isDropDownButton={true}
                placeholderInsideSelect={false}
                options={this.getProductOptions()}
                onChange={(val) => {
                  this.setState({ selectedProduct: val });
                }}
                value={this.state.selectedProduct}
              />
            </div>
          )
        ) : null}
        <div className="form-group">
          <label>Enter the payment amount*</label>
          <input
            className={`form-control`}
            placeholder={"Payment Amount"}
            onChange={(e) => this.setState({ paymentAmount: e.target.value })}
            value={this.state.paymentAmount}
          />
        </div>
        <div className="form-group">
          <label>Enter the transaction ID</label>
          <input
            className="form-control"
            placeholder={"Transaction Id"}
            onChange={(e) => this.setState({ transactionId: e.target.value })}
            value={this.state.transactionId}
          />
        </div>
        <div className="form-group">
          <div>
            <label>Notes</label>
            <input
              className={`form-control`}
              placeholder={"Notes to accompany this payment"}
              onChange={(e) => this.setState({ paymentNotes: e.target.value })}
              value={this.state.paymentNotes}
            />
          </div>
          <span></span>
        </div>

        <SubmitContainer
          onCancel={this.props.hideModal}
          onSubmit={() => {
            return this.props.item
              ? this.props.updatePayment(
                  this.props.eventId,
                  this.state.paymentAmount,
                  this.state.paymentMethod,
                  this.state.paymentType?.toLowerCase(),
                  this.state.userId,
                  this.props.item.id,
                  this.state.transactionId,
                  this.state.paymentNotes,
                  this.state.dates,
                  this.state.paymentType?.toLowerCase() === "sponsorship"
                    ? this.state.sponsorId
                    : this.state.selectedProduct
                )
              : this.props.createPayment(
                  this.props.eventId,
                  this.state.paymentAmount,
                  this.state.paymentMethod,
                  this.state.paymentType?.toLowerCase(),
                  this.state.userId,
                  this.state.transactionId,
                  this.state.paymentNotes,
                  this.state.dates,
                  this.state.paymentType?.toLowerCase() === "sponsorship"
                    ? this.state.sponsorId
                    : this.state.selectedProduct
                );
          }}
        />
      </div>
    );
  }
}

SinglePayment.defaultProps = {
  newMeta: {},
};
SinglePayment.propTypes = {
  eventId: PropTypes.number,
  item: PropTypes.object,
  createPayment: PropTypes.func,
  user: PropTypes.any,
  updatePayment: PropTypes.func,
  bankSupported: PropTypes.number,
  allPolicyUsers: PropTypes.array,
  searchUsers: PropTypes.func,
  policyId: PropTypes.number,
  loadSponsors: PropTypes.func,
  orgId: PropTypes.number,
  accessEvents: PropTypes.object,
  sponsorList: PropTypes.array,
  loadData: PropTypes.func,
  productId: PropTypes.number,
  paymentsListMeta: PropTypes.object,
  newMeta: PropTypes.object,
  hideModal: PropTypes.func,
  loadUser: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    orgId: state.api.events.edit.data.orgId,
    sponsorList: state.api.sponsors.data,
    ready: state.api.sponsors.ready,
    eventId: state.api.events.edit.data.id,
    bankSupported: state.api.events.edit.data.settings.billing.bank,
    user: state.users.userReservation,
    paymentsListMeta: state.api.payments.meta,
    allPolicyUsers: state.users.allPolicyUsers.data,
    policyId: state.page.params.pid,
    accessEvents: state.appuser.data.accessEvents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPayment: (
      eventId,
      amount,
      way,
      type,
      eventUserId,
      transactionId,
      paymentNotes,
      dates,
      typeOrReferenceId,
      meta
    ) => {
      return dispatch(
        createPayment(
          eventId,
          amount,
          false,
          "",
          way,
          type,
          eventUserId,
          transactionId,
          null,
          paymentNotes,
          dates,
          typeOrReferenceId,
          meta
        )
      );
    },
    updatePayment: (
      eventId,
      amount,
      way,
      type,
      eventUserId,
      paymentId,
      transactionId,
      paymentNotes,
      dates,
      typeOrReferenceId,
      meta
    ) => {
      return dispatch(
        updatePayment(
          eventId,
          amount,
          false,
          "",
          way,
          type,
          eventUserId,
          paymentId,
          transactionId,
          false,
          paymentNotes,
          dates,
          typeOrReferenceId,
          meta
        )
      );
    },
    loadSponsors: (orgId, meta) => {
      return dispatch(getSponsors(orgId, meta));
    },
    loadUser(eventId, userId) {
      return dispatch(loadUser(eventId, userId, "?products=true"));
    },
    searchUsers: (queryString, policyId, eventId) => {
      return dispatch(
        loadUsers(
          eventId,
          policyId,
          {
            search: queryString,
            products: false,
            subscriptions: false,
            accomodation: false,
            minutes: false,
            currentRoom: false,
            points: false,
            abstracts: false,
          },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SinglePayment);
