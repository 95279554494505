/* eslint-disable */
import { api as configuration } from "@config/conf";
import { logout, isArray } from "@helpers";
import mt from "moment-timezone";
import ApiHelper from "@helpers/api-helper";

const dateKeys = ["startDate", "endDate"];

const checkForTimezones = (res) => {
  return res;
};

const checkForDates = (body, timezone) => {
  // convert everything to utc
  // before send to api
  const response = {};
  if (body.hasOwnProperty("data")) {
    response["data"] = {};
    Object.entries(body.data).map(([key, value]) => {
      if (dateKeys.includes(key) && key.split(" ").length > 1) {
        // check if has time, otherwise we don't need tz.
        let m = mt.tz(value, ApiHelper.dateFormat, timezone);
        m = m.format(ApiHelper.dateFormat);
        response.data[key] = m;
      } else {
        response.data[key] = value;
      }
    });
  }
  return response;
};

const buildParams = (parameters, timezone) => {
  delete parameters.url;

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${parameters.token}`);

  if (!parameters.hasOwnProperty("method")) parameters.method = "GET";

  if (parameters.method !== "GET") {
    if (!parameters.hasOwnProperty("contentType")) {
      parameters.contentType = "application/json";
      headers.append("Content-Type", parameters.contentType);
    }

    if (parameters.contentType === "application/json") {
      if (
        parameters.hasOwnProperty("body") &&
        Object.keys(parameters.body).length
      ) {
        parameters.body = JSON.stringify(
          timezone ? checkForDates(parameters.body, timezone) : parameters.body
        );
      }
    } else if (parameters.contentType === "multipart/form-data") {
      const formData = new FormData();

      for (const name in parameters.body) {
        const value = parameters.body[name];

        if (isArray(parameters.body[name])) {
          for (const v of parameters.body[name]) {
            formData.append(`${name}`, v);
          }
        } else {
          formData.append(name, value);
        }
      }

      parameters.body = formData;
    }

    delete parameters.contentType;
  }

  parameters.headers = headers;

  return parameters;
};

export const sanitizeArgs = (args, timezone = false) => {
  const response = {
    success: false,
    message: "",
    args: {},
    url: "",
    dataType: "json",
  };

  const token = localStorage.getItem("bluetree_admin_token");
  if (!token && args.insecure !== true) {
    response.message = "Unauthorized access.";
    return response;
  }

  if (args.dataType) {
    response.dataType = args.dataType;
  }

  if (!args.hasOwnProperty("endpoint") || !args.hasOwnProperty("action")) {
    response.message = "Endpoint or action not defined.";
    return response;
  }

  if (!configuration.endpoints.hasOwnProperty(args.endpoint)) {
    response.message = "Endpoint not listed on application.";
    return response;
  }

  if (!configuration.endpoints[args.endpoint].hasOwnProperty(args.action)) {
    response.message = "Endpoint's action not defined.";
    return response;
  }

  const endpoint = Object.assign(
    {},
    configuration.endpoints[args.endpoint][args.action]
  );
  endpoint.token = token;

  if (args.hasOwnProperty("body")) {
    endpoint.body = args.body;
  }

  if (args.hasOwnProperty("contentType")) {
    endpoint.contentType = args.contentType;
  }

  if (args.hasOwnProperty("params")) {
    // the endpoint's url has dynamic
    // parameters. Let's populate them

    const populatedUrl = populateEndpoint(args.params, endpoint.url);
    response.url = `${configuration.url}/${populatedUrl}`;
  } else {
    response.url = `${configuration.url}/${endpoint.url}`;
  }

  if (
    (args.hasOwnProperty("query") && endpoint.method === "GET") ||
    endpoint.method === "POST"
  ) {
    if (args.query) {
      response.url += args.query;
    }
  }

  response.success = true;
  response.params = buildParams(Object.assign({}, endpoint), timezone);
  response.args = args;

  return response;
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */

export const parseJSON = (response) => {
  return new Promise((resolve, reject) => {
    response
      .json()
      .then((json) => {
        json.status = response.status;
        response.ok ? resolve(json) : reject(json.meta);
      })
      .catch((e) =>
        reject({ errorMessage: `Response status: ${response.status}` })
      );
  });
};

export const populateEndpoint = (params, url) => {
  for (const [key, value] of Object.entries(params)) {
    url = url.replace("${" + `${key}` + "}", value);
  }

  return url;
};

export const callApi = (url, params, dataType = "json") => {
  return new Promise((resolve, reject) => {
    return fetch(url, params)
      .then((response) => {
        if (response.status !== 200) {
          return parseJSON(response);
        }
        return Promise.resolve(response);
      })
      .then((response) => {
        if (dataType === "buffer") {
          return response.arrayBuffer().then((buffer) => buffer);
        } else if (dataType === "blob") {
          return response.blob();
        } else {
          return parseJSON(response);
        }
      })
      .then((response) => {
        if (dataType !== "buffer" && dataType !== "blob") {
          const token = response.meta.accessToken || false;
          if (token)
            localStorage.setItem(
              "bluetree_admin_token",
              response.meta.accessToken
            );
        }
        resolve(checkForTimezones(response));
      })
      .catch((err) => {
        console.error(err);
        let message = "";
        if (err.errorMessage) {
          message += err.errorMessage;
        }
        if (err.description) {
          message += err.errorMessage ? " - " : "";
          message += err.description;
        }
        reject(new Error(message));
      });
  });
};
