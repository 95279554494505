import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { connect } from "react-redux";
import { loadProducts, deleteProduct } from "@actions/products";
import TableActions from "@layout/TableActions";
import SingleProduct from "./partials/SingleProduct";
import Prices from "./Prices";
import Data from "./Data";
import { listGroups } from "@actions/policyGroups";
import { changeTab } from "@actions/editmenu";
import { showModal } from "@actions";

class ProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      viewData: false,
      viewPrices: false,
      productId: null,
      targetUser: 0,
      productName: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const viewData = nextProps.fakeChild === "data";
    const viewPrices = nextProps.fakeChild === "prices";
    if (nextProps.fakeChild !== this.props.fakeChild) {
      this.setState({ viewData, viewPrices });
    }
  }
  componentDidMount() {
    this.props.loadProducts(this.props.eventId);
    this.props.listGroups(this.props.eventId);
  }
  goBack() {
    this.setState({ viewData: false, viewPrices: false });
  }
  onRender(col, item) {
    switch (col.key) {
      case "buyingPrice": {
        return col.value.toFixed(2);
      }
      case "active": {
        return item.active ? "Yes" : "No";
      }
      case "productActions": {
        return (
          <div style={{ display: "flex" }}>
            <button
              className="btn transparent table-button"
              style={{ marginRight: 15 }}
              onClick={() =>
                this.setState(
                  {
                    viewPrices: true,
                    viewData: false,
                    productId: item.id,
                    targetUser: item.targetEventUsers,
                    productName: item.name,
                  },
                  () => this.props.changeTab("ProductsList", "prices")
                )
              }
            >
              <span className="fa fa-list-ul" aria-hidden="true"></span>
              View Prices
            </button>
            <button
              className="btn transparent table-button"
              style={{ marginRight: 15 }}
              onClick={() =>
                this.setState(
                  {
                    viewData: true,
                    viewPrices: false,
                    productId: item.id,
                    targetUser: item.targetEventUsers,
                    productName: item.name,
                  },
                  () => this.props.changeTab("ProductsList", "data")
                )
              }
            >
              <span className="fa fa-list-ul" aria-hidden="true"></span>
              View Purchases
            </button>
          </div>
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onDelete={() =>
              this.props.deleteProduct(this.props.eventId, item.id)
            }
            onEdit={() =>
              this.props.showModal(
                <SingleProduct
                  h2={"Edit"}
                  eventId={this.props.eventId}
                  item={item}
                />
              )
            }
          />
        );
      }
    }
  }
  render() {
    if (this.state.viewPrices) {
      return (
        <Prices
          eventId={this.props.eventId}
          productId={this.state.productId}
          targetUser={this.state.targetUser}
          goBack={this.goBack}
          productName={this.state.productName}
        />
      );
    }
    if (this.state.viewData) {
      return (
        <Data
          eventId={this.props.eventId}
          productId={this.state.productId}
          targetUser={this.state.targetUser}
          productName={this.state.productName}
          goBack={this.goBack}
        />
      );
    }
    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SingleProduct h2={"Create"} eventId={this.props.eventId} />
                )
              }
            >
              Create
            </button>
          </div>
        </div>

        <div className="tab-content">
          <Table
            columns={{
              id: {
                name: "ID",
              },
              name: {
                name: "Product",
              },
              quantity: {
                name: "Quantity",
              },
              maxQuantityPerUser: {
                name: "Max quantity/User",
              },
              availableOnlineQuantity: {
                name: "Available Online quantity",
              },
              active: {
                name: "Active",
              },
              buyingPrice: {
                name: "Buying Price",
              },
              productActions: {
                name: "Actions",
              },
              actions: {
                name: "",
              },
            }}
            updating={!this.props.productsReady}
            items={this.props.products}
            onRender={this.onRender}
          />
        </div>
      </>
    );
  }
}
ProductsList.propTypes = {
  eventId: PropTypes.number,
  loadProducts: PropTypes.func.isRequired,
  products: PropTypes.array,
  productsReady: PropTypes.bool,
  showModal: PropTypes.func,
  deleteProduct: PropTypes.func,
  listGroups: PropTypes.func,
  changeTab: PropTypes.func,
  fakeChild: PropTypes.string,
};
const mapDispatchToProps = (dispatch) => {
  return {
    listGroups: (eventId) => {
      dispatch(listGroups(eventId));
    },
    loadProducts: (eventId) => {
      return dispatch(loadProducts(eventId));
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    deleteProduct: (eventId, itemId) => {
      dispatch(deleteProduct(eventId, itemId));
    },
    changeTab: (tab, fakeChild) => dispatch(changeTab(tab, fakeChild)),
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    presetList: state.api.events.presetEmails.data,
    globalVars: state.api.events.presetEmails.meta.available_vars,
    tempContacts: state.users.tempContacts,
    eventId: ownProps.event.id,
    products: state.api.products.list.data,
    productsReady: state.api.products.list.ready,
    fakeChild: state.editmenu.fakeChild,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
