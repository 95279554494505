import React from "react";
import { connect } from "react-redux";
import {
  getAbstracts,
  updateAbstract,
  createSchema,
  deleteAbstract,
  unassignAbstractsFromJudges,
  assignAbstractsToJudges,
  publishAbstracts,
  getStatistics,
} from "@actions/abstracts";
import { changeTab } from "@actions/editmenu";
import { deleteItem, addNotification } from "@actions";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import Filters from "./partials/Filters";
import Statistics from "./partials/Statistics";
import Select2 from "@layout/Select2";
import { capitalizeAllWords, createAbstractFile, downloadFile } from "@helpers";
import TableActions from "@layout/TableActions";
import { HeaderPagination } from "@layout/Pagination";
import Switch from "@layout/Switch";
import Modal from "@layout/Modal";
import Checkbox from "@layout/Checkbox";
import { showModal } from "@actions";
import EditAbstract from "./partials/EditAbstract";
import SingleAbstract from "./partials/SingleAbstract";
import Select from "@layout/Select2";
import { getJudges } from "@actions/abstracts";

class AbstractsList extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.onRenderTh = this.onRenderTh.bind(this);
    this.bulkAction = this.bulkAction.bind(this);
    this.getHtml = this.getHtml.bind(this);
    this.fetched = false;
    this.state = {
      adminRating: false,
      adminScore: this.props.schema.passingScore || "",
      selectedAll: false,
      selected: [],
      bulkUnrate: false,
      bulkJudgeRate: false,
      editDeleteMode: false,
      userType: false,
      typeId: null,
      bulkJudgeUnassign: false,
      unassignRated: false,
      bulkJudgeAssign: false,
      bulkPublish: false,
      bulkUnPublish: false,
      judgeIds: [],
      publishedAbstracts: {},
    };
  }

  componentDidMount() {
    if (!this.fetched) {
      const { getAbstracts } = this.props;
      getAbstracts();
      this.fetched = true;
    }
    this.props.getJudges();
  }

  isAbstractPassing(item) {
    if (!item) return null;
    const { passingScore } = this.props.schema;
    return parseFloat(item.score) > passingScore ? "Yes" : "No";
  }
  getJudgedBy(item) {
    if (!item) return null;
    const rated = [...item.judging].filter((j) => j.score !== null).length;
    const total = item.judging.length;
    return (
      <span>
        {rated} of {total} judges
      </span>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.adminScore !== nextProps.schema.passingScore) {
      this.setState({
        adminScore: nextProps.schema.passingScore,
      });
    }
  }

  selectRow(id) {
    let selected = [...this.state.selected];

    if (this.state.selectedAll) {
      selected = [...this.props.abstracts]
        .map((u) => u.id)
        .filter((i) => i !== id);
    } else {
      if (selected.includes(id)) {
        selected = selected.filter((u) => u !== id);
      } else {
        selected = [...selected, ...[id]];
      }
    }

    this.setState({ selected, selectedAll: false });
  }

  getScoreHtml(item, html = false) {
    if (!item) return null;
    item.judging ??= [];
    const rated = [...item.judging].filter((j) => j.score !== null).length;
    const total = item.judging.length;
    if (html) {
      return (
        <p>
          {item.score !== null ? item.score : "N/A"}
          <span>
            ( {rated} of {total} judges )
          </span>
        </p>
      );
    } else {
      return item.score !== null ? item.score : "N/A";
    }
  }

  getFontClass(item) {
    let _className = "";
    if (item.finalScore !== null) {
      _className =
        item.passingByAdmin === 1 ? "score-approved" : "score-rejected";
    }

    if (
      (item.finalScore || this.getScoreHtml(item, false)).toString().length >= 5
    ) {
      _className = `${_className} minified-font-size`;
    }

    return _className;
  }

  onRender(col, item) {
    switch (col.key) {
      case "bulkSelect": {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              id={`user-${item.id}`}
              checked={
                this.state.selectedAll || this.state.selected.includes(item.id)
              }
              onChange={() => this.selectRow(item.id)}
            />
          </div>
        );
      }

      case "authors": {
        const main = [...col.value].filter((v) => v.type === "main");
        if (main.length === 0) return null;
        const { firstName, lastName } = main[0];
        return <span>{`${firstName} ${lastName}`}</span>;
      }

      case "type":
      case "status": {
        return capitalizeAllWords(col.value.replace("_", " "));
      }

      case "judging": {
        return (
          <table>
            <thead>
              <tr>
                <th>Judge ID</th>
                <th>Judge Full Name</th>
                <th>Assigned Score</th>
              </tr>
            </thead>
            <tbody>
              {col.value.map((j, i) => {
                return (
                  <tr key={`${item.id}_judge_${i}`}>
                    <td>{j.judgeUserId}</td>
                    <td>{`${j.firstName} ${j.lastName}`}</td>
                    <td>
                      {j.score ? (
                        j.rated ? (
                          <strong>
                            <u title="rated">{j.score}</u>
                          </strong>
                        ) : (
                          j.score
                        )
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }
      case "judgeAverage": {
        return <div>{this.getScoreHtml(item, false)}</div>;
      }
      case "judgePassing": {
        return <div>{this.isAbstractPassing(item)}</div>;
      }
      case "score": {
        return this.getScoreHtml(item, true);
      }
      case "finalScore": {
        const { schema } = this.props;
        const rateBtn = [];
        const disableRate =
          (schema.finalize === 1 ||
            item.status === "awaiting_resubmission" ||
            this.state.selected.length ||
            this.state.selectedAll) &&
          item.finalScore !== null;
        rateBtn.push(
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <button
              type="button"
              className={`btn transparent table-button`}
              onClick={(e) => {
                e.stopPropagation();
                this.props.changeTab("AbstractsList", item.id);
              }}
            >
              View
            </button>
            <button
              type="button"
              style={{ marginTop: 10 }}
              disabled={disableRate}
              className={`btn transparent table-button${
                disableRate ? " disabled" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                item.finalScore !== null
                  ? this.props.updateAbstract(this.props.eventId, item.id, {
                      finalScore: null,
                      unrate: true,
                    })
                  : this.setState({
                      adminRating: item,
                      userType: item.eventAbstractSchemaTypeId ? false : true,
                      typeId: item.eventAbstractSchemaTypeId,
                    });
              }}
            >
              {item.finalScore !== null ? "Unrate" : "Rate"}
            </button>
          </div>
        );
        return (
          <div className="rate-final">
            <span
              style={{
                paddingRight: 10,
                opacity: item.published == true ? 1 : 0.2,
              }}
              title={item.published == true ? "published" : "not published"}
            >
              <i className="fas fa-globe"></i>
            </span>
            <div className={`final-score ${this.getFontClass(item)}`}>
              {item.finalScore ?? "N/A"}
            </div>
            <div>{rateBtn}</div>
          </div>
        );
      }

      case "types": {
        return (
          <div>
            {item.types.map((type) => (
              <span
                key={`type-${type.id}`}
              >{`${type.typeFrom}: ${type.name}`}</span>
            ))}
          </div>
        );
      }

      case "topics": {
        return <div>{item.topics.map((topic) => topic.name).join(", ")}</div>;
      }

      case "actions": {
        return (
          <TableActions
            entity="abstract"
            item={item}
            delete={this.props.orgAdmin}
            onDelete={() => {
              this.props
                .deleteAbstract(this.props.eventId, item.id)
                .then(() => this.props.getAbstracts())
                .then(() => {
                  this.props.addNotification(
                    "Abstract successfully deleted",
                    "success"
                  );
                });
            }}
            onEdit={() =>
              this.props.showModal(
                <EditAbstract
                  item={item}
                  eventId={this.props.eventId}
                  schema={this.props.schema}
                />
              )
            }
          />
        );
      }
    }
  }

  onRenderTh(col) {
    if (col.key === "bulkSelect") {
      return (
        <Checkbox
          id="user-all"
          checked={this.state.selectedAll}
          onChange={() => {
            this.setState((prevState) => ({
              selectedAll: !prevState.selectedAll,
              selected: [],
            }));
          }}
        />
      );
    }
  }

  bulkAction(action) {
    let newState;
    switch (action) {
      case "bulk_rate": {
        newState = {
          adminRating: true,
          bulkJudgeRate: false,
          bulkUnrate: false,
          bulkRate: true,
          bulkJudgeUnassign: false,
          unassignRated: false,
          bulkJudgeAssign: false,
          bulkPublish: false,
          bulkUnPublish: false,
        };
        break;
      }
      case "bulk_unrate": {
        newState = {
          adminRating: true,
          bulkJudgeRate: false,
          bulkUnrate: true,
          bulkRate: false,
          bulkJudgeUnassign: false,
          unassignRated: false,
          bulkJudgeAssign: false,
          bulkUnPublish: false,
          bulkPublish: false,
        };
        break;
      }
      case "bulk_judge_rate": {
        newState = {
          adminRating: true,
          bulkJudgeRate: true,
          bulkUnrate: false,
          bulkRate: false,
          bulkJudgeUnassign: false,
          unassignRated: false,
          bulkJudgeAssign: false,
          bulkUnPublish: false,
          bulkPublish: false,
        };
        break;
      }
      case "bulk_judge_unassign": {
        newState = {
          adminRating: false,
          bulkJudgeRate: false,
          bulkUnrate: false,
          bulkRate: false,
          bulkJudgeUnassign: true,
          unassignRated: false,
          bulkJudgeAssign: false,
          bulkUnPublish: false,
          bulkPublish: false,
        };
        break;
      }
      case "bulk_judge_assign": {
        newState = {
          adminRating: false,
          bulkJudgeRate: false,
          bulkUnrate: false,
          bulkRate: false,
          bulkJudgeUnassign: false,
          unassignRated: false,
          bulkJudgeAssign: true,
          bulkUnPublish: false,
          bulkPublish: false,
        };
        break;
      }
      case "bulk_publish": {
        newState = {
          adminRating: false,
          bulkJudgeRate: false,
          bulkUnrate: false,
          bulkRate: false,
          bulkJudgeUnassign: false,
          unassignRated: false,
          bulkJudgeAssign: false,
          bulkUnPublish: false,
          bulkPublish: true,
        };
        break;
      }
      case "bulk_unpublish": {
        newState = {
          adminRating: false,
          bulkJudgeRate: false,
          bulkUnrate: false,
          bulkRate: false,
          bulkJudgeUnassign: false,
          unassignRated: false,
          bulkJudgeAssign: false,
          bulkUnPublish: true,
          bulkPublish: false,
        };
        break;
      }
    }
    const abstractIds =
      this.state.selectedAll === false
        ? this.state.selected
        : [...this.props.abstracts].map((a) => a.id);
    const publishedAbstracts = [...this.props.abstracts]
      .filter((abstract) => abstractIds.includes(abstract.id))
      .map((abstract) => ({
        id: abstract.id,
        publishedId: abstract.publishedId,
      }));
    this.setState({ ...newState, abstractIds, publishedAbstracts });
  }

  rate(finalScore) {
    let abstractId;
    const { bulkJudgeRate, bulkUnrate } = this.state;

    if (this.state.adminRating === true) {
      abstractId =
        this.state.selectedAll === false
          ? this.state.selected
          : [...this.props.abstracts]
              .filter((a) => (bulkJudgeRate ? a.score !== null : a))
              .map((a) => a.id);
    } else {
      abstractId = this.state.adminRating.id;
    }

    const { eventId } = this.props;
    const keepUserType = this.state.userType;
    const eventAbstractSchemaTypeId = this.state.typeId;

    if (eventAbstractSchemaTypeId === null || keepUserType === 1) {
      this.props
        .updateAbstract(
          eventId,
          abstractId,
          bulkJudgeRate
            ? { keepUserType }
            : bulkUnrate
            ? { finalScore: null, keepUserType, unrate: true }
            : { finalScore, keepUserType },
          bulkJudgeRate
        )
        .then(() =>
          this.setState({
            adminRating: false,
            bulkJudgeRate: false,
            bulkUnrate: false,
            selectedAll: false,
            selected: [],
            typeId: null,
          })
        );
    } else {
      this.props
        .updateAbstract(
          eventId,
          abstractId,
          bulkJudgeRate
            ? {}
            : { finalScore, eventAbstractSchemaTypeId, keepUserType },
          bulkJudgeRate
        )
        .then(() =>
          this.setState({
            adminRating: false,
            bulkJudgeRate: false,
            bulkUnrate: false,
            selectedAll: false,
            selected: [],
            typeId: null,
          })
        );
    }
  }

  getHtml() {
    const options = {};
    this.props.schema &&
      this.props.schema.types &&
      this.props.schema.types.map((type) => {
        options[type.id] = type.name;
      });
    const html = [];
    const {
      adminRating,
      selectedAll,
      selected,
      bulkJudgeRate,
      bulkUnrate,
      adminScore,
      bulkRate,
      bulkJudgeUnassign,
      bulkJudgeAssign,
      bulkPublish,
      bulkUnPublish,
      judgeIds,
      abstractIds,
      publishedAbstracts,
    } = this.state;
    const { abstracts, schema } = this.props;
    const { passingScore, minScore, maxScore } = schema;
    let dataSet = [];

    if (
      adminRating === false &&
      bulkJudgeUnassign === false &&
      bulkJudgeAssign === false &&
      bulkPublish === false &&
      bulkUnPublish === false
    ) {
      return [];
    }

    if (bulkPublish) {
      html.push(
        <h5 key="heading">You will publish the following abstracts:</h5>
      );

      html.push(
        <div className="published-abstracts-table">
          <Table
            id={"abstracts-list"}
            items={publishedAbstracts}
            columns={{
              id: {
                name: "ID",
              },
              publishedId: {
                name: "Published Id",
              },
            }}
            onRender={(col, item) => {
              switch (col.key) {
                case "publishedId":
                  return (
                    <input
                      value={item.publishedId}
                      className="form-control"
                      onChange={(e) => {
                        const newPublishedAbstracts = [
                          ...this.state.publishedAbstracts,
                        ];
                        const updateAbstract = newPublishedAbstracts.filter(
                          (abstract) => abstract.id === item.id
                        )[0];
                        updateAbstract.publishedId = e.target.value;
                        this.setState({
                          publishedAbstracts: newPublishedAbstracts,
                        });
                      }}
                    />
                  );
              }
            }}
            containerClassName={"table-container "}
          />
        </div>
      );
      html.push(
        <p
          style={{
            padding: "15px 0px",
            fontSize: 13,
            color: "#999",
          }}
        >
          Note: You need to enable the Publish Abstracts screen from the{" "}
          <code style={{ color: "#208edd" }}>
            Basic Setup &gt; Client Panel
          </code>{" "}
          in order to display them in the Client Panel
        </p>
      );
      html.push(
        <div style={{ marginTop: 20 }}>
          <button
            className={`btn`}
            onClick={() => {
              return this.props
                .publishAbstracts(
                  this.props.eventId,
                  this.state.publishedAbstracts,
                  true
                )
                .then(() => {
                  this.setState({
                    adminRating: false,
                    bulkJudgeRate: false,
                    bulkUnrate: false,
                    selectedAll: false,
                    selected: [],
                    bulkJudgeUnassign: false,
                    unassignRated: false,
                    bulkPublish: false,
                    bulkUnPublish: false,
                    publishedAbstracts: [],
                  });
                });
            }}
          >
            Publish
          </button>
        </div>
      );
      return html;
    }

    if (bulkUnPublish) {
      html.push(
        <h5 key="heading">
          You will remove from published the following abstracts. Are you sure ?
        </h5>
      );
      html.push(<div>{abstractIds.join(", ")}</div>);
      html.push(
        <div style={{ marginTop: 20 }}>
          <button
            className={`btn`}
            onClick={() => {
              return this.props
                .publishAbstracts(this.props.eventId, publishedAbstracts, false)
                .then(() => {
                  this.setState({
                    adminRating: false,
                    bulkJudgeRate: false,
                    bulkUnrate: false,
                    selectedAll: false,
                    selected: [],
                    bulkJudgeUnassign: false,
                    unassignRated: false,
                    bulkPublish: false,
                    bulkUnPublish: false,
                    publishedAbstracts: [],
                  });
                });
            }}
          >
            Un-Publish
          </button>
        </div>
      );
      return html;
    }
    if (bulkJudgeUnassign) {
      html.push(
        <h5 key="heading">
          You will unassign all judges and their comments for the following
          abstracts:
        </h5>
      );

      html.push(<div>{abstractIds.join(", ")}</div>);

      html.push(
        <div
          className="row form-container"
          key="other"
          style={{ marginTop: 15 }}
        >
          <div className="col-sm-8">
            Force unassign rated or revisioned abstracts
          </div>
          <div className="col-sm-4">
            <Switch
              isActive={Number(this.state.unassignRated)}
              onChange={(value) => this.setState({ unassignRated: value })}
            />
          </div>
          <p
            className="col-sm-12"
            style={{
              padding: 15,
              fontSize: 13,
              color: "#999",
              display: this.state.unassignRated == true ? "block" : "none",
            }}
          >
            Attention: if you enable this switch it will remove the judges
            including their comments for rated or revisioned abstracts also. If
            some abstracts have status &rsquo;awaiting-resumbission&rsquo; they
            will be left with no comments for the authors to revise
          </p>
        </div>
      );
      html.push(
        <button
          style={{ marginTop: 20 }}
          className={`btn${adminRating.score !== null ? "" : " disabled"}`}
          onClick={() => {
            return this.props
              .unassignAbstractsFromJudges(
                this.props.eventId,
                abstractIds,
                this.state.unassignRated
              )
              .then(() => {
                this.setState({
                  adminRating: false,
                  bulkJudgeRate: false,
                  bulkUnrate: false,
                  selectedAll: false,
                  selected: [],
                  bulkJudgeUnassign: false,
                  unassignRated: false,
                });
              });
          }}
        >
          Unassign judges
        </button>
      );
      return html;
    }

    if (bulkJudgeAssign) {
      const judges = {};
      this.props.judges &&
        this.props.judges.map((judge) => {
          judges[judge.id] = `${judge.id} ${judge.firstName} ${judge.lastName}`;
        });
      html.push(
        <h5 key="heading">
          You will assign the following abstracts to judges:
        </h5>,
        <div className="form-group">
          <Select2
            options={judges}
            onChange={(val) => {
              this.setState({ judgeIds: val });
            }}
            placeholder={"Judges"}
            value={judgeIds.length === 0 ? "" : judgeIds}
            multi={true}
          />
        </div>
      );

      const abstractIds =
        selectedAll === false ? selected : [...abstracts].map((a) => a.id);
      html.push(<div>{abstractIds.join(", ")}</div>);

      html.push(
        <button
          style={{ marginTop: 20 }}
          className={`btn${adminRating.score !== null ? "" : " disabled"}`}
          onClick={() => {
            return this.props
              .assignAbstractsToJudges(
                this.props.eventId,
                abstractIds,
                this.state.judgeIds
              )
              .then(() => {
                this.setState({
                  adminRating: false,
                  bulkJudgeRate: false,
                  bulkUnrate: false,
                  selectedAll: false,
                  selected: [],
                  bulkJudgeUnassign: false,
                  unassignRated: false,
                  bulkJudgeAssign: false,
                  judgeIds: [],
                });
              });
          }}
        >
          Assign to judges
        </button>
      );
      return html;
    }

    if (adminRating === true) {
      if (bulkJudgeRate) {
        dataSet =
          selectedAll === false
            ? [...abstracts].filter(
                (a) => selected.includes(a.id) && a.score !== null
              )
            : [...abstracts].filter((a) => a.score !== null);

        html.push(
          <h5 key="heading">
            {dataSet.length === 0 ? "No" : ""} Applicable Abstracts (Rated by
            judges)
          </h5>
        );

        if (dataSet.length > 0) {
          let passing = 0;
          html.push(
            <div key="table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Abstract Id</th>
                    <th>Judge Score</th>
                    <th>Passing</th>
                  </tr>
                </thead>
                <tbody>
                  {dataSet.map((a, _i) => {
                    if (a.score >= passingScore) {
                      passing++;
                    }
                    return (
                      <tr key={_i}>
                        <td>{a.id}</td>
                        <td>
                          <strong>{a.score}</strong>
                        </td>
                        <td>
                          {a.score >= passingScore ? (
                            <span className="alert alert-success">Yes</span>
                          ) : (
                            <span className="alert alert-danger">No</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="alert alert-info">
                {passing} abstract{passing > 1 ? "s" : ""} will be approved,{" "}
                {dataSet.length - passing} will be rejected.
              </p>
            </div>
          );
        }
      } else {
        html.push(
          <table className="table" key="table">
            <tbody>
              <tr>
                <td>Selected Abstracts</td>
                <td>
                  {selectedAll === false
                    ? selected.join(", ")
                    : [...abstracts].map((a) => a.id).join(", ")}
                </td>
              </tr>
              <tr>
                <td>Passing Score</td>
                <td>{passingScore}</td>
              </tr>
            </tbody>
          </table>
        );
      }
    } else {
      html.push(
        <table key="table">
          <tbody>
            <tr>
              <td>Judges Score</td>
              <td>{this.getScoreHtml(adminRating)}</td>
            </tr>
            <tr>
              <td>Is abstract passing?</td>
              <td>
                {this.isAbstractPassing(adminRating)} ( Passing score:{" "}
                {passingScore} )
              </td>
            </tr>
            <tr>
              <td>Judged by</td>
              <td>{this.getJudgedBy(adminRating)}</td>
            </tr>
          </tbody>
        </table>
      );
    }

    if (bulkUnrate === false && bulkJudgeRate === false) {
      html.push(<h5 key="heading_2">Final Score</h5>);
    }
    if (bulkJudgeRate && dataSet.length === 0) return html;

    html.push(
      <div className="row form-container" key="other">
        {bulkUnrate !== true && (
          <div className="col-sm-6">
            {typeof this.state.adminRating === "object" && (
              <button
                className={`btn${
                  adminRating.score !== null ? "" : " disabled"
                }`}
                disabled={adminRating.score !== null ? "" : " disabled"}
                onClick={() => {
                  this.rate(adminRating.score);
                }}
              >
                Use Judges Score
              </button>
            )}
            <div
              className="row form-container abstract-rating-switch"
              key="other"
              style={{ marginTop: 15 }}
            >
              <div className="col-sm-8">Use User Type</div>
              <div className="col-sm-4">
                <Switch
                  isActive={Number(this.state.userType)}
                  onChange={(value) => this.setState({ userType: value })}
                />
              </div>
            </div>
            <Select
              options={options}
              onChange={(value) => this.setState({ typeId: value })}
              disabled={Boolean(this.state.userType)}
              value={
                this.state.typeId !== null && this.state.typeId
                  ? this.state.typeId
                  : null
              }
            />
          </div>
        )}
        <div
          className={
            adminRating !== true || bulkRate ? "col-sm-6" : "col-sm-12"
          }
        >
          <button
            style={{
              marginTop:
                bulkUnrate === false && bulkJudgeRate === true ? 10 : 0,
            }}
            className={`btn${adminScore !== null ? "" : " disabled"}`}
            onClick={() => this.rate(bulkUnrate === true ? null : adminScore)}
          >
            {bulkUnrate === false
              ? bulkJudgeRate === false
                ? "Assign your score"
                : "Assign Judges Scores"
              : "Unrate"}
          </button>
          {bulkUnrate === false && bulkJudgeRate === false && (
            <div>
              <input
                type="number"
                className="form-control"
                min={minScore}
                max={maxScore}
                placeholder="Final Score"
                value={adminScore || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value <= maxScore && value >= minScore) {
                    this.setState({ adminScore: e.target.value });
                  }
                }}
              />
              <div
                className={`alert alert-${
                  adminScore >= passingScore ? "success" : "danger"
                }`}
              >
                You are {adminScore >= passingScore ? "approving" : "rejecting"}{" "}
                {adminRating === true ? "those abstracts" : "this abstract"}
              </div>
            </div>
          )}
        </div>
      </div>
    );

    return html;
  }

  render() {
    const { abstracts, fetching, getAbstracts, meta } = this.props;
    const {
      p,
      rpp,
      totalRows,
      typeIds,
      topicIds,
      status,
      ratedBy,
      search,
      published,
      inSchedule,
    } = meta;

    const { selected, selectedAll } = this.state;

    const _filters = {};
    Object.entries({
      typeIds,
      topicIds,
      status,
      ratedBy,
      search,
      published,
      inSchedule,
    }).map(([k, v]) => {
      if (v !== undefined) {
        _filters[k] = [v];
      }
    });

    const viewModal =
      this.state.adminRating !== false ||
      this.state.bulkJudgeUnassign ||
      this.state.bulkPublish ||
      this.state.bulkUnPublish ||
      this.state.bulkJudgeAssign;

    if (this.props.fakeChild) {
      return (
        <SingleAbstract
          eventId={this.props.eventId}
          abstractId={this.props.fakeChild}
          back={() => this.props.changeTab("AbstractsList", null)}
        />
      );
    }
    return (
      <>
        <div className="tab-header">
          <div className="left">
            <div className="bulk">
              <Select
                placeholder={[
                  <span
                    key={"more-options"}
                    style={{ fontSize: 16 }}
                    className="icon-more-options"
                  ></span>,
                ]}
                className="more-actions"
                isDropDownButton={true}
                placeholderInsideSelect={false}
                isSearchable={false}
                onChange={(action) => this.bulkAction(action)}
                options={
                  !selectedAll && !selected.length
                    ? {
                        select: <span>Select Abstracts to view options</span>,
                      }
                    : !this.props.schema.finalize
                    ? {
                        bulk_rate: "Rate",
                        bulk_judge_rate: "Rate by Judge Score (If Applicable)",
                        bulk_unrate: "Unrate",
                        bulk_judge_assign: "Assign abstracts to judges",
                        bulk_judge_unassign: "Unassign abstracts from judges",
                        bulk_publish: "Publish abstracts",
                        bulk_unpublish: "Un-Publish abstracts",
                      }
                    : {
                        bulk_publish: "Publish abstracts",
                        bulk_unpublish: "Un-Publish abstracts",
                      }
                }
              />
            </div>
            <div className="">
              <Select
                className="more-actions select-button"
                border="none"
                isDropDownButton={true}
                isSearchable={false}
                hoverBackgroundColor="#f0f0f4"
                color={"#516673"}
                hoverColor={"#153447"}
                fontWeight={"500"}
                selectedBackground="#f0f0f4"
                placeholder={"Export"}
                isClearable={false}
                placeholderInsideSelect={false}
                options={{
                  exportAll: "Export All",
                  exportToExcel: "Export All to EXCEL",
                }}
                onChange={(action) => {
                  if (action === "exportAll") {
                    this.props
                      .getAbstracts({ p: 1, rpp: -1 }, _filters, true)
                      .then(
                        async (response) =>
                          await createAbstractFile(
                            response.data,
                            this.props.eventName
                          )
                      );
                  } else if (action === "exportToExcel") {
                    this.props
                      .getAbstracts(
                        { p: 1, rpp: -1, export: true },
                        _filters,
                        true
                      )
                      .then((file) => {
                        const blob = new Blob([file], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
                        });
                        const objectUrl = URL.createObjectURL(blob);
                        downloadFile(
                          objectUrl,
                          `Event-${this.props.eventId}-Abstracts.xlsx`
                        );
                      });
                  }
                }}
              />
            </div>
            <Filters
              onChange={(filters) => {
                return getAbstracts({ p: 1, rpp }, filters);
              }}
              schema={this.props.schema}
            />
          </div>
          <div className="right">
            <HeaderPagination
              p={p}
              rpp={rpp}
              totalRows={totalRows}
              onChangePage={(data) =>
                getAbstracts(Object.assign({}, { p, rpp }, data), _filters)
              }
            />
            <button
              type="button"
              className="btn create rounded"
              disabled={!Object.keys(this.props.schema).length}
              onClick={() => this.props.changeTab("AbstractsList", "create")}
            >
              Create
            </button>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-header-filters">
            <Statistics
              abstracts={abstracts}
              getStatistics={this.props.getStatistics}
              schema={this.props.schema}
              createSchema={this.props.createSchema}
            />
          </div>
          <Table
            id={"abstracts-list"}
            items={abstracts}
            columns={{
              bulkSelect: {
                name: "",
              },
              finalScore: {
                name: "Score",
              },
              id: {
                name: "ID",
              },
              name: {
                name: "Title",
              },
              status: {
                name: "Status",
              },

              types: {
                name: "Suggested Types",
              },
              type: {
                name: "Final Type",
              },
              topics: {
                name: "Topics",
              },
              judging: {
                name: "Judging Status",
              },
              judgeAverage: {
                name: "Judge Average Score",
              },
              actions: {
                name: "",
              },
            }}
            filtered={!!search}
            onThClick={(thClick) => thClick()}
            onRender={this.onRender}
            onRenderTh={this.onRenderTh}
            updating={fetching}
            containerClassName={"table-container "}
            onTrClassName={(className, item) => {
              let _className = "";
              if (item.finalScore !== null) {
                _className = "rated";
                _className +=
                  item.passingByAdmin === 1 ? " approved" : " rejected";
              }
              return _className;
            }}
          />
          <Modal
            show={viewModal}
            title={(() => {
              if (this.state.adminRating === true) {
                return `Bulk ${
                  this.state.bulkUnrate === false ? "Rate" : "Unrate"
                } Abstracts`;
              } else if (this.state.adminRating) {
                return `Rate Abstract #${this.state.adminRating.id}`;
              } else if (this.state.bulkJudgeUnassign) {
                return "Unassign Abstracts from Judges";
              } else if (this.state.bulkJudgeAssign) {
                return "Assign Abstracts to Judges";
              } else if (this.state.bulkPublish) {
                return "Publish Abstracts";
              } else if (this.state.bulkUnPublish) {
                return "Un-Publish Abstracts";
              }
            })()}
            onClose={() =>
              this.setState({
                adminRating: false,
                adminScore: this.props.schema.passingScore || "",
                bulkUnrate: false,
                bulkJudgeRate: false,
                bulkJudgeUnassign: false,
                unassignRated: false,
                bulkJudgeAssign: false,
                bulkPublish: false,
                bulkUnPublish: false,
              })
            }
            className={`med-table-container rate-abstract ${
              this.state.adminRating === true
                ? `bulk-${
                    this.state.bulkUnrate
                      ? "unrate"
                      : this.state.bulkJudgeRate
                      ? "judge-rate"
                      : "rate"
                  }`
                : "single-rate"
            }`}
          >
            {this.getHtml()}
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    abstracts: state.api.abstracts.list.data,
    fetching: state.api.abstracts.list.fetching,
    eventId: state.api.events.edit.data.id,
    eventName: state.api.events.edit.data.name,
    orgAdmin: state.appuser.data.orgAdmin ? true : false,
    schema: state.api.abstracts.currentSchema.data,
    meta: state.api.abstracts.list.meta,
    judges: state.api.abstracts.judges.data,
    fakeChild: state.editmenu.fakeChild,
  };
};

AbstractsList.propTypes = {
  getAbstracts: PropTypes.func.isRequired,
  abstracts: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  deleteItem: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  orgAdmin: PropTypes.bool.isRequired,
  schema: PropTypes.object,
  meta: PropTypes.object.isRequired,
  updateAbstract: PropTypes.func.isRequired,
  createSchema: PropTypes.func.isRequired,
  deleteAbstract: PropTypes.func.isRequired,
  showModal: PropTypes.func,
  getJudges: PropTypes.func,
  addNotification: PropTypes.func.isRequired,
  judges: PropTypes.array,
  unassignAbstractsFromJudges: PropTypes.func,
  publishAbstracts: PropTypes.func,
  getStatistics: PropTypes.func,
  changeTab: PropTypes.func,
  assignAbstractsToJudges: PropTypes.func,
  fakeChild: PropTypes.string,
  eventName: PropTypes.string,
};

export default connect(mapStateToProps, {
  getAbstracts,
  deleteAbstract,
  deleteItem,
  updateAbstract,
  createSchema,
  showModal,
  getJudges,
  addNotification,
  unassignAbstractsFromJudges,
  assignAbstractsToJudges,
  publishAbstracts,
  getStatistics,
  changeTab,
})(AbstractsList);
