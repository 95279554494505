import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import formFields from "@helpers/form-fields";
import { loadUsers } from "@actions/users";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import { updatePrice, createPrice } from "@actions/products";

const yesNoKeys = [];
class SinglePrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const options = {};
    if (this.props.policyGroups.length > 0) {
      this.props.policyGroups.map((group) => {
        options[group.id] = `${group.eventPolicyName} - ${group.name}`;
      });
    }
    formFields.forms.products.prices.eventPolicyGroupId.options = options;

    return (
      <React.Fragment>
        {this.props.h2 === "Edit" ? (
          <h2>Edit price</h2>
        ) : (
          <h2>Create new price</h2>
        )}
        <br />
        <div className="new-staff form-container">
          <Form
            componentName={"products"}
            formName="prices"
            value={this.props.h2 === "Edit" ? this.props.item : {}}
            onSubmit={(data) => {
              if (isEmpty(data.errors)) {
                Object.entries(data.value).map(([k, v]) => {
                  if (yesNoKeys.indexOf(k) !== -1) {
                    if (v === "yes") {
                      data.value[k] = true;
                    } else if (v === "no") {
                      data.value[k] = false;
                    }
                  }
                  if (data.value[k] === "") {
                    delete data.value[k];
                  }
                });
                if (this.props.h2 === "Edit") {
                  this.props.updatePrice(
                    this.props.eventId,
                    this.props.productId,
                    this.props.item.id,
                    data.value
                  );
                } else {
                  this.props.createPrice(
                    this.props.eventId,
                    this.props.productId,
                    data.value
                  );
                }
              }
            }}
            onCancel={this.props.hideModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

SinglePrice.propTypes = {
  eventId: PropTypes.number,
  h2: PropTypes.string,
  hideModal: PropTypes.func,
  productId: PropTypes.number,
  listGroups: PropTypes.func,
  policyGroups: PropTypes.array,
  createPrice: PropTypes.func.isRequired,
  item: PropTypes.object,
  updatePrice: PropTypes.func,
};

SinglePrice.defaultProps = {
  value: {},
};
const mapStateToProps = (state) => {
  return {
    policyId: state.page.params.pid,
    policyGroups: state.api.policyGroups.list.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (queryString, policyId, eventId) => {
      dispatch(
        loadUsers(
          eventId,
          policyId,
          { search: queryString },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    createPrice: (eventId, productId, data) => {
      dispatch(createPrice(eventId, productId, data));
    },
    updatePrice: (eventId, productId, priceId, data) => {
      dispatch(updatePrice(eventId, productId, priceId, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SinglePrice);
