import React from "react";
import Form from "@components/form";
import formFields from "@helpers/form-fields";
import PropTypes from "prop-types";
import momentTimezones from "moment-timezone";
import Select from "@layout/Select2";
import ImageCover from "@layout/ImageCover";

export default class BasicInfo extends React.Component {
  constructor(props) {
    super(props);

    formFields.forms.events.basicInfo.duration.autoTime = true;
    this.zones = {};
    this.state = {
      imageUrl: this.props.event.banners[0]?.url,
      name: this.props.event.name,
      location: this.props.event.location,
      timezone: this.props.event.timezone,
    };
    // eslint-disable-next-line
    momentTimezones.tz.names().map((z) => {
      this.zones[z] = `${z} ${momentTimezones().tz(z).format("Z")}`;
    });
    this.onChange = this.onChange.bind(this);
    this.banners = null;
  }

  update(id, value = {}) {
    value["name"] = this.state.name;
    value["location"] = this.state.location;
    value["timezone"] = this.state.timezone;
    if (this.banners) {
      value["banners"] = this.banners;
    }
    this.props.updateEvent(id, value);
  }
  onChange(key, value) {
    this.setState({ [key]: value });
  }
  render() {
    const { event, fetching, updating } = this.props;

    const e = Object.assign({}, event, {
      startDate: event.startDate.tz,
      endDate: event.endDate.tz,
    });
    const onCancel = () => {
      this.setState({
        imageUrl: this.props.event.banners[0]?.url,
        name: this.props.event.name,
        location: this.props.event.location,
        timezone: this.props.event.timezone,
      });
    };
    return (
      <div className="tab-content">
        {!fetching && (
          <React.Fragment>
            <div className="form-container row">
              <div className="col-xs-12 col-sm-6 row-blue">
                <div className="form-group">
                  <ImageCover
                    height={"300px"}
                    imageUrl={this.state.imageUrl}
                    name="Event Banners"
                    description="This is for backoffice management purposes and login/registration in client panel"
                    onChange={(file, deletefile) => {
                      if (deletefile) {
                        this.setState({
                          imageUrl: "",
                        });
                        this.banners = [this.props.event.banners[0]?.id];
                      } else if (window.FileReader && file) {
                        const fr = new FileReader();
                        fr.onload = () => {
                          this.banners = [file];
                          this.setState({
                            imageUrl: fr.result,
                          });
                        };
                        fr.readAsDataURL(file);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 row-blue">
                <div className="form-group field-type type-text kmb-text">
                  <label>Event Name</label>
                  <textarea
                    style={{
                      maxHeight: 70,
                      minHeight: 70,
                      height: 70,
                    }}
                    className="form-control"
                    value={this.state.name}
                    onChange={(e) => this.onChange("name", e.target.value)}
                  />
                  <div>
                    <span className="icon icon-edit"></span>
                  </div>
                </div>
                <div className="form-group field-type type-text kmb-text">
                  <label>Event Location</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.location}
                    onChange={(e) => this.onChange("location", e.target.value)}
                  />
                  <div>
                    <span className="icon icon-edit"></span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="field-type type-select kmb-select ">
                    <label>Event Timezone</label>
                    <button
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: 22,
                        fontSize: 12,
                      }}
                      className="btn btn-cancel"
                      onClick={() => {
                        const timezone =
                          Intl.DateTimeFormat().resolvedOptions().timeZone;
                        this.onChange("timezone", timezone);
                      }}
                    >
                      Locate me
                    </button>
                    <Select
                      name="timezone"
                      id="timezone"
                      isClearable={false}
                      options={this.zones}
                      value={this.state.timezone}
                      onChange={(value) => this.onChange("timezone", value)}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <Form
                componentName="events"
                formName="basicInfo"
                value={e}
                updating={updating}
                onSubmit={(data) => {
                  if (Object.keys(data.errors).length) {
                    return false;
                  }
                  this.update(event.id, data.value);
                }}
                onCancel={onCancel}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

BasicInfo.propTypes = {
  event: PropTypes.object.isRequired,
  loadEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  updating: PropTypes.bool.isRequired,
};
