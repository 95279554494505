import React, { useEffect } from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "@helpers";
import { hideModal } from "@actions";
import { updateRentable, createRentable } from "@actions/rentables";
import formFields from "@helpers/form-fields";
import { listGroups } from "@actions/policyGroups";

const yesNoKeys = [];
const SingleRentable = ({ eventId, h2, item }) => {
  const dispatch = useDispatch();
  const policyGroups = useSelector(
    (state) => state.api.policyGroups?.list?.data
  );

  // Corrected dependency array to include eventId
  useEffect(() => {
    if (!dispatch) return;
    dispatch(listGroups(eventId));
  }, [dispatch, eventId]);

  // Use policyGroups directly instead of policyGroups.length
  useEffect(() => {
    if (!policyGroups.length) return;
    const options = policyGroups.reduce((acc, group) => {
      acc[group.id] = `${group.eventPolicyName} - ${group.name}`;
      return acc;
    }, {});

    formFields.forms.rentables.single.eventPolicyGroupId.options = options;
  }, [policyGroups]);

  const handleSubmit = (data) => {
    if (isEmpty(data.errors)) {
      Object.entries(data.value).forEach(([k, v]) => {
        if (yesNoKeys.indexOf(k) !== -1) {
          data.value[k] = v === "yes";
        }
        if (data.value[k] === "") {
          delete data.value[k];
        }
      });
      if (h2 === "Edit") dispatch(updateRentable(eventId, item.id, data.value));
      else dispatch(createRentable(eventId, data.value));
    }
  };

  return (
    <>
      <h2>{h2 === "Edit" ? "Edit the Rentable" : "Create new Rentable"}</h2>
      <br />
      <div className="new-staff form-container">
        <Form
          componentName={"rentables"}
          formName="single"
          value={h2 === "Edit" ? item : {}}
          onSubmit={handleSubmit}
          onCancel={() => dispatch(hideModal())}
        />
      </div>
    </>
  );
};

SingleRentable.propTypes = {
  eventId: PropTypes.number.isRequired,
  h2: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default SingleRentable;
