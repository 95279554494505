import React from "react";
import Input from "@layout/Input";
import Select2 from "@layout/Select2";
import Switch from "@layout/Switch";
import { makePassword } from "@helpers";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";

export default function SinglePolicy(props) {
  const [name, setName] = React.useState(props.item.name || "");
  const [type, setType] = React.useState(props.item.type || "");
  const [primary, setPrimary] = React.useState(props.item.default || false);
  const [errors, setErrors] = React.useState({
    name: false,
    type: false,
  });

  const types = {
    public: "Public",
    private: "Private",
  };

  const validateFields = () => {
    const newErrors = {
      name: !name.trim(),
      type: !type,
    };

    setErrors(newErrors);

    return !newErrors.name && !newErrors.type;
  };

  const onSubmit = () => {
    let settings = {};

    if (!validateFields()) return;

    if (!props.item.id) {
      if (type === "public") {
        settings = {
          settings: {
            firstName: { name: "First Name", askUser: 1, required: 1 },
            lastName: { name: "Last Name", askUser: 1, required: 1 },
            email: { name: "Email", askUser: 1 },
            password: {
              name: "Password",
              askUser: 1,
              identifier: 1,
              required: 1,
              value: makePassword(5),
            },
          },
          onlineRegistration: 1,
        };
      } else {
        settings = {
          settings: {
            firstName: { name: "First Name", askUser: 1, required: 1 },
            lastName: { name: "Last Name", askUser: 1, required: 1 },
            email: {
              name: "Email",
              askUser: 1,
              required: 1,
              identifier: 1,
              unique: 1,
            },
            password: {
              name: "Password",
              askUser: 1,
              identifier: 1,
              required: 1,
              value: "",
            },
          },
        };
      }
    }
    props.onSave(
      props.eventId,
      { name, type, default: primary, ...settings },
      props.item.id
    );
  };
  return (
    <div className="form-container">
      <h2>{props.item.id ? "Edit Policy" : "Create Policy"}</h2>
      <div className={`form-group ${errors.name ? "has-error" : ""}`}>
        <label>Name (*)</label>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
        {errors.name && <div className="help-block">Name is required</div>}
      </div>
      <div className={`form-group ${errors.type ? "has-error" : ""}`}>
        <div>
          <label>Type (*)</label>
          <Select2
            disabled={!!props.item.type}
            options={types}
            onChange={(val) => setType(val)}
            placeholder={"Select Type"}
            value={type}
            isSearchable={false}
            isClearable={false}
          />
          {errors.type && <div className="help-block">Type is required</div>}
        </div>
      </div>
      <div className="form-group policy-switch">
        <label>Default</label>
        <p className="subtitle">
          Note: Enabling this option will affect the client panel default access
          policy login
        </p>
        <Switch
          id={`policy-default-${props.item.id}`}
          isActive={primary}
          onChange={(active) => setPrimary(active)}
        />
      </div>
      <SubmitContainer onCancel={props.hideModal} onSubmit={onSubmit} />
    </div>
  );
}

SinglePolicy.propTypes = {
  item: PropTypes.object,
  eventId: PropTypes.number,
  onSave: PropTypes.func,
  hideModal: PropTypes.func,
};
