import { api } from "@actions/ajax";
import { addNotification } from "@actions";
import { uploadFile } from ".";

export const getSponsors =
  (orgId, meta = false) =>
  (dispatch, getState) => {
    const request = {
      endpoint: "sponsors",
      action: "list",
      params: { orgId },
    };
    const defaults = {
      rpp: 20,
      search: "",
      orderBy: "name",
      order: "ASC",
      p: 1,
    };

    const state = getState();
    const buildMeta = {};

    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });

      if (
        meta.hasOwnProperty("orderBy") &&
        state.api.sponsors.meta.orderBy === buildMeta.orderBy
      ) {
        buildMeta.order =
          state.api.sponsors.meta.order === "ASC" ? "ASC" : "DESC";
      }
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }
    return dispatch(api(request));
  };

export const getSponsor = (orgId, sponsorId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sponsors",
      action: "singleSponsor",
      params: { orgId, sponsorId },
    })
  );

export const getPersons = (orgId, sponsorId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sponsors",
      action: "personsList",
      params: { orgId, sponsorId },
    })
  );
export const deleteSponsorship =
  (eventId, sponsorshipId, meta = null) =>
  (dispatch, getState) => {
    const request = {
      endpoint: "sponsors",
      action: "deleteSponsorshipStatus",
      params: { eventId, sponsorshipId },
    };
    const defaults = {
      rpp: 20,
      search: "",
      orderBy: "id",
      order: "ASC",
      p: 1,
      eventUserId: null,
      type: "",
      orgSponsorId: null,
    };

    const state = getState();
    const buildMeta = {};

    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });

      if (
        meta.hasOwnProperty("orderBy") &&
        state.api.sponsors.meta.orderBy === buildMeta.orderBy
      ) {
        buildMeta.order =
          state.api.sponsors.meta.order === "ASC" ? "ASC" : "DESC";
      }
    }
    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }

    return dispatch(api(request))
      .then(() => {
        dispatch(
          addNotification("Sponsorship deleted successfully", "success")
        );
      })
      .then(() => dispatch(getSponsorshipStatus(eventId, meta)));
  };

export const createSponsorship = (eventId, data) => (dispatch) => {
  return dispatch(
    api({
      endpoint: "sponsors",
      action: "sponsorship",
      params: { eventId },
      body: {
        data,
      },
    })
  ).then(() => {
    dispatch(addNotification("Sponsorship submitted successfully", "success"));
  });
};

export const updateSponsorship =
  (eventId, sponsorshipId, data) => (dispatch) => {
    return dispatch(
      api({
        endpoint: "sponsors",
        action: "updateSponsorshipStatus",
        params: { eventId, sponsorshipId },
        body: {
          data,
        },
      })
    ).then(() => {
      dispatch(addNotification("Sponsorship updated successfully", "success"));
    });
  };
export const createSponsorshipAccomodation =
  (
    eventId,
    orgSponsorId,
    orgSponsorPersonId,
    eventUserId,
    amount,
    eventReservationId,
    sponsorMethod,
    paymentDate
  ) =>
  (dispatch) =>
    dispatch(
      api({
        endpoint: "sponsors",
        action: "sponsorship",
        params: { eventId },
        body: {
          data: {
            orgSponsorId,
            orgSponsorPersonId,
            type: "accomodation",
            referenceId: eventReservationId,
            eventUserId,
            amount,
            paymentType: sponsorMethod,
            paymentDate: paymentDate,
          },
        },
      })
    ).then(() => {
      dispatch(
        addNotification("Sponsorship submitted successfully", "success")
      );
    });
export const getSponsorshipStatus =
  (eventId, meta = false) =>
  (dispatch) => {
    const request = {
      endpoint: "sponsors",
      action: "sponsorshipStatus",
      params: { eventId },
    };
    const defaults = {
      rpp: 20,
      orderBy: "id",
      order: "DESC",
      p: 1,
      statistics: true,
    };

    const buildMeta = {};
    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });
      Object.keys(meta).forEach((key) => {
        if (key === "dateRange") {
          buildMeta[key] = JSON.stringify({
            from: meta[key].from,
            to: meta[key].to,
          });
        } else if (key === "eventUserId" && meta[key] === "") {
          // console.log("Hi this should not be happening, i am sorry :)");
        } else {
          buildMeta[key] = meta[key];
        }
      });
    }

    if (meta === false) {
      Object.keys(defaults).forEach((key) => {
        buildMeta[key] = defaults[key];
      });
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }
    request.cache = false;
    dispatch(api(request));
  };
export const createSponsor = (orgId, data) => (dispatch) => {
  const filesToUpload = {
    sponsorThumbnail: Object.assign([], data.sponsorThumbnail),
  };
  if (
    data.sponsorThumbnail?.length > 0 &&
    typeof data.sponsorThumbnail[0] !== "number"
  ) {
    data.sponsorThumbnail[0] = data.sponsorThumbnail[0].name;
  }

  const filesToUploadProps = Object.keys(filesToUpload);
  let hasFiles = false;
  filesToUploadProps.forEach((key) => {
    if (filesToUpload[key]?.length > 0) hasFiles = true;
  });

  const callback = () => {
    dispatch(getSponsors(orgId));
  };

  return dispatch(
    api({
      endpoint: "sponsors",
      action: "create",
      params: { orgId },
      body: { data },
    })
  ).then((data) => {
    if (hasFiles) {
      return dispatch(
        uploadFile(
          null,
          "sponsorThumbnail",
          filesToUpload.sponsorThumbnail[0],
          { organisationId: orgId, sponsorId: data.data.id },
          callback,
          null,
          filesToUpload.sponsorThumbnail.length,
          true,
          orgId
        )
      );
    }
    return callback();
  });
};

export const createPerson = (orgId, sponsorId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sponsors",
      action: "personsCreate",
      params: { orgId, sponsorId },
      body: {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          notes: data.notes,
        },
      },
    })
  )
    .then(() => dispatch(getPersons(orgId, sponsorId)))
    .then(() =>
      dispatch(addNotification("Person created successfully", "success"))
    );

export const deleteSponsor = (orgId, sponsorId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sponsors",
      action: "delete",
      params: { orgId, sponsorId },
      body: {
        data: {},
      },
    })
  )
    .then(() => dispatch(getSponsors(orgId)))
    .then(() =>
      dispatch(addNotification("Sponsor deleted successfully", "success"))
    );
export const deletePerson = (orgId, sponsorId, personId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sponsors",
      action: "personsDelete",
      params: { orgId, sponsorId, personId },
      body: {
        data: {},
      },
    })
  )
    .then(() => dispatch(getPersons(orgId, sponsorId)))
    .then(() =>
      dispatch(
        addNotification("Sponsor Person deleted successfully", "success")
      )
    );

export const updateSponsor = (orgId, data, sponsorId) => (dispatch) => {
  const filesToUpload = {
    sponsorThumbnail: Object.assign([], data.sponsorThumbnail),
  };
  if (
    data.sponsorThumbnail?.length > 0 &&
    typeof data.sponsorThumbnail[0] !== "number"
  ) {
    data.sponsorThumbnail[0] = data.sponsorThumbnail[0].name;
  }

  let deletionAction = false;
  data.sponsorThumbnail.forEach((file) => {
    if (typeof file === "number") {
      deletionAction = true;
    }
  });

  const callback = () => {
    dispatch(getSponsors(orgId));
  };

  if (data.sponsorThumbnail.length === 0) {
    delete data.sponsorThumbnail;
  }

  return dispatch(
    api({
      endpoint: "sponsors",
      action: "update",
      params: { orgId, sponsorId },
      body: { data },
    })
  ).then(() => {
    if (data.sponsorThumbnail.length > 0 && !deletionAction) {
      return dispatch(
        uploadFile(
          null,
          "sponsorThumbnail",
          filesToUpload.sponsorThumbnail[0],
          { organisationId: orgId, sponsorId },
          callback,
          null,
          filesToUpload.sponsorThumbnail.length,
          true,
          orgId
        )
      );
    }
    return callback();
  });
};

export const updatePerson = (orgId, sponsorId, personId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sponsors",
      action: "personsUpdate",
      params: { orgId, sponsorId, personId },
      body: {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email,
          notes: data.notes,
        },
      },
    })
  )
    .then(() => dispatch(getPersons(orgId, sponsorId)))
    .then(() =>
      dispatch(addNotification("Person updated successfully", "success"))
    );
