import { getUserNotifications } from "@actions/appuser";

const notificationObj = {
  obj: {
    type: "info",
    title: "",
    message: "",
    className: "",
    link: false,
    date: false,
    dismissable: true,
    resolvable: true,
    resolveLabel: "view",
    dismissLabel: "ok",
    pseudoId: null,
    viewed: 0,
    dismissCallback: () => {},
    resolveCallback: () => {},
  },

  init: (data, state) => {
    const pseudoId = `n-${state.appuser.notifications.length}`;
    return Object.assign({}, notificationObj.obj, data, { pseudoId });
  },

  initListener: (store) => {
    window.addEventListener("storage", function (event) {
      const key = event.key;
      const storage = event.storageArea;
      const state = store.getState();

      if (!state.appuser.ready) {
        return;
      }

      const userId = state.appuser.data.id;

      if (
        !storage.hasOwnProperty("bluetree_admin_token") ||
        localStorage.getItem("bluetree_admin_token") !==
          storage["bluetree_admin_token"]
      ) {
        return;
      }

      if (key === `notifications_user_${userId}`) {
        if (event.newValue === "invalid") {
          store.dispatch(getUserNotifications(true));
          notificationObj.storageValidate(state.appuser.data.id);
        }
        return;
      }

      if (key.substring(0, 16) === "offline_scanner_") {
        store.dispatch(getUserNotifications(true));
      }
    });
  },

  storageInvalidate: (userId) => {
    localStorage.setItem(`notifications_user_${userId}`, "invalid");
  },

  storageValidate: (userId) => {
    localStorage.setItem(`notifications_user_${userId}`, "valid");
  },

  storageGetNotifications: (user) => {
    const userId = user.data.id;
    return JSON.parse(localStorage.getItem(`notifications_user_${userId}`));
  },
};

export default notificationObj;
