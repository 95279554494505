import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "@reducers";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import Login from "../components/login/Login";
import Wrapper from "./partials/Wrapper";
import { ProvideAuth, useAuth } from "./partials/authProvider";
import { createLogger } from "redux-logger";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ChangePassword from "../components/login/ChangePassword";
import ForgotPassword from "../components/login/ForgotPassword";
const loggerMiddleware = createLogger();

let storeRef;

if (process.env.REACT_APP_LOG_STATE) {
  storeRef = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  );
} else {
  storeRef = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunkMiddleware)
  );
}

export const store = storeRef;
export default function AuthExample() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <ProvideAuth>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route
                path="/change-password/:token"
                element={<ChangePassword />}
              ></Route>
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route path="/*" element={<Protected />}></Route>
            </Routes>
          </Router>
        </ProvideAuth>
      </Provider>
    </DndProvider>
  );
}

function Protected() {
  const { authenticated } = useAuth();
  if (!authenticated) return <Navigate replace to="/login" />;
  return (
    <>
      <Routes>
        <Route
          path="/administrator/events"
          element={<Wrapper title={"list-events"} />}
        ></Route>
        <Route
          path="/administrator/events/:eventId/basic-setup"
          element={<Wrapper title={"edit-event"} innerPage={"basic_info"} />}
        ></Route>
        <Route
          path="/administrator/events/:eventId/participants"
          element={<Wrapper title={"edit-event"} innerPage={"registration"} />}
        ></Route>
        <Route
          path="/administrator/events/:eventId/schedule"
          element={<Wrapper title={"edit-event"} innerPage={"schedule"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/accommodation"
          element={<Wrapper title={"edit-event"} innerPage={"accommodation"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/abstracts"
          element={<Wrapper title={"edit-event"} innerPage={"abstracts"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/products"
          element={<Wrapper title={"edit-event"} innerPage={"products"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/client-panel"
          element={
            <Wrapper title={"edit-event"} innerPage={"client_builder"} />
          }
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/communication"
          element={<Wrapper title={"edit-event"} innerPage={"communication"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/statistics"
          element={<Wrapper title={"edit-event"} innerPage={"statistics"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/payments"
          element={<Wrapper title={"edit-event"} innerPage={"payments"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/exhibition-hall"
          element={<Wrapper title={"edit-event"} innerPage={"exhibition"} />}
        ></Route>{" "}
        <Route
          path="/administrator/events/:eventId/other-settings"
          element={<Wrapper title={"edit-event"} innerPage={"settings"} />}
        ></Route>
        <Route
          path="/administrator/crm"
          element={<Wrapper title={"list-crm"} />}
        ></Route>
        <Route
          path="/administrator/hotels"
          element={<Wrapper title={"accommodation"} />}
        ></Route>
        <Route
          path="/administrator/hotels/:hotelId/rooms"
          element={<Wrapper title={"accommodation-rooms"} />}
        ></Route>
        <Route
          path="/administrator/sponsors"
          element={<Wrapper title={"list-sponosors"} />}
        ></Route>
        <Route
          path="/administrator/sponsors/:sponsorId/persons"
          element={<Wrapper title={"list-sponosors-persons"} />}
        ></Route>
        <Route
          path="/administrator/staff"
          element={<Wrapper title={"list-staff"} />}
        ></Route>
        <Route
          path="/administrator/staff/:staffId/roles"
          element={<Wrapper title={"list-staff-roles"} />}
        ></Route>
        <Route
          path="/administrator/bank-accounts"
          element={<Wrapper title={"bank-accounts"} />}
        ></Route>
        <Route
          path="/judge/abstracts"
          element={<Wrapper title={"abstracts"} />}
        ></Route>
        <Route
          path="/judge/abstracts/:eventId/:abstractId"
          element={<Wrapper title={"abstract"} />}
        ></Route>
        <Route path="/change-password" element={<ChangePassword />}></Route>
        <Route
          path="/administrator/myaccount"
          element={<Wrapper title={"myaccount"} />}
        ></Route>
        <Route
          path="/events/:eventId/minutescanning"
          element={<Wrapper title={"minutes-scanning"} />}
        ></Route>
        <Route
          path="/events/:eventId/:roomId/scanning"
          element={<Wrapper title={"room-scanning"} />}
        ></Route>
        <Route
          path="/*"
          element={<Navigate replace to="/administrator/events" />}
        />
      </Routes>
    </>
  );
}
