import React from "react";
import PropTypes from "prop-types";
export default function Input(props) {
  return (
    <div
      className="field-type type-text kmb-text"
      style={{
        width: props.width || "100%",
      }}
    >
      <div>
        <input {...props} className={`form-control ${props.className || ""}`} />
        <span className="icon icon-edit"></span>
      </div>
    </div>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
};
