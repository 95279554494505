import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import Select2 from "@layout/Select2";
import { connect } from "react-redux";
import { api } from "@config/conf";
import Input from "@layout/Input";
import { updateEvent, addNotification } from "@actions";
import CopyInput from "./partials/CopyInput";
import DangerZone from "./partials/DangerZone";

const AccessLanguage = (props) => {
  const [freeAccess, setFreeAccess] = React.useState(props.event.freeAccess);
  const [language, setLanguage] = React.useState(
    props.event.clientPanelLanguage
  );
  const [urlAlias, setUrlAlias] = React.useState(
    api.clientUrl + "/" + props.event.urlAlias
  );

  return (
    <React.Fragment>
      <div className="panel-content-card access-language-content">
        <div className="field-section">
          <div className="field">
            <label>Client Access</label>
            <Select2
              className="access-language-select client-access-select"
              options={{ 0: "Private Access", 1: "Public Access" }}
              onChange={(value) => setFreeAccess(value)}
              placeholder={"Select"}
              value={freeAccess}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="description">
            Public access conferences allow basic and restricted access to the
            client panel of a conference when the user is not logged in. If you
            want to block the user from viewing any information about the
            conference select Private Access. The user will be required to
            login/register before entering the panel.
          </div>
        </div>

        <div className="field-section">
          <div className="field">
            <label>Url Alias</label>
            <div className="url-generator">
              <Input
                value={urlAlias}
                className="dynamic-url"
                onChange={(value) => {
                  if (value.target.value.includes(api.clientUrl + "/")) {
                    const theUrl = api.clientUrl;
                    const theRealVal = value.target.value.replace(
                      api.clientUrl + "/",
                      ""
                    );
                    const finalString = theUrl + "/" + theRealVal;
                    setUrlAlias(finalString);
                  } else {
                    setUrlAlias(api.clientUrl + "/");
                  }
                }}
              />
              <CopyInput
                disabled={
                  props.event.urlAlias !==
                  urlAlias.replace(api.clientUrl + "/", "")
                }
              />
            </div>
          </div>
          <div className="description">
            The url alias is the code-name that will be added to the url of your
            client panel. For non-default policies, the url of the client panel
            will also contain the ID of that policy. You may copy these urls
            once you have saved any changes you ve made to the url alias of your
            conference.
          </div>
        </div>

        <div className="field-section">
          <div className="field">
            <label>Language</label>
            <Select2
              className="input"
              options={{ english: "English", greek: "Greek" }}
              onChange={(value) => setLanguage(value)}
              placeholder={"Language"}
              value={language}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="description">
            Select the language for the client panel. This will change all text
            the client panel according to your selection.
          </div>
        </div>

        <SubmitContainer
          fetching={props.fetching}
          onSubmit={() => {
            props.updateEvent(props.event.id, {
              urlAlias: urlAlias.replace(api.clientUrl + "/", ""),
              freeAccess: Boolean(parseInt(freeAccess)),
              clientPanelLanguage: language,
            });
          }}
          onCancel={() => {
            setUrlAlias(api.clientUrl + "/" + props.event.urlAlias);
            setFreeAccess(props.event.freeAccess);
            setLanguage(props.event.clientPanelLanguage);
          }}
          disabled={
            props.fetching ||
            (props.event.urlAlias ===
              urlAlias.replace(api.clientUrl + "/", "") &&
              props.event.freeAccess === freeAccess &&
              props.event.clientPanelLanguage === language)
          }
          cancelDisabled={
            props.event.urlAlias ===
              urlAlias.replace(api.clientUrl + "/", "") &&
            props.event.freeAccess === freeAccess &&
            props.event.clientPanelLanguage === language
          }
          submitText="Save Changes"
        />
      </div>

      <DangerZone />
    </React.Fragment>
  );
};

AccessLanguage.propTypes = {
  activeItem: PropTypes.object.isRequired,
  policies: PropTypes.array,
  addNotification: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    policies: state.api.accesspolicies?.list?.data,
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (msg, status) => dispatch(addNotification(msg, status)),
    updateEvent: (eventId, event) => dispatch(updateEvent(eventId, event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessLanguage);
