import React from "react";
import PropTypes from "prop-types";

const ExhibitionSlots = (props) => {
  const [state, setState] = React.useState({ slots: [], hasChanges: [] });

  const getSlots = () => {
    const slots = [];
    props.slots.forEach((slot) =>
      slots.push(
        ...Array(props.numberOfSlots).fill({ id: slot.id, price: slot.price })
      )
    );
    slots.push(...Array(props.numberOfSlots).fill({ price: "" }));
    return slots;
  };

  React.useEffect(() => {
    const slots = getSlots();
    setState({ hasChanges: [], slots });
  }, [props.slots]);

  const onChangePrice = (e, i) => {
    if (!isFinite(e.target.value)) return;
    const newState = { ...state };
    newState.slots[i].price = e.target.value;

    if (i == state.slots.length - props.numberOfSlots) {
      newState.slots.push(...Array(props.numberOfSlots).fill({ price: "" }));
    } else if (
      i === state.slots.length - props.numberOfSlots * 2 &&
      e.target.value === ""
    ) {
      newState.slots.splice(-props.numberOfSlots);
    }
    props.onChange(props.componentName, newState.slots);
    setState(newState);
  };

  return (
    <div className="slot-container row">
      {state.slots.map((row, i) => {
        const disabled = i % props.numberOfSlots;
        return (
          <div
            className={`col-sm-${12 / props.numberOfSlots}`}
            key={`slot-${i}`}
          >
            <input
              placeholder={!disabled ? "Type a price..." : ""}
              disabled={disabled}
              className={`slot-item${disabled ? " disabled" : ""}`}
              onChange={(e) => onChangePrice(e, i)}
              value={row.price}
            />
          </div>
        );
      })}
    </div>
  );
};

ExhibitionSlots.defaultProps = {
  slots: [],
};
ExhibitionSlots.propTypes = {
  slots: PropTypes.array.isRequired,
  name: PropTypes.string,
  componentName: PropTypes.string,
  numberOfSlots: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default ExhibitionSlots;
