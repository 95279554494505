export const api = {
  url: process.env.REACT_APP_BACKEND_URL,
  clientUrl: process.env.REACT_APP_CLIENT_URL,
  googleMapKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  dymoFrameworkUrl:
    "http://download.dymo.com/dymo/Software/Win/DLS8Setup.8.7.3.exe",
  endpoints: {
    patch: {
      speakers: {
        method: "PUT",
        url: "admin/events/${eventId}/patch-speakers/",
      },
    },
    storage: {
      presignedURL: {
        method: "POST",
        url: "admin/events/${eventId}/signedurl",
      },
      saveFile: {
        method: "POST",
        url: "admin/events/${eventId}/savefile",
      },
      orgPresignedURL: {
        method: "POST",
        url: "admin/organisations/${orgId}/signedurl",
      },
      orgSaveFile: {
        method: "POST",
        url: "admin/organisations/${orgId}/savefile",
      },
    },
    advanced: {
      list: {
        method: "POST",
        url: "admin/events/${eventId}/advanced-search",
      },
    },
    user: {
      get: {
        method: "GET",
        url: "admin/user",
      },
      getNotifications: {
        method: "GET",
        url: "admin/user/notifications",
      },
      notificationsRead: {
        method: "PUT",
        url: "admin/user/notifications",
      },
    },
    authenticate: {
      login: {
        method: "POST",
        url: "admin/login",
      },
      logout: {
        method: "PUT",
        url: "admin/logout",
      },
      changePassword: {
        method: "POST",
        url: "admin/change-password",
      },
      forgotPassword: {
        method: "POST",
        url: "admin/forgot-password",
      },
    },
    events: {
      list: {
        method: "GET",
        url: "admin/events",
      },
      recent: {
        method: "GET",
        url: "admin/events",
      },
      edit: {
        method: "GET",
        url: "admin/events/${eventId}/${section}",
      },
      editPayments: {
        method: "GET",
        url: "admin/events/${eventId}/${section}",
      },
      updateEventFeatureStatus: {
        method: "PUT",
        url: "admin/events/${eventId}/features/",
      },
      updateEventFeaturesOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/features/order",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/${section}",
      },
      createClientPanelVideoSponsor: {
        method: "POST",
        url: "admin/events/${eventId}/client-panel-settings/videos-sponsors/",
      },
      updateClientPanelVideoSponsorOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/videos-sponsors/",
      },
      deleteClientPanelVideoSponsor: {
        method: "DELETE",
        url: "admin/events/${eventId}/client-panel-settings/videos-sponsors/${videoSponsorId}",
      },
      createClientPanelFooterSponsor: {
        method: "POST",
        url: "admin/events/${eventId}/client-panel-settings/footer-sponsors/",
      },
      deleteClientPanelFooterSponsor: {
        method: "DELETE",
        url: "admin/events/${eventId}/client-panel-settings/footer-sponsors/${footerSponsorId}",
      },
      createClientPanelSponsor: {
        method: "POST",
        url: "admin/events/${eventId}/client-panel-settings/sponsors/",
      },
      deleteClientPanelSponsor: {
        method: "DELETE",
        url: "admin/events/${eventId}/client-panel-settings/sponsors/${sponsorId}",
      },
      createClientPanelSponsorType: {
        method: "POST",
        url: "admin/events/${eventId}/client-panel-settings/sponsors-types/",
      },
      deleteClientPanelSponsorType: {
        method: "DELETE",
        url: "admin/events/${eventId}/client-panel-settings/sponsors-types/${sponsorTypeId}",
      },
      updateClientPanelSettings: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/${section}/${sectionId}",
      },

      updateClientPanelFooterSponsorOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/footer-sponsors/",
      },

      updateClientPanelSponsorsOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/sponsors/",
      },
      updateClientPanelSponsorsTypesOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/sponsors-types/",
      },
      createClientPanelEbag: {
        method: "POST",
        url: "admin/events/${eventId}/client-panel-settings/ebag/",
      },
      deleteClientPanelEbag: {
        method: "DELETE",
        url: "admin/events/${eventId}/client-panel-settings/ebag/${ebagId}",
      },
      updateClientPanelEbagOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/ebag/",
      },

      updateClientPanelSpeakersOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/speakers-chairpersons/",
      },
      createClientPanelHomeCallToAction: {
        method: "POST",
        url: "admin/events/${eventId}/client-panel-settings/home-call-to-action/",
      },
      deleteClientPanelHomeCallToAction: {
        method: "DELETE",
        url: "admin/events/${eventId}/client-panel-settings/home-call-to-action/${callToActionId}",
      },
      updateClientPanelHomeCallToActionsOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/home-call-to-action/",
      },

      createClientPanelHomeSponsor: {
        method: "POST",
        url: "admin/events/${eventId}/client-panel-settings/home-sponsors/",
      },
      deleteClientPanelHomeSponsor: {
        method: "DELETE",
        url: "admin/events/${eventId}/client-panel-settings/home-sponsors/${sponsorId}",
      },
      updateClientPanelHomeSponsorsOrder: {
        method: "PUT",
        url: "admin/events/${eventId}/client-panel-settings/home-sponsors/",
      },

      getClientPanelSettings: {
        method: "GET",
        url: "admin/events/${eventId}/client-panel-settings/",
      },
      create: {
        method: "POST",
        url: "admin/events",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}",
      },
      statistics: {
        method: "GET",
        url: "admin/events/${eventId}/statistics",
      },
      totalScans: {
        method: "GET",
        url: "admin/events/${eventId}/statistics/scans/",
      },
      duplicate: {
        method: "POST",
        url: "admin/events/${eventId}/duplicate",
      },
    },
    eventHooks: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/hooks",
      },
      get: {
        method: "GET",
        url: "admin/events/${eventId}/hooks/${hookId}",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/hooks/${hookId}",
      },
    },
    hookEmails: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/hooks/${hookId}/emails",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/hooks/${hookId}/emails",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/hooks/${hookId}/emails/${emailId}",
      },
      get: {
        method: "GET",
        url: "admin/events/${eventId}/hooks/${hookId}/emails/${emailId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/hooks/${hookId}/emails/${emailId}",
      },
    },
    exhibitionDigitalSettings: {
      editSlots: {
        method: "PUT",
        url: "admin/events/${eventId}/exhibition-digital-slots/",
      },
      editCategories: {
        method: "PUT",
        url: "admin/events/${eventId}/exhibition-digital-categories/",
      },
      listCategories: {
        method: "GET",
        url: "admin/events/${eventId}/exhibition-digital-categories/",
      },
      listSlots: {
        method: "GET",
        url: "admin/events/${eventId}/exhibition-digital-slots/",
      },
    },
    exhibitors: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/exhibitors/",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/exhibitors/",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/exhibitors/${exhibitorId}/",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/exhibitors/${exhibitorId}/",
      },
      getPage: {
        method: "GET",
        url: "admin/events/${eventId}/exhibitors/${exhibitorId}/page/",
      },
      updatePage: {
        method: "PUT",
        url: "admin/events/${eventId}/exhibitors/${exhibitorId}/page/",
      },
      updateBucket: {
        method: "POST",
        url: "admin/events/${eventId}/exhibitors/${exhibitorId}/bucket/",
      },
    },
    presetEmails: {
      list: {
        method: "GET",
        url: "admin/organisations/${orgId}/preset-emails/",
      },
      delete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/preset-emails/${presetEmailId}",
      },
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/preset-emails/",
      },
      update: {
        method: "PUT",
        url: "admin/organisations/${orgId}/preset-emails/${presetEmailId}",
      },
      send: {
        method: "POST",
        url: "admin/organisations/${orgId}/preset-emails/${presetEmailId}/send",
      },
      contacts: {
        method: "POST",
        url: "admin/events/${eventId}/contacts-labels",
      },
      customContacts: {
        method: "POST",
        url: "admin/events/${eventId}/contacts-labels",
      },
      custom: {
        method: "POST",
        url: "admin/events/${eventId}/custom-emails/send",
      },
      scheduling: {
        method: "GET",
        url: "admin/organisations/${orgId}/preset-emails/${presetEmailId}/scheduling",
      },
      updateScheduling: {
        method: "POST",
        url: "admin/organisations/${orgId}/preset-emails/${presetEmailId}/update-scheduling",
      },
    },
    sentEmails: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/sent-emails",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/sent-emails/${emailId}",
      },
    },
    accesspolicies: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/policies",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/policies",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/policies/${policyId}",
      },
      get: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/policies/${policyId}",
      },
    },
    policyGroups: {
      create: {
        method: "POST",
        url: "admin/events/${eventId}/policies/${policyId}/groups",
      },
      list: {
        method: "GET",
        url: "admin/events/${eventId}/policies/groups",
      },
      policyList: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/groups",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/policies/${policyId}/groups/${groupId}",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/policies/${policyId}/groups/${groupId}",
      },
    },
    users: {
      getUserCertificate: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/users/${userId}/certificate",
      },
      getUserCertificates: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/certificates",
      },
      getTestCertificate: {
        method: "GET",
        url: "admin/events/${eventId}/certificate/testprint",
      },
      getUserBadge: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/users/${userId}/badge/",
      },
      getUserBadges: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/badges",
      },
      getTestBadge: {
        method: "GET",
        url: "admin/events/${eventId}/badge/testprint",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/policies/${policyId}/users/",
      },
      advancedList: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/users/",
      },
      allUsersList: {
        method: "GET",
        url: "admin/events/${eventId}/policies/users/",
      },
      searchUsersList: {
        method: "GET",
        url: "admin/events/${eventId}/policies/users/",
      },
      list: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/users/",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/policies/${policyId}/users/${userId}",
      },
      refreshMinutes: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/users/",
      },
      bulkImport: {
        method: "POST",
        url: "admin/events/${eventId}/policies/${policyId}/users/import",
      },
      get: {
        method: "GET",
        url: "admin/events/${eventId}/policies/users/${userId}",
      },
      minutescanning: {
        method: "GET",
        url: "admin/events/${eventId}/${section}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/policies/${policyId}/users/${userId}",
      },
      export: {
        method: "POST",
        url: "admin/events/${eventId}/policies/${policyId}/users/export",
      },
      getHits: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/users/${userId}/events",
      },
      editScan: {
        method: "PUT",
        url: "admin/events/${eventId}/policies/${policyId}/users/${userId}/events/${scanId}",
      },
      deleteScan: {
        method: "DELETE",
        url: "admin/events/${eventId}/policies/${policyId}/users/${userId}/events/${scanId}",
      },
    },
    rooms: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/rooms/",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/rooms/${roomId}/",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/rooms/",
      },
      allOut: {
        method: "POST",
        url: "admin/events/${eventId}/rooms/${roomId}/allout",
      },
      get: {
        method: "GET",
        url: "admin/events/${eventId}/rooms/${roomId}/",
      },
      scan: {
        method: "POST",
        url: "admin/events/${eventId}/rooms/${roomId}/scan",
      },
      uploadScanFile: {
        method: "POST",
        url: "admin/events/${eventId}/rooms/import",
      },
      massive: {
        method: "GET",
        url: "admin/events/${eventId}/rooms/allout",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/rooms/${roomId}",
      },
      undoAllOut: {
        method: "DELETE",
        url: "admin/events/${eventId}/rooms/${roomId}/allout/${allOutId}",
      },
    },
    sessionUsers: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/sessions/${sessionId}/users",
      },
      add: {
        method: "POST",
        url: "admin/events/${eventId}/sessions/${sessionId}/users/",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/sessions/${sessionId}/users/${userId}",
      },
    },
    speakers: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/speakers-chairpersons",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/speakers-chairpersons/${speakerId}",
      },
    },
    bankAccounts: {
      getBankAccounts: {
        method: "GET",
        url: "admin/organisations/${orgId}/bank-accounts",
      },
      deleteBankAccount: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/bank-accounts/${bankAccountId}",
      },
      updateBankAccount: {
        method: "PUT",
        url: "admin/organisations/${orgId}/bank-accounts/${bankAccountId}",
      },
      createBankAccount: {
        method: "POST",
        url: "admin/organisations/${orgId}/bank-accounts/",
      },
    },
    sessions: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/sessions",
      },
      getSessionTypes: {
        method: "GET",
        url: "admin/organisations/${orgId}/sessions-types",
      },
      createSessionType: {
        method: "POST",
        url: "admin/organisations/${orgId}/sessions-types",
      },
      updateSessionType: {
        method: "PUT",
        url: "admin/organisations/${orgId}/sessions-types/${sessionTypeId}",
      },
      deleteSessionType: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/sessions-types/${sessionTypeId}",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/sessions",
      },
      import: {
        method: "POST",
        url: "admin/events/${eventId}/sessions/import",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/sessions/${sessionId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/sessions/${sessionId}",
      },
    },
    datasets: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/datasets",
      },
      listEntries: {
        method: "GET",
        url: "admin/events/${eventId}/datasets/${datasetId}/data",
      },
      updateDatasetEntries: {
        method: "PUT",
        url: "admin/events/${eventId}/datasets/${datasetId}",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/datasets/${datasetId}/data",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/datasets/${datasetId}/data/${entryId}",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/datasets/${datasetId}/data/${entryId}",
      },
    },
    products: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/products",
      },
      pricesList: {
        method: "GET",
        url: "admin/events/${eventId}/products/${productId}/prices",
      },
      priceCreate: {
        method: "POST",
        url: "admin/events/${eventId}/products/${productId}/prices/",
      },
      priceDelete: {
        method: "DELETE",
        url: "admin/events/${eventId}/products/${productId}/prices/${priceId}",
      },
      priceEdit: {
        method: "PUT",
        url: "admin/events/${eventId}/products/${productId}/prices/${priceId}",
      },
      dataList: {
        method: "GET",
        url: "admin/events/${eventId}/products/${productId}/data",
      },
      dataCreate: {
        method: "POST",
        url: "admin/events/${eventId}/products/${productId}/data/",
      },
      dataDelete: {
        method: "DELETE",
        url: "admin/events/${eventId}/products/${productId}/data/${dataId}",
      },
      dataEdit: {
        method: "PUT",
        url: "admin/events/${eventId}/products/${productId}/data/${dataId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/products/${productId}",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/products",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/products/${productId}",
      },
    },
    rentables: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/rentables",
      },
      dataList: {
        method: "GET",
        url: "admin/events/${eventId}/rentables/${rentableId}/data",
      },
      dataCreate: {
        method: "POST",
        url: "admin/events/${eventId}/rentables/${rentableId}/data/",
      },
      dataDelete: {
        method: "DELETE",
        url: "admin/events/${eventId}/rentables/${rentableId}/data/${itemId}",
      },
      dataEdit: {
        method: "PUT",
        url: "admin/events/${eventId}/rentables/${rentableId}/data/${itemId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/rentables/${rentableId}",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/rentables",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/rentables/${rentableId}",
      },
    },
    speeches: {
      create: {
        method: "POST",
        url: "admin/events/${eventId}/sessions/${sessionId}/speeches",
      },
      edit: {
        method: "PUT",
        url: "admin/events/${eventId}/sessions/${sessionId}/speeches/${speechId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/sessions/${sessionId}/speeches/${speechId}",
      },
    },
    persons: {
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/persons",
      },

      edit: {
        method: "PUT",
        url: "admin/organisations/${orgId}/persons/${personId}",
      },
      list: {
        method: "GET",
        url: "admin/organisations/${orgId}/persons",
      },
      delete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/persons/${personId}",
      },
    },
    crm: {
      list: {
        method: "GET",
        url: "admin/organisations/${orgId}/crm-users",
      },
      edit: {
        method: "PUT",
        url: "admin/organisations/${orgId}/crm-users/${userId}",
      },
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/crm-users",
      },
      delete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/crm-users/${userId}",
      },
      export: {
        method: "POST",
        url: "admin/organisations/${orgId}/crm-users/export",
      },
    },
    schemas: {
      list: {
        method: "GET",
        url: "admin/organisations/${orgId}/crm-schemas",
      },
      orglist: {
        method: "GET",
        url: "admin/organisations/${orgId}/crm-schemas/${schemaId}",
      },
      update: {
        method: "PUT",
        url: "admin/organisations/${orgId}/crm-schemas/${schemaId}",
      },
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/crm-schemas/",
      },
    },
    staff: {
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/staff",
      },
      edit: {
        method: "PUT",
        url: "admin/organisations/${orgId}/staff/${personId}",
      },
      list: {
        method: "GET",
        url: "admin/organisations/${orgId}/staff",
      },
      delete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/staff/${personId}",
      },
      get: {
        method: "GET",
        url: "admin/organisations/${orgId}/staff/${personId}",
      },
    },
    roles: {
      list: {
        method: "GET",
        url: "admin/organisations/${orgId}/staff/${personId}/roles",
      },
      types: {
        method: "GET",
        url: "admin/organisations/roletypes",
      },
      edit: {
        method: "PUT",
        url: "admin/organisations/${orgId}/staff/${personId}/roles/${roleId}",
      },
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/staff/${personId}/roles",
      },
      delete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/staff/${personId}/roles/${roleId}",
      },
    },
    questionnaire: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/questionnaires/",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/questionnaires/",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/questionnaires/${itemId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/questionnaires/${itemId}",
      },
      customContacts: {
        method: "POST",
        url: "admin/events/${eventId}/contacts-labels",
      },
      send: {
        method: "POST",
        url: "admin/events/${eventId}/questionnaires/${itemId}/send",
      },
    },
    subscriptions: {
      listPricingFields: {
        method: "GET",
        url: "admin/events/${eventId}/policies/${policyId}/pricing-fields",
      },
      calculatePricingGroups: {
        method: "POST",
        url: "admin/events/${eventId}/calculate-pricing-groups",
      },
      list: {
        method: "GET",
        url: "admin/events/${eventId}/subscriptions",
      },
      types: {
        method: "GET",
        url: "admin/events/${eventId}/policies/groups",
      },
      periods: {
        method: "GET",
        url: "admin/events/${eventId}/subscriptions/periods",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/subscriptions",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/subscriptions/${subscriptionId}",
      },
      upsert: {
        method: "PUT",
        url: "admin/events/${eventId}/subscriptions/",
      },
      createPeriod: {
        method: "POST",
        url: "admin/events/${eventId}/subscriptions/periods",
      },

      deletePeriod: {
        method: "DELETE",
        url: "admin/events/${eventId}/subscriptions/periods/${id}",
      },

      updatePeriod: {
        method: "PUT",
        url: "admin/events/${eventId}/subscriptions/periods/${id}",
      },

      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/subscriptions/${subscriptionId}",
      },
    },
    reservations: {
      listReservations: {
        method: "GET",
        url: "admin/events/${eventId}/hotels/reservations",
      },
      listHotelReservations: {
        method: "GET",
        url: "admin/events/${eventId}/hotels/${hotelId}/reservations",
      },
      createReservation: {
        method: "POST",
        url: "admin/events/${eventId}/hotels/${hotelId}/reservations",
      },
      updateReservation: {
        method: "PUT",
        url: "admin/events/${eventId}/hotels/${hotelId}/reservations/${reservationId}",
      },
      deleteReservation: {
        method: "DELETE",
        url: "admin/events/${eventId}/hotels/${hotelId}/reservations/${reservationId}",
      },
    },
    hotels: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/hotels",
      },

      orgList: {
        method: "GET",
        url: "admin/organisations/${orgId}/hotels",
      },
      singleOrgHotel: {
        method: "GET",
        url: "admin/organisations/${orgId}/hotels/${hotelId}",
      },
      edit: {
        method: "PUT",
        url: "admin/organisations/${orgId}/hotels/${hotelId}",
      },
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/hotels",
      },
      delete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/hotels/${hotelId}",
      },
      assignHotel: {
        method: "POST",
        url: "admin/events/${eventId}/hotels",
      },
      unassignHotel: {
        method: "DELETE",
        url: "admin/events/${eventId}/hotels/${hotelId}",
      },
      reorder: {
        method: "PUT",
        url: "admin/events/${eventId}/hotels/${hotelId}",
      },
      updateRoom: {
        method: "PUT",
        url: "admin/organisations/${orgId}/hotels/${hotelId}/rooms/${roomId}",
      },
      createRoom: {
        method: "POST",
        url: "admin/organisations/${orgId}/hotels/${hotelId}/rooms",
      },
      deleteRoom: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/hotels/${hotelId}/rooms/${roomId}",
      },
      assignRoom: {
        method: "POST",
        url: "admin/events/${eventId}/hotels/${hotelId}/rooms",
      },
      unassignRoom: {
        method: "DELETE",
        url: "admin/events/${eventId}/hotels/${hotelId}/rooms/${roomId}",
      },
      updateEventRoom: {
        method: "PUT",
        url: "admin/events/${eventId}/hotels/${hotelId}/rooms/${roomId}",
      },
      orgRooms: {
        method: "GET",
        url: "admin/organisations/${orgId}/hotels/${hotelId}/rooms",
      },
      eventRooms: {
        method: "GET",
        url: "admin/events/${eventId}/hotels/${hotelId}/rooms",
      },
      export: {
        method: "POST",
        url: "admin/events/${eventId}/hotels/reservations/export/",
      },
    },
    payments: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/payments",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/payments/${paymentId}/",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/payments",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/payments/${paymentId}/",
      },
    },
    abstracts: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/abstracts",
      },
      statistics: {
        method: "GET",
        url: "admin/events/${eventId}/abstracts-statistics",
      },
      view: {
        method: "GET",
        url: "admin/events/${eventId}/abstracts",
      },
      get: {
        method: "GET",
        url: "admin/events/${eventId}/abstracts/${abstractId}",
      },
      schemas: {
        method: "GET",
        url: "admin/events/abstracts-schemas",
      },
      createEventSchema: {
        method: "POST",
        url: "admin/events/${eventId}/abstracts-schemas",
      },
      updateEventSchema: {
        method: "PUT",
        url: "admin/events/${eventId}/abstracts-schemas/${schemaEventId}",
      },
      getEventSchema: {
        method: "GET",
        url: "admin/events/${eventId}/abstracts-schemas",
      },
      getSchema: {
        method: "GET",
        url: "admin/events/${eventId}/abstracts-schemas/${schemaId}",
      },
      createSchema: {
        method: "POST",
        url: "admin/events/${eventId}/abstracts-schemas",
      },
      updateSchema: {
        method: "PUT",
        url: "admin/events/${eventId}/abstracts-schemas/${schemaId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/abstracts/${abstractId}",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/abstracts/${abstractId}",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/abstracts/",
      },
      updateMultiple: {
        method: "PUT",
        url: "admin/events/${eventId}/abstracts",
      },
      getJudges: {
        method: "GET",
        url: "admin/events/${eventId}/abstracts/judges",
      },
      unassignJudges: {
        method: "POST",
        url: "admin/events/${eventId}/abstracts/unassign",
      },
      assignJudges: {
        method: "POST",
        url: "admin/events/${eventId}/abstracts/assign",
      },
      publish: {
        method: "PUT",
        url: "admin/events/${eventId}/abstracts/publish",
      },
    },
    topics: {
      getOrgTopics: {
        method: "GET",
        url: "admin/organisations/${orgId}/abstracts-topics",
      },
      createOrgTopic: {
        method: "POST",
        url: "admin/organisations/${orgId}/abstracts-topics",
      },
    },
    types: {
      getOrgTypes: {
        method: "GET",
        url: "admin/organisations/${orgId}/abstracts-types",
      },
      createOrgType: {
        method: "POST",
        url: "admin/organisations/${orgId}/abstracts-types",
      },
    },
    judge: {
      list: {
        method: "GET",
        url: "judge/events/abstracts",
      },
      get: {
        method: "GET",
        url: "judge/events/${eventId}/abstracts/${abstractId}",
      },
      rate: {
        method: "PUT",
        url: "judge/events/${eventId}/abstracts/${abstractId}",
      },
      comment: {
        method: "POST",
        url: "judge/events/${eventId}/abstracts/${abstractId}/comments",
      },
    },
    invoices: {
      list: {
        method: "GET",
        url: "admin/events/${eventId}/invoices",
      },
      create: {
        method: "POST",
        url: "admin/events/${eventId}/invoices",
      },
      update: {
        method: "PUT",
        url: "admin/events/${eventId}/invoices/${invoiceId}",
      },
      delete: {
        method: "DELETE",
        url: "admin/events/${eventId}/invoices/${invoiceId}",
      },
      export: {
        method: "POST",
        url: "admin/events/${eventId}/invoices/export",
      },
    },
    sponsors: {
      singleSponsor: {
        method: "GET",
        url: "admin/organisations/${orgId}/sponsors/${sponsorId}",
      },
      list: {
        method: "GET",
        url: "admin/organisations/${orgId}/sponsors",
      },
      sponsorship: {
        method: "POST",
        url: "admin/events/${eventId}/sponsorships",
      },
      sponsorshipStatus: {
        method: "GET",
        url: "admin/events/${eventId}/sponsorships",
      },
      export: {
        method: "POST",
        url: "admin/events/${eventId}/sponsorships/export",
      },
      deleteSponsorshipStatus: {
        method: "DELETE",
        url: "admin/events/${eventId}/sponsorships/${sponsorshipId}",
      },
      updateSponsorshipStatus: {
        method: "PUT",
        url: "admin/events/${eventId}/sponsorships/${sponsorshipId}",
      },
      create: {
        method: "POST",
        url: "admin/organisations/${orgId}/sponsors",
      },
      delete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/sponsors/${sponsorId}",
      },
      update: {
        method: "PUT",
        url: "admin/organisations/${orgId}/sponsors/${sponsorId}",
      },
      personsList: {
        method: "GET",
        url: "admin/organisations/${orgId}/sponsors/${sponsorId}/persons",
      },
      personsDelete: {
        method: "DELETE",
        url: "admin/organisations/${orgId}/sponsors/${sponsorId}/persons/${personId}",
      },
      personsCreate: {
        method: "POST",
        url: "admin/organisations/${orgId}/sponsors/${sponsorId}/persons",
      },
      personsUpdate: {
        method: "PUT",
        url: "admin/organisations/${orgId}/sponsors/${sponsorId}/persons/${personId}",
      },
    },
  },
};

export const pages = {
  baseTitle: "BlueTree Admin",
  routes: {
    "/events": {
      title: "List Events",
    },
    "/events/:id/basic-setup": {
      title: "Edit Event",
    },
    "/events/:id/:pid/users": {
      title: "Edit Event",
    },
    "/events/:id/statistics": {
      title: "Edit Event",
    },
    "/events/:id/:rid/scanning": {
      title: "Room Scanning",
    },
    "/events/:id/minutescanning": {
      title: "Minutes Scanning",
    },
    "/events/:id/abstract/:aid": {
      title: "Edit Event",
    },
    "/staff": {
      title: "List Staff",
    },
    "/persons": {
      title: "List Persons",
    },
    "/crm": {
      title: "List Crm Users",
    },
    "/sponsors": {
      title: "List Sponsors",
    },
    "/staff/:id/roles": {
      title: "Assign Roles",
    },
    "/myaccount": {
      title: "My Account",
    },
    "/accomodation": {
      title: "Accommodation",
    },
    "/session-types": {
      title: "Session Types",
    },
    "/bank-accounts": {
      title: "Bank Accounts",
    },
    "/abstracts": {
      title: "Abstracts",
    },
    "/abstracts/:id/:aid": {
      title: "Abstract",
    },
  },
};
