import React from "react";
import SelectCheckboxes from "@layout/SelectCheckboxes";
import Select2 from "@layout/Select2";
import PropTypes from "prop-types";
import { loadUsers } from "@actions/users";
import { connect } from "react-redux";
import { getSponsorshipStatus } from "@actions/sponsors";
import SearchAndSelect from "@layout/SearchAndSelect";

class FiltersSponsorships extends React.Component {
  constructor(props) {
    super(props);
    this.resetRange = this.resetRange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.prevQuery = "";
    this.state = {
      closeAllSelects: false,
      reset: false,
      resetRange: false,
      closeRange: false,
      filters: this.initialFilters,
      userName: "",
      userId: "",
      sponsorName: null,
    };
  }
  setFilter(filter, value) {
    this.setState(
      {
        filters: Object.assign({}, this.state.filters, {
          [filter]: value,
        }),
      },
      () => {
        const request = {};
        for (const [i, f] of Object.entries(this.state.filters)) {
          if (f !== false) {
            request[i] = f;
          }
        }
        this.props.sponsorshipsStatus(this.props.eventId, request);
      }
    );
  }

  resetRange() {
    this.setState(
      {
        resetRange: true,
        closeRange: true,
        filters: Object.assign({}, this.state.filters, {
          dateRange: false,
        }),
      },
      () => {
        this.setState({ resetRange: false, closeRange: false });
        const request = {};
        for (const [i, f] of Object.entries(this.state.filters)) {
          if (f !== false) {
            request[i] = f;
          }
        }
        this.props.sponsorshipsStatus(this.props.eventId, request);
      }
    );
  }
  changeSponsor(val) {
    let id;
    this.setState({ sponsorName: val || "" });
    this.props.sponsorList.map((sponsor) => {
      if (sponsor.name === val) {
        id = sponsor.id;
      }
    });
    if (val === "Select") {
      id = "";
    }
    this.setFilter("orgSponsorId", id || "");
  }
  debouncedCall(query) {
    if (query === "") {
      this.setFilter("eventUserId", "");
    }
    this.setState(
      {
        userName: query,
        userId: "",
      },
      () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.props.searchUsers(query, undefined, this.props.eventId);
        }, 400);
      }
    );
  }
  onSelectChange(val) {
    this.setState(
      {
        userId: val,
      },
      () => {
        this.setFilter("eventUserId", this.state.userId);
      }
    );
  }
  render() {
    const sponsors = {};
    sponsors["Select"] = "Select";
    this.props.sponsorList.map((sponsor) => {
      sponsors[sponsor.name] = sponsor.name;
    });
    return (
      <>
        <SelectCheckboxes
          options={{
            accomodation: {
              label: "Accomodation",
              checked: false,
            },
            registration: {
              label: "Registration",
              checked: false,
            },
            product: {
              label: "Product",
              checked: false,
            },
          }}
          placeholder={"Select Type"}
          onChange={(type) => this.setFilter("type", type)}
          selected={this.state.filters?.type}
        />
        <Select2
          border={"none"}
          options={sponsors}
          onChange={(val) => {
            this.changeSponsor(val);
          }}
          placeholder={
            this.state.sponsorName ? this.state.sponsorName : "Select Sponsor"
          }
          value={this.state.sponsorName}
          width={"150px"}
        />
        <SearchAndSelect
          placeholder={"Search participant..."}
          onSelectChange={this.onSelectChange}
          selectValue={this.state.userName}
          onSearch={(query) => {
            if (!query) this.setFilter("eventUserId", "");
            if (
              this.state.filters?.eventUserId?.value &&
              this.state.filters?.eventUserId?.value !== ""
            )
              this.setFilter("eventUserId", "");
            return this.props.searchUsers(query, null, this.props.eventId);
          }}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    schema: state.api.abstracts.currentSchema.data,
    eventAbstractSchemaId: state.api.events.edit.data.eventAbstractSchemaId,
    eventId: state.api.events.edit.data.id,
    allPolicyUsers: state.users.allPolicyUsers.data,
    policyId: state.page.params.pid,
    doingAjax: state.api.payments.meta.statisticData.doingAjax,
    sponsorList: state.api.sponsors.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (queryString, policyId, eventId) => {
      return dispatch(
        loadUsers(
          eventId,
          policyId,
          { search: queryString },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
    sponsorshipsStatus: (eventId, meta) => {
      dispatch(getSponsorshipStatus(eventId, meta));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersSponsorships);

FiltersSponsorships.propTypes = {
  searchUsers: PropTypes.func,
  schema: PropTypes.object,
  eventAbstractSchemaId: PropTypes.number,
  eventId: PropTypes.number,
  allPolicyUsers: PropTypes.array,
  policyId: PropTypes.number,
  sponsorshipsStatus: PropTypes.func,
  sponsorList: PropTypes.array,
};
