import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import Confirm from "@layout/Confirm";

const Statistics = (props) => {
  const [fetching, setFetching] = useState(true);
  const [statistics, setStatistics] = useState({});
  useEffect(() => {
    props.getStatistics().then((data) => {
      setFetching(false);
      setStatistics(data.data[0]);
    });
  }, []);

  return (
    <div style={{ paddingBottom: 32 }}>
      <Table
        loadingRowsHeight={45}
        loadingRows={1}
        updating={fetching}
        items={[statistics]}
        columns={{
          totalAbstracts: {
            name: "Total Abstracts",
          },
          totalPassingByAdmin: {
            name: "Approved (Final)",
          },
          totalNotPassingByAdmin: {
            name: "Rejected (Final)",
          },
          remainingAbstracts: {
            name: "Remaining (Final)",
          },
          totalPassingByJudge: {
            name: "Approved (Judges)",
          },
          totalNotPassingByJudge: {
            name: "Rejected (Judges)",
          },
        }}
      />
      <div className="finalize">
        {statistics.totalAbstracts > 0 &&
          statistics.remainingAbstracts === 0 &&
          props.schema.finalize === 0 && (
            <>
              <Confirm
                onConfirm={() =>
                  props.createSchema({ finalize: true }, true, props.schema.id)
                }
                body={
                  <span>
                    You have rated all the submitted abstracts. This
                    &apos;Finalize Ratings&apos; action will finalize the
                    abstract ratings, inform the submitters by email (if the
                    relevant hook is set) and notification and is irreversible.
                    Are you sure?
                  </span>
                }
                confirmText="Yes, Finalize the procedure!"
                title="Attention"
              >
                <button className="btn btn-green">Finalize Ratings</button>
              </Confirm>

              <p className="alert alert-danger">
                You have rated all the submitted abstracts. This &apos;Finalize
                Ratings&apos; action will finalize the abstract ratings, inform
                the submitters by email and notification and is irreversible.
              </p>
            </>
          )}
        {props.schema.finalize === 1 && (
          <div className="alert alert-info" style={{ marginTop: "15px" }}>
            The process has been finalized! No more actions can be applied to
            the abstracts.
          </div>
        )}
      </div>
    </div>
  );
};

Statistics.propTypes = {
  getStatistics: PropTypes.func,
  createSchema: PropTypes.func,
  schema: PropTypes.object,
  abstracts: PropTypes.array,
};

export default Statistics;
