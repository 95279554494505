import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import moment from "moment";
import { connect } from "react-redux";

import { showModal, addNotification } from "@actions";
import { getReservations, deleteReservation } from "@actions/reservations";
import { changeTab } from "@actions/editmenu";
import KMBLoader from "@layout/KMBLoader";
import ReservationModal from "./ReservationModal";
import SinglePayment from "./SinglePayment";
import Search from "@layout/Search";

class Reservations extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.state = {
      search: "",
    };
  }
  onRender(col, item) {
    switch (col.key) {
      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() =>
              this.props.showModal(<ReservationModal item={item} />)
            }
            onDelete={() =>
              this.props.deleteReservation(
                this.props.eventId,
                item.eventHotelId,
                item.id
              )
            }
          />
        );
      }
      case "startDate": {
        return moment(col.value.tz).format("DD-MM-YYYY");
      }
      case "endDate": {
        return moment(col.value.tz).format("DD-MM-YYYY");
      }
      case "dueAmount":
      case "price": {
        return col.value.toFixed(2);
      }
      case "subscription": {
        let color = "";
        let className = "icon-notpaid";
        let info = "Not Paid";
        let title = "";
        const colorGreen = "#14da9e";
        const colorBlue = "#3C96FF";
        const colorRed = "#FF8100";
        const hasPayment = item.payments?.amount;

        const hasSponsorPayment = item.sponsorships?.amount;
        if (item.price === 0 && item.dueAmount === 0) {
          // free
          color = item.sponsorships?.id ? colorBlue : colorGreen;
          info = "Free";
          title = "Free (no subscription fees)";
          className = "icon-paid";
        } else if (item.dueAmount > 0) {
          // has due amount
          if (hasSponsorPayment) {
            // partially sponsored
            color = colorBlue;
            info = "Partial";
            className = "icon-partial";
            title = "Partialy paid by Sponsor";
          } else if (hasPayment) {
            color = colorGreen;
            info = "Partial";
            className = "icon-partial";
            title = "Partialy paid by the Participant";
          } else {
            color = item.sponsorships?.id ? colorBlue : colorRed;
            info = "Not Paid";
            className = "icon-notpaid";
            title = "Not Paid";
          }
        } else if (item.dueAmount < 0) {
          // needs refund
          className = "icon-refund";
          if (hasSponsorPayment) {
            color = colorBlue;
            info = "Refund";
            title = "Refund the Sponsor";
          } else {
            color = colorGreen;
            info = "Refund";
            title = "Refund the Participant";
          }
        } else {
          // paid
          if (hasSponsorPayment) {
            /* paid by sponsor*/
            className = "icon-paid";
            color = colorBlue;
            info = "Paid";
            title = "Paid by the Sponsor";
          } else {
            /* paid by participant*/
            className = "icon-paid";
            color = colorGreen;
            info = "Paid";
            title = "Paid by the Participant";
          }
        }

        return (
          <button
            title={title}
            style={{ minWidth: 129 }}
            className="btn transparent table-button-link"
            onClick={() => {
              this.props.showModal(<SinglePayment item={item} />);
            }}
          >
            <span className={className} style={{ color, fontSize: 13 }}></span>
            {info}
          </button>
        );
      }
    }
  }
  componentDidMount() {
    this.props.getReservations(this.props.eventId);
  }

  render() {
    const columns = {
      id: {
        name: "ID",
      },
      eventUserId: {
        name: "Participant ID",
      },
      eventUserFullName: {
        name: "Participant Name",
        className: ".kmb-eventUserFullName",
      },
      orgHotelRoomName: {
        name: "Room Name",
      },
      orgHotelName: {
        name: "Hotel Name",
      },
      reservedBy: {
        name: "Reserved By",
      },
      startDate: {
        name: "Start Date",
      },
      endDate: {
        name: "End Date",
      },
      price: {
        name: "Price",
      },
      dueAmount: {
        name: "Due Amount",
      },
      notes: {
        name: "Notes",
      },
      adminNotes: {
        name: "Admin Notes",
      },
      subscription: {
        name: "Payment Status",
      },
      actions: {
        name: "",
      },
    };

    return (
      <>
        <div className="tab-header">
          <div className="left">
            <Search
              placeholder="Search participant..."
              onSearch={(search) => {
                this.setState({ search });
                this.props.getReservations(this.props.eventId, { search });
              }}
              visible={true}
              timeout={400}
            />
          </div>

          <div className="right">
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(<ReservationModal {...this.props} />)
              }
            >
              Create
            </button>
          </div>
        </div>
        <div className="tab-content">
          {!this.props.reservationsReady ? (
            <KMBLoader rows={15} padding={24} height={53} />
          ) : (
            <Table
              containerClassName={`table-container `}
              id={"subscriptions"}
              columns={columns}
              filtered={this.state.search}
              items={this.props.reservations}
              onRender={(col, item) =>
                this.onRender(col, item, "subscriptions")
              }
            />
          )}
        </div>
      </>
    );
  }
}

Reservations.propTypes = {
  eventId: PropTypes.number,
  getReservations: PropTypes.func.isRequired,
  reservations: PropTypes.array.isRequired,
  reservationsReady: PropTypes.bool.isRequired,
  deleteReservation: PropTypes.func.isRequired,
  showModal: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    reservations: state.api.reservations.data,
    reservationsReady: !state.api.reservations.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    changeTab: (tab) => {
      return dispatch(changeTab(tab));
    },
    getReservations: (eventId, meta) => {
      dispatch(getReservations(eventId, meta));
    },
    deleteReservation: (eventId, hotelId, reservationId) => {
      return dispatch(deleteReservation(eventId, hotelId, reservationId))
        .then(() => dispatch(getReservations(eventId)))
        .then(() =>
          dispatch(
            addNotification("Reservation deleted successfully", "success")
          )
        );
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
