import React from "react";
import PropTypes from "prop-types";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from "react-sortable-hoc";

const DragHandle = SortableHandle(() => <span className="fa fa-sort"></span>);

const SortableItem = SortableElement((props) => {
  return props.html;
});

const getJSX = (items, data, selections, handleCheck, keyName) => {
  return (
    <div>
      {items.map((obj, index) => {
        const staticIndex = data.static.indexOf(obj.key);
        let cn = `checkbox-wrapper kmb-checkbox${
          obj.containerClassName ? ` ${obj.containerClassName}` : ""
        }`;
        if (data.sortable) cn += " sortable";

        const html = (
          <div
            style={{ zIndex: 100 }}
            className={cn}
            key={`${data.id}-${obj.key}-${keyName}`}
          >
            <input
              disabled={staticIndex != -1}
              type="checkbox"
              id={`${data.id}-${obj.key}-${keyName}`}
              name={obj.key}
              onChange={handleCheck}
              checked={selections.includes(obj.key)}
            />
            <label htmlFor={`${data.id}-${obj.key}-${keyName}`}>
              {obj.label}
            </label>

            {data.sortable && staticIndex == -1 && <DragHandle />}
          </div>
        );
        return data.sortable && staticIndex == -1 ? (
          <SortableItem
            key={`item-${obj.key}`}
            html={html}
            index={staticIndex != -1 ? staticIndex : index}
          />
        ) : (
          html
        );
      })}
    </div>
  );
};

const SortableList = SortableContainer(
  ({ items, props, selections, handleCheck, keyName }) =>
    getJSX(items, props, selections, handleCheck, keyName)
);

export default class SelectCheckboxes extends React.Component {
  constructor(props) {
    super(props);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.getSelections = this.getSelections.bind(this);
    this.handleBody = this.handleBody.bind(this);
    this.getPrompt = this.getPrompt.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.originalCount = 0;
    this.excludedArea = null;

    const options = [];
    Object.entries(props.options).map(([k, v]) => {
      options.push(
        Object.assign({}, v, {
          key: k,
        })
      );
    });

    this.state = {
      toggled: props.toggled,
      selections: [],
      selectButton: "select",
      options,
    };
  }

  toggleSelect() {
    this.setState((prevState) => {
      return {
        toggled: !prevState.toggled,
      };
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(
      {
        options: arrayMove(this.state.options, oldIndex, newIndex),
      },
      () => this.props.onSortEnd(this.state.options)
    );
  }

  handleBody(e) {
    if (this.excludedArea && !this.excludedArea.contains(e.target)) {
      this.setState({
        toggled: false,
      });
    }
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleBody);
    const selections = this.getSelections(this.props.options);
    let selectButton = "select";
    this.originalCount = Object.keys(this.props.options).length;

    if (Object.keys(this.props.options).length === selections.length) {
      selectButton = "deselect";
    }

    this.setState({
      selections,
      selectButton,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = Object.assign({}, this.state);
    if (Object.keys(nextProps.options).length !== this.originalCount) {
      newState.selectButton = "select";
      this.originalCount = Object.keys(nextProps.options).length;
      newState.selections = this.getSelections(nextProps.options);
      if (
        Object.keys(nextProps.options).length === newState.selections.length
      ) {
        newState.selectButton = "deselect";
      }
      this.setState(newState);
    }

    if (
      Object.keys(nextProps.options).length !==
      Object.keys(this.props.options).length
    ) {
      const options = [];
      Object.entries(nextProps.options).map(([k, v]) => {
        options.push(
          Object.assign({}, v, {
            key: k,
          })
        );
      });
      newState.options = options;
      this.setState(newState);
    }

    if (nextProps.selected.length > 0) {
      this.setState({
        selections: nextProps.selected,
      });
      return;
    }

    if (nextProps.forceClose) {
      newState.toggled = false;
      this.setState(newState);
    }

    if (nextProps.reset) {
      newState.selections = this.getSelections(nextProps.options);
      this.setState(newState);
    }
  }

  getSelections(options) {
    const selections = [];
    Object.entries(options).map(([key, obj]) => {
      if (obj.checked) {
        selections.push(key);
      }
    });
    return selections;
  }

  selectAll(deselect = false) {
    const selections = [...this.props.reset];
    if (!deselect) {
      Object.entries(this.props.options).map(([key]) => {
        selections.push(key);
      });
    }

    this.setState(
      {
        selections,
        selectButton: deselect ? "select" : "deselect",
      },
      () => {
        this.props.onChange(selections);
      }
    );
  }

  handleCheck(e) {
    const { name } = e.target;

    let selections = [...this.state.selections];
    let selectButton = "select";

    if (this.props.single) {
      if (name === selections[0]) {
        selections = [];
      } else {
        selections = [name];
      }
    } else {
      const index = selections.indexOf(name);
      if (index !== -1) {
        selections.splice(index, 1);
      } else {
        selections.push(name);
      }

      if (Object.keys(this.props.options).length === selections.length) {
        selectButton = "deselect";
      }
    }

    if (this.props.roles && selections.length === 0) {
      return;
    }

    this.setState(
      {
        selections,
        selectButton,
      },
      () => {
        this.props.onChange(selections);
      }
    );
  }

  getPrompt() {
    if (!this.props.fixedPrompt && this.state.selections.length) {
      return this.state.selections
        .map((s) => {
          if (this.props.options[s]) {
            return this.props.options[s].label;
          }
        })
        .join(", ");
    } else {
      return <span style={{ display: "flex" }}>{this.props.placeholder}</span>;
    }
  }

  render() {
    const className = `bottom-area${this.state.toggled ? "" : " hidden"}`;

    return (
      <div
        className={`select-advanced${
          this.props.className ? ` ${this.props.className}` : ""
        }`}
        ref={(ref) => (this.excludedArea = ref)}
      >
        <div className="select-checkboxes-outer">
          <div className="upper-area" onClick={this.toggleSelect}>
            <span>{this.getPrompt()}</span>
            <span className="fa fa-angle-down directional"></span>
          </div>
          <div
            className={className}
            style={{ maxHeight: 400, overflow: "auto" }}
          >
            <div className="checkboxes-wrapper" style={{ paddingBottom: 10 }}>
              {this.props.selectAllButton && !this.props.roles && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span onClick={() => this.selectAll()}>Select All</span>
                  <span onClick={() => this.selectAll(true)}>Reset</span>
                </div>
              )}

              {this.props.sortable ? (
                <SortableList
                  items={this.state.options}
                  props={this.props}
                  onSortEnd={this.onSortEnd}
                  lockAxis="y"
                  lockToContainerEdges={true}
                  lockOffset={`70%`}
                  useDragHandle={true}
                  helperClass={this.props.id}
                  selections={this.state.selections}
                  handleCheck={this.handleCheck}
                  keyName={this.props.keyName}
                />
              ) : (
                getJSX(
                  this.state.options,
                  this.props,
                  this.state.selections,
                  this.handleCheck,
                  this.props.keyName
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectCheckboxes.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.any,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  selectAllButton: PropTypes.bool,
  toggled: PropTypes.bool,
  forceClose: PropTypes.bool,
  fixedPrompt: PropTypes.bool,
  className: PropTypes.string,
  reset: PropTypes.array,
  single: PropTypes.bool,
  containerClassName: PropTypes.string,
  roles: PropTypes.bool,
  selected: PropTypes.array,
  sortable: PropTypes.bool,
  onSortEnd: PropTypes.func,
  keyName: PropTypes.string,
  static: PropTypes.array,
};

SelectCheckboxes.defaultProps = {
  placeholder: "Select",
  keyName: "default",
  onChange: () => {},
  selectAllButton: true,
  toggled: false,
  forceClose: false,
  static: [],
  reset: [],
  fixedPrompt: false,
  className: "",
  single: false,
  roles: false,
  selected: [],
  sortable: false,
  onSortEnd: () => {},
};
