/* eslint-disable */
import React from "react";
import Form from "@components/form";
import { isEmpty } from "@helpers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@layout/Select2";
import { getOrgHotels, assignHotel, saveHotel } from "@actions/hotels";
import Confirm from "@layout/Confirm";
import { hideModal, addNotification } from "@actions";
import SubmitContainer from "@layout/SubmitContainer";

class SingleHotel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createMode: "new",
      file: null,
      value: "",
      hotelItem: this.props.value,
      showConfirm: false,
    };
    ["changeMode", "selectHotel"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  changeMode(createMode) {
    if (createMode === "existing") {
      this.props.getOrgHotels();
    }
    this.setState({ createMode, value: "" });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showConfirm: false,
      file: null,
      hotelItem: nextProps.hotels.filter(
        (hotel) => hotel.id === this.props.eventHotelId
      )[0],
    });
  }

  selectHotel(value) {
    this.setState({ value });
  }

  render() {
    const props = this.props;
    const mode = props.hasOwnProperty("value") ? "update" : "create";
    const value =
      mode === "update"
        ? {
            address: {
              hname: this.state.hotelItem.name,
              address: this.state.hotelItem.address,
              lat: this.state.hotelItem.latitude,
              lng: this.state.hotelItem.longitude,
            },
            facilities: this.state.hotelItem.facilities,
            description: this.state.hotelItem.description,
            rating: this.state.hotelItem.stars,
            active: this.state.hotelItem.active,
            hotelImages: this.state.hotelItem.hotelImages,
            notes: this.state.hotelItem.notes || null,
          }
        : {};
    const hotels = {};
    const availableHotels = [...props.orgHotels].filter(
      (h) => props.hotels.find((ht) => ht.id === h.id) === undefined
    );
    availableHotels.map((h) => {
      if (h.active === 1) {
        hotels[h.id.toString()] = h.name;
      }
    });

    const deletefileBody = this.state.showConfirm ? (
      <div>
        <p>
          This action will permanently delete the file with ID:{" "}
          <strong>{this.state.file.id}</strong>
        </p>
        <p>
          <span>{this.state.file.fileName}</span>
        </p>
        <p>Are you sure that you want to do this?</p>
      </div>
    ) : null;

    return (
      <div className="single-hotel form-container">
        <h2>{mode === "create" ? "Create Hotel" : "Edit Hotel"}</h2>
        <Confirm
          onConfirm={() => {
            props.saveHotel(
              props.orgId,
              { hotelImages: [this.state.file.id] },
              props.value.orgHotelId || props.value.id,
              !props.orgMode, //dont assign hotel if in orgMode
              undefined, //endpoint
              false, //formdata
              true, //keep modal open
              props.orgMode,
              null,
              null,
              props.eventId
            );
          }}
          body={deletefileBody}
          className="delete-event"
          confirmText="Confirm File Deletion"
          disabled={false}
          visible={this.state.showConfirm}
          title="File Delete Action! Are you sure?"
        ></Confirm>
        <div className="form-container">
          {mode === "create" && (
            <div className="row boolean-buttons">
              <div className="col-sm-6">
                <button
                  style={{ width: "100%" }}
                  className={`btn${
                    this.state.createMode === "existing" ? " selected" : ""
                  }`}
                  type="button"
                  onClick={() => this.changeMode("existing")}
                >
                  Select from existing
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  style={{ width: "100%" }}
                  className={`btn${
                    this.state.createMode === "new" ? " selected" : ""
                  }`}
                  type="button"
                  onClick={() => this.changeMode("new")}
                >
                  Create New
                </button>
              </div>
            </div>
          )}
          {this.state.createMode === "new" && (
            <Form
              componentName="hotels"
              formName={"single"}
              value={value}
              onSubmit={(data) => {
                if (!isEmpty(data.errors)) {
                  this.props.addNotification(
                    "Please fill in all required fields",
                    "error"
                  );
                  return;
                }

                let formData = false;
                const value = data.value;
                const req = {
                  name: value.address.hname,
                  address: value.address.address,
                  stars: value.rating,
                  description: value.description,
                  facilities: value.facilities,
                  active: value.active,
                  longitude: value.address.lng,
                  latitude: value.address.lat,
                };

                if (value.notes) {
                  req["notes"] = value.notes;
                }

                if (value.hotelImages && value.hotelImages.length > 0) {
                  req["hotelImages"] = value.hotelImages;
                  formData = true;
                }

                props.saveHotel(
                  this.props.orgId,
                  req,
                  mode === "update"
                    ? props.value.orgHotelId || props.value.id
                    : false,
                  this.props.mode !== "edit",
                  undefined,
                  formData,
                  false,
                  this.props.orgMode,
                  mode === "update",
                  props.eventHotelId,
                  props.eventId
                );
              }}
              onCancel={() => this.props.hideModal()}
              onChange={(data) => {
                if (data.key === "hotelImages") {
                  this.setState({
                    showConfirm: true,
                    file: data.value,
                  });
                }
              }}
            />
          )}
          {this.state.createMode === "existing" && mode === "create" && (
            <div className="form-container">
              <div className="form-group">
                <Select
                  isClearable={false}
                  options={hotels}
                  onChange={(val) => this.selectHotel(val)}
                  value={this.state.value}
                />
              </div>
              <SubmitContainer
                onSubmit={() => this.props.assignHotel(this.state.value)}
                submitText={"Assing to event"}
                disabled={!this.state.value}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

SingleHotel.defaultProps = {
  orgMode: false,
};

SingleHotel.propTypes = {
  value: PropTypes.object,
  eventId: PropTypes.number,
  saveHotel: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  getOrgHotels: PropTypes.func.isRequired,
  orgHotels: PropTypes.array.isRequired,
  hotels: PropTypes.array.isRequired,
  assignHotel: PropTypes.func.isRequired,
  orgMode: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  eventHotelId: PropTypes.number,
  hideModal: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  orgId: ownProps.orgMode
    ? state.appuser.data.orgId
    : state.api.events.edit.data.orgId,
  hotels: state.api.hotels.list.data,
  eventId: state.api.events.edit.data.id,
  orgHotels: state.api.hotels.orgList.data,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgHotels: () => dispatch(getOrgHotels(undefined, { rpp: -1 })),
  hideModal: () => dispatch(hideModal()),
  assignHotel: (hotelId) => dispatch(assignHotel(hotelId)),
  addNotification: (msg, status) => dispatch(addNotification(msg, status)),
  saveHotel: (
    orgId,
    data,
    hotelId = false,
    assign,
    endpoint = "hotels",
    formData,
    modalOpen = false,
    orgMode = false,
    editMode,
    eventHotelId,
    eventId
  ) => {
    dispatch(
      saveHotel(
        orgId,
        data,
        hotelId,
        endpoint,
        modalOpen,
        orgMode,
        editMode,
        eventHotelId,
        eventId
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleHotel);
