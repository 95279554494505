import React, { useState, useEffect, useRef } from "react";
import Select2 from "@layout/Select2";
import { api } from "../../config/conf";
import PropTypes from "prop-types";

const ClientPanelSelect = (props) => {
  const [options, setOptions] = useState({});
  const policiesMap = useRef({});

  const calculateOptions = () => {
    return props.policies?.reduce((prev, next) => {
      // create a map to use on change event
      policiesMap.current[next.id] = next.active
        ? `${api.clientUrl}/${props.eventURLAlias}/${
            next.default ? "" : next.id
          }`
        : "#";

      // create the actual option
      const name = (
        <div
          className={`event-policies-dropdown ${
            !next.active ? "disabled" : ""
          }`}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          <div
            style={{
              fontSize: 16,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span
              className={
                next.type == "public"
                  ? "icon-public-policy"
                  : "icon-private-policy"
              }
            ></span>
            {!!next.saveUsersToCrm && <span style={{ fontSize: 7 }}>CRM</span>}
          </div>
          <span
            title={next.name + (next.default ? " (Default)" : "")}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {next.name + (next.default ? " (Default)" : "")}
          </span>
        </div>
      );
      return { ...prev, [next.id]: name };
    }, {});
  };
  useEffect(() => {
    setOptions(calculateOptions());
  }, [props.policies, props.eventURLAlias]);

  return (
    <Select2
      options={
        props.eventActive
          ? options
          : {
              _: (
                <span className="event-policies-dropdown disabled">
                  Client Panel is disabled!
                </span>
              ),
            }
      }
      onChange={(val) => {
        if (policiesMap.current[val] === "#") return;
        if (val === "_") return;
        window.open(policiesMap.current[val]);
      }}
      placeholder={
        <span style={{ display: "flex" }}>
          {props.eventActive ? (
            <i
              style={{ paddingRight: 5, fontSize: 18 }}
              className={
                props.eventFreeAccess
                  ? "icon-public-client"
                  : "icon-private-client-panel"
              }
            ></i>
          ) : (
            <span
              className="icon-disabled-client-panel"
              style={{ paddingRight: 5, fontSize: 18 }}
            >
              <span className="path1"></span>
              <span className="path2" style={{ color: "#ff775c" }}></span>
            </span>
          )}
          Client Panel
        </span>
      }
      value={""}
      isDropDownButton={true}
      isSearchable={false}
      {...props}
    />
  );
};

ClientPanelSelect.propTypes = {
  eventFreeAccess: PropTypes.number,
  eventActive: PropTypes.number,
  policies: PropTypes.array,
  eventURLAlias: PropTypes.string,
};
export default ClientPanelSelect;
