import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { connect } from "react-redux";
import { loadPrices, deletePrice } from "@actions/products";
import TableActions from "@layout/TableActions";
import KMBLoader from "@layout/KMBLoader";
import { showModal } from "@actions";
import SinglePrice from "./partials/SinglePrice";
import Breadcrumbs from "@layout/Breadcrumbs";
import { changeSection } from "@actions/editmenu";
import { HeaderPagination } from "../../../../layout/Pagination";

class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.state = {
      viewData: false,
      viewPrices: false,
      productId: null,
      targetUser: 0,
    };
  }
  componentDidMount() {
    this.props.loadPrices(this.props.eventId, this.props.productId);
  }
  goBack() {
    this.setState({ viewData: false, viewPrices: false });
  }
  onRender(col, item) {
    switch (col.key) {
      case "sellingPrice": {
        return col.value.toFixed(2);
      }
      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onDelete={() =>
              this.props.deletePrice(
                this.props.eventId,
                this.props.productId,
                item.id
              )
            }
            onEdit={() =>
              this.props.showModal(
                <SinglePrice
                  h2={"Edit"}
                  eventId={this.props.eventId}
                  productId={this.props.productId}
                  item={item}
                />
              )
            }
          />
        );
      }
    }
  }
  render() {
    if (!this.props.pricesReady) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }
    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <HeaderPagination
              fetching={this.props.fetching}
              p={this.props.p}
              rpp={this.props.rpp}
              totalRows={this.props.totalRows}
              onChangePage={(data) => {
                this.props.loadPrices(
                  this.props.eventId,
                  this.props.productId,
                  {
                    p: data.p,
                    rpp: data.rpp,
                  }
                );
              }}
            />
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SinglePrice
                    h2={"Create"}
                    eventId={this.props.eventId}
                    productId={this.props.productId}
                  />
                )
              }
            >
              Create
            </button>
          </div>
        </div>
        <div className="tab-content">
          <Breadcrumbs
            options={[
              {
                name: "Products List",
                action: () => this.props.changeSection("products"),
              },
              {
                name: this.props.productName,
              },
              {
                name: "Prices",
              },
            ]}
          />

          <Table
            columns={{
              id: {
                name: "ID",
              },
              name: {
                name: "Product",
              },
              minQuantity: {
                name: "Minimum Quantity",
              },
              sellingPrice: {
                name: "Selling Price",
              },
              active: {
                name: "Active",
              },
              actions: {
                name: "",
              },
            }}
            updating={false}
            items={this.props.prices}
            onRender={this.onRender}
          />
        </div>
      </>
    );
  }
}
Prices.propTypes = {
  eventId: PropTypes.number,
  loadPrices: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  pricesReady: PropTypes.bool,
  prices: PropTypes.array,
  showModal: PropTypes.func,
  deletePrice: PropTypes.func,
  targetUser: PropTypes.number,
  goBack: PropTypes.func.isRequired,
  productName: PropTypes.string,
  changeSection: PropTypes.func,
  fetching: PropTypes.bool,
  p: PropTypes.number,
  rpp: PropTypes.number,
  totalRows: PropTypes.number,
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadPrices: (eventId, productId, meta) => {
      return dispatch(loadPrices(eventId, productId, meta));
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    changeSection: (section) => dispatch(changeSection(section)),
    deletePrice: (eventId, productId, itemId) => {
      dispatch(deletePrice(eventId, productId, itemId));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    presetList: state.api.events.presetEmails.data,
    globalVars: state.api.events.presetEmails.meta.available_vars,
    tempContacts: state.users.tempContacts,
    prices: state.api.products.listPrices.data,
    pricesReady: state.api.products.listPrices.ready,
    p: state.api.products.listPrices.meta.p,
    rpp: state.api.products.listPrices.meta.rpp,
    totalRows: state.api.products.listPrices.meta.totalRows,
    fetching: state.api.products.listPrices.meta.fetching,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Prices);
