import React from "react";
import DateRange from "@layout/DateRange";
import KMBEditor from "@layout/KMBEditor";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  loadSchemas,
  loadEventSchema,
  loadSchema,
  assignSchema,
  createSchema,
  getTopics,
  createOrgTopic,
  getTypes,
  createOrgType,
} from "@actions/abstracts";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import moment from "moment";
import KMBLoader from "@layout/KMBLoader";
import AbstractFields from "@layout/AbstractFields";
import Switch from "@layout/Switch";
import Select2 from "@layout/Select2";
import SubmitContainer from "@layout/SubmitContainer";

class Schemas extends React.Component {
  constructor(props) {
    super(props);

    this.default = {
      topics: {
        options: [],
        value: [],
      },
      types: {
        options: [],
        value: [],
      },
      multipleTopics: true,
      judgeSuggestType: true,
      userSuggestType: true,
      autoJudgeAssignment: false,
      enableUploadFilesForPublished: false,
      numberOfJudges: "",
      passingScore: "",
      minScore: "",
      maxScore: "",
      minText: "",
      maxText: "",
      countTextType: "characters",
      startDate: null,
      endDate: null,
      judgingEndDate: null,
      overrideEventAbstracts: false,
      publishedAbstractsSchema: "",
      rules: "",
      maxAbstracts: "",
    };

    this.schemaSet = false;
    this.topicsSet = false;

    this.state = {
      removePreviousMeta: false,
      values: Object.assign({}, this.default),
      errors: [],
      mode: props.eventAbstractSchemaId ? "assigned" : "existing",
      eventAbstractSchemaId: isEmpty(props.eventAbstractSchemaId)
        ? ""
        : props.eventAbstractSchemaId,
      updating: false,
      extraFields: [],
    };

    this.eventFields = [
      {
        key: "multipleTopics",
        label: "The participant can select multiple topics",
        type: "switch",
      },

      {
        key: "userSuggestType",
        label: "The participant can suggest a type",
        type: "switch",
      },
      {
        key: "autoJudgeAssignment",
        label: "Enable automatic judge assignment",
        type: "switch",
      },
      {
        key: "enableUploadFilesForPublished",
        label: "Enable upload files for published abstracts",
        type: "switch",
      },
    ];

    this.fields = [
      {
        key: "rules",
        label: "Submission Rules",
        type: "editor",
        required: true,
      },
      {
        key: "Text",
        label: "Number of characters allowed",
        type: "numberRange",
        sublabels: ["Minimum characters", "Maximum characters"],
        required: true,
      },
      {
        key: "topics",
        label: "Topics",
        type: "multiselect",
      },
      {
        key: "types",
        label: "Presentation Types",
        type: "multiselectType",
      },
      {
        key: "maxAbstracts",
        label: "Maximum number of abstracts per participant",
        type: "number",
      },
      {
        key: "Date",
        label: "Abstract Submission Period (Start Date - End Date)",
        type: "dateRange",
        required: true,
      },
      {
        key: "judgingEndDate",
        label: "Judging End Date",
        type: "date",
        required: true,
      },
      {
        key: "numberOfJudges",
        label: "Judges Number",
        type: "number",
        required: true,
      },
      {
        key: "Score",
        label: "Score Range",
        type: "numberRange",
        sublabels: ["Minimum score", "Maximum score"],
        required: true,
      },
      {
        key: "passingScore",
        label: "Minimum Passing Score",
        type: "number",
        required: true,
      },
    ];

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderField = this.renderField.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  resetFields() {
    this.setState({
      values: Object.assign({}, this.state.values, this.default, {
        eventAbstractSchemaId: null,
        topics: {
          options: this.prepareOptions(this.props.topics),
          value: "",
        },
        types: {
          options: this.prepareOptions(this.props.types),
          value: "",
        },
      }),
    });
  }

  prepareOptions(dataSet) {
    return [...dataSet].map((d) => {
      return { key: d.id.toString(), label: d.name };
    });
  }

  componentDidMount() {
    const {
      orgId,
      eventId,
      eventAbstractSchemaId,
      loadSchema,
      getTopics,
      getTypes,
    } = this.props;
    if (eventAbstractSchemaId !== null) {
      loadSchema(eventAbstractSchemaId, eventId);
    }
    getTopics(orgId);
    getTypes(orgId);
  }
  onCancel() {
    const {
      numberOfJudges,
      passingScore,
      minScore,
      maxScore,
      minText,
      maxText,
      startDate,
      endDate,
      judgingEndDate,
      publishedAbstractsSchema,
      overrideEventAbstracts,
      maxAbstracts,
      rules,
      topics,
      types,
      multipleTopics,
      judgeSuggestType,
      userSuggestType,
      countTextType,
      meta,
    } = this.props.schema;
    this.setState({
      values: Object.assign({}, this.state.values, {
        numberOfJudges,
        passingScore,
        minScore,
        maxScore,
        minText,
        maxText,
        startDate: startDate.tz,
        endDate: endDate.tz,
        judgingEndDate: moment(judgingEndDate.tz).format("DD-MM-YYYY"),
        maxAbstracts,
        rules,
        multipleTopics: multipleTopics === 1,
        judgeSuggestType: judgeSuggestType === 1,
        userSuggestType: userSuggestType === 1,
        overrideEventAbstracts: overrideEventAbstracts === 1,
        publishedAbstractsSchema,
        topics: Object.assign({}, this.state.values.topics, {
          value: topics.map((t) => t.orgAbstractSchemaTopicId.toString()),
          options: this.prepareOptions([...this.props.topics]),
        }),
        types: Object.assign({}, this.state.values.types, {
          value: types.map((t) => t.orgAbstractSchemaTypeId.toString()),
          options: this.prepareOptions([...this.props.types]),
        }),
        countTextType,
        meta,
      }),
      extraFields:
        Object.entries(meta || {})
          .map(([key, value]) => ({ ...value, key }))
          .sort((a, b) => (a.index > b.index ? 1 : -1)) || [],
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.schema)) {
      const {
        numberOfJudges,
        passingScore,
        minScore,
        maxScore,
        minText,
        maxText,
        startDate,
        endDate,
        judgingEndDate,
        rules,
        topics,
        types,
        multipleTopics,
        overrideEventAbstracts,
        judgeSuggestType,
        autoJudgeAssignment,
        publishedAbstractsSchema,
        userSuggestType,
        countTextType,
        meta,
        maxAbstracts,
        enableUploadFilesForPublished,
      } = nextProps.schema;

      this.setState({
        values: Object.assign({}, this.state.values, {
          numberOfJudges,
          passingScore,
          minScore,
          maxScore,
          minText,
          maxText,
          autoJudgeAssignment,
          enableUploadFilesForPublished,
          startDate: startDate.tz,
          endDate: endDate.tz,
          judgingEndDate: moment(judgingEndDate.tz).format("DD-MM-YYYY"),
          rules,
          multipleTopics: multipleTopics === 1,
          overrideEventAbstracts: overrideEventAbstracts === 1,
          publishedAbstractsSchema,
          judgeSuggestType: judgeSuggestType === 1,
          userSuggestType: userSuggestType === 1,
          topics: Object.assign({}, this.state.values.topics, {
            value: topics.map((t) => t.orgAbstractSchemaTopicId.toString()),
            options: this.prepareOptions([...nextProps.topics]),
          }),
          types: Object.assign({}, this.state.values.types, {
            value: types.map((t) => t.orgAbstractSchemaTypeId.toString()),
            options: this.prepareOptions([...nextProps.types]),
          }),
          countTextType,
          meta,
          maxAbstracts,
        }),
        extraFields:
          Object.entries(meta || {})
            .map(([key, value]) => ({
              ...value,
              key,
            }))
            .sort((a, b) => (a.index > b.index ? 1 : -1)) || [],
      });
      this.schemaSet = true;
    } else {
      this.setState({
        values: Object.assign({}, this.state.values, {
          topics: Object.assign({}, this.state.values.topics, {
            options: this.prepareOptions([...nextProps.topics]),
          }),
          types: Object.assign({}, this.state.values.types, {
            options: this.prepareOptions([...nextProps.types]),
          }),
        }),
      });
    }
    this.setState({ update: true }, () => this.setState({ update: false }));
  }

  prepareExtraFields() {
    let response = false;
    if (!isEmpty(this.state.extraFields)) {
      const extraFields = [...this.state.extraFields];
      response = {};

      this.state.extraFields.forEach((f, index) => {
        // reset
        extraFields[index]["errors"] = [];

        if (!f.name && !f.delete) {
          extraFields[index]["errors"].push("name");
        }

        if (
          f.type === "dropdown" &&
          f.hasOwnProperty("value") &&
          !f.value.length
        ) {
          extraFields[index]["errors"].push("value");
        }

        const preparedExtraField = Object.assign({}, f);
        delete preparedExtraField.errors;

        const newKey = preparedExtraField.hasOwnProperty("key")
          ? preparedExtraField.key
          : uuidv4();

        if (preparedExtraField.hasOwnProperty("key")) {
          // its an edit.
          delete preparedExtraField.key;
        }

        if (preparedExtraField.hasOwnProperty("db")) {
          delete preparedExtraField["db"];
        }

        if (preparedExtraField.hasOwnProperty("delete")) {
          delete preparedExtraField["delete"];
        }

        if (f.type === "dropdown") {
          preparedExtraField.value =
            preparedExtraField.value.length > 0 ? preparedExtraField.value : "";
        }

        if (preparedExtraField.deleted) {
          // deleted fields are not sent to the server.
          return;
        }
        preparedExtraField.index = index;
        response[newKey] = preparedExtraField;
      });
      return response;
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const meta = this.prepareExtraFields();
    const { values } = this.state;

    const { schema, createSchema } = this.props;
    const errors = [];
    const request = {};

    Object.entries(values).map(([key, value]) => {
      let isRequired = this.fields.filter((f) => f.key === key)[0]?.required;
      // handle some edge cases of required fields with different keys
      if (key === "minText" || key === "maxText") {
        isRequired = this.fields.filter((f) => f.key === "Text")[0]?.required;
      }
      if (key === "minScore" || key === "maxScore") {
        isRequired = this.fields.filter((f) => f.key === "Score")[0]?.required;
      }
      if (key === "startDate" || key === "endDate") {
        isRequired = this.fields.filter((f) => f.key === "Date")[0]?.required;
      }
      if (
        key !== "publishedAbstractsSchema" &&
        (value == null || value === "") &&
        isRequired
      )
        return errors.push(key);
      request[key] = value;
    });

    if (errors.length > 0) {
      this.setState({ errors });
    } else {
      // split request
      const orgRequest = {};
      const eventRequest = {};

      Object.entries(request).map(([k, v]) => {
        if (
          [
            "startDate",
            "endDate",
            "judgingEndDate",
            "topics",
            "types",
            "multipleTopics",
            "judgeSuggestType",
            "overrideEventAbstracts",
            "publishedAbstractsSchema",
            "userSuggestType",
            "enableUploadFilesForPublished",
            "autoJudgeAssignment",
            "enableUploadFilesForPublished",
          ].includes(k)
        ) {
          eventRequest[k] =
            k === "topics" || k === "types"
              ? [...v.value].map((vv) => parseInt(vv))
              : v;
        } else {
          orgRequest[k] = v;
        }
      });
      if (meta) {
        orgRequest["meta"] = meta;
      }
      const _request = { ...orgRequest, ...eventRequest };
      createSchema(
        _request,
        !isEmpty(schema),
        isEmpty(schema) ? false : schema.id,
        this.state.removePreviousMeta
      );
    }
  }

  onChange(e) {
    const { name, value, type } = e.target;
    const errors = [...this.state.errors].filter((v) => v !== name);
    this.setState({
      values: Object.assign({}, this.state.values, {
        [name]: type === "checkbox" ? e.target.checked : value,
      }),
      errors,
    });
  }

  renderField(field) {
    switch (field.type) {
      case "text": {
        return (
          <input
            type="text"
            className="form-control"
            placeholder={`Set the ${field.label}`}
            onChange={this.onChange}
            name={field.key}
            value={this.state.values[field.key]}
          />
        );
      }
      case "textarea": {
        return (
          <textarea
            type="textarea"
            className="form-control"
            placeholder={`Set the ${field.label}`}
            onChange={this.onChange}
            name={field.key}
            value={this.state.values[field.key]}
          />
        );
      }
      case "switch": {
        return (
          <div className="type-switch">
            <div className="switch-holder">
              <Switch
                isActive={this.state.values[field.key]}
                id={field.key}
                onChange={(val) =>
                  this.onChange({
                    target: {
                      name: field.key,
                      value: val,
                    },
                  })
                }
              />
            </div>
          </div>
        );
      }

      case "multiselectType":
      case "multiselect": {
        const options = [...this.state.values[field.key].options];
        const newOptions = options.reduce((a, b) => {
          return { ...a, ...{ [b.key]: b.label } };
        }, {});
        return (
          <div>
            <Select2
              allowCreate={true}
              options={newOptions}
              onChange={(val, action, inputValue) => {
                if (val.slice(-1)[0] === "new") {
                  val.splice(-1);
                  this.props[
                    field.type === "multiselect"
                      ? "createOrgTopic"
                      : "createOrgType"
                  ](this.props.orgId, inputValue)
                    .then((response) => {
                      this.setState({
                        values: Object.assign(
                          {},
                          {
                            ...this.state.values,
                            [field.key]: {
                              value: [...val, response.data.id.toString()],
                              options: [
                                ...options,
                                {
                                  key: response.data.id,
                                  label: response.data.name,
                                },
                              ],
                            },
                          }
                        ),
                      });
                    })
                    .catch(console.error);
                } else {
                  this.setState({
                    values: Object.assign(
                      {},
                      {
                        ...this.state.values,
                        [field.key]: {
                          value: val,
                          options: this.state.values[field.key].options,
                        },
                      }
                    ),
                  });
                }
              }}
              placeholder={field.type == "multiselect" ? "Topics" : "Types"}
              value={this.state.values[field.key].value}
              multi={true}
            />
          </div>
        );
      }

      case "number": {
        return (
          <input
            type="number"
            min="0"
            className="form-control"
            placeholder={`Set the ${field.label}`}
            onChange={this.onChange}
            name={field.key}
            value={this.state.values[field.key]}
          />
        );
      }
      case "numberRange": {
        return (
          <div className="row">
            <div className="col-sm-6">
              <span className="subtitle">{field.sublabels[0]}:</span>
              <input
                type="number"
                className="form-control"
                placeholder={`Set the ${field.sublabels[0]}`}
                name={`min${field.key}`}
                value={this.state.values[`min${field.key}`]}
                onChange={this.onChange}
              />
            </div>
            <div className="col-sm-6">
              <span className="subtitle">{field.sublabels[1]}:</span>
              <input
                type="number"
                className="form-control"
                placeholder={`Set the ${field.sublabels[1]}`}
                name={`max${field.key}`}
                value={this.state.values[`max${field.key}`]}
                onChange={this.onChange}
              />
            </div>
          </div>
        );
      }
      case "dateRange": {
        return (
          <DateRange
            dateFormat="DD-MM-YYYY"
            from={{ value: this.state.values[`start${field.key}`] }}
            to={{ value: this.state.values[`end${field.key}`] }}
            name={field.key}
            onChange={(range) => {
              range.from =
                range.from &&
                moment(range.from, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:00");
              range.to =
                range.to &&
                moment(range.to, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:00");
              const errors = [...this.state.errors].filter(
                (v) => v !== `start${field.key}` && v !== `end${field.key}`
              );
              const data = {};
              Object.entries(range).map(([key, value]) => {
                key = key.replace("from", "start").replace("to", "end");
                // data[`${key}${field.key}`] = value;
                if (value) {
                  data[`${key}${field.key}`] = value;
                }
              });
              this.setState({
                values: Object.assign({}, this.state.values, data),
                errors,
              });
            }}
          />
        );
      }
      case "date": {
        return (
          <Datetime
            dateFormat={"DD-MM-YYYY"}
            timeFormat={false}
            closeOnSelect={true}
            value={
              this.state.values[field.key]
                ? this.state.values[field.key]
                : new Date()
            }
            inputProps={{
              placeholder: `Set the ${field.label}`,
              readOnly: true,
            }}
            onChange={(value) =>
              this.onChange({
                target: { name: field.key, value: value.format("DD-MM-YYYY") },
              })
            }
          />
        );
      }
      case "editor": {
        return (
          <div style={{ minHeight: 298 }}>
            <KMBEditor
              placeholder="Type the submission rules"
              height={298}
              value={this.state.values[field.key]}
              onChange={(value) => {
                this.onChange({ target: { name: field.key, value } });
              }}
            />
          </div>
        );
      }
    }
  }

  render() {
    const { errors } = this.state;
    const { fetching } = this.props;

    return (
      <div className="tab-content">
        <div className="form-container">
          {fetching && <KMBLoader rows={20} padding={24} height={53} />}
          {!fetching && (
            <>
              <div>
                {[...this.fields, ...this.eventFields].map((field, index) => {
                  const hasError =
                    errors.includes(field.key) ||
                    errors.includes(`start${field.key}`) ||
                    errors.includes(`end${field.key}`) ||
                    errors.includes(`min${field.key}`) ||
                    errors.includes(`max${field.key}`);

                  if (field.key === "Text") {
                    if (this.state.values.countTextType === "words") {
                      field.sublabels[0] = "Minimum words";
                      field.sublabels[1] = "Maximum words";
                    } else {
                      field.sublabels[0] = "Minimum characters";
                      field.sublabels[1] = "Maximum characters";
                    }
                  }

                  return (
                    <div
                      className={`form-group${hasError ? " has-error" : ""}`}
                      key={`field-${index}`}
                    >
                      {field.key === "Text" ? (
                        <label htmlFor="">
                          Number of
                          <span
                            onClick={() =>
                              this.setState({
                                values: Object.assign({}, this.state.values, {
                                  countTextType: "characters",
                                }),
                              })
                            }
                            className={
                              this.state.values.countTextType === "characters"
                                ? "selected"
                                : ""
                            }
                          >
                            characters
                          </span>
                          /
                          <span
                            className={
                              this.state.values.countTextType === "words"
                                ? "selected"
                                : ""
                            }
                            onClick={() =>
                              this.setState({
                                values: Object.assign({}, this.state.values, {
                                  countTextType: "words",
                                }),
                              })
                            }
                          >
                            words
                          </span>
                          {`allowed${field.required ? " (*)" : ""}`}
                        </label>
                      ) : (
                        <label htmlFor="">{`${field.label}${
                          field.required ? " (*)" : ""
                        }`}</label>
                      )}

                      {this.renderField(field)}
                      {hasError && (
                        <div className="help-block">
                          {field.label} is required!
                        </div>
                      )}
                    </div>
                  );
                })}
                {this.state.update ? (
                  <KMBLoader rows={15} padding={24} height={53} />
                ) : (
                  <>
                    <br />
                    <h3>Additional submission fields</h3>
                    <AbstractFields
                      items={this.state.extraFields}
                      id={this.state.eventAbstractSchemaId}
                      onChange={(extraFields) => {
                        this.setState({ extraFields });
                      }}
                    />
                  </>
                )}
                <SubmitContainer
                  onCancel={this.onCancel}
                  onSubmit={this.onSubmit}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

Schemas.propTypes = {
  schemas: PropTypes.array,
  orgId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  loadSchemas: PropTypes.func.isRequired,
  assignSchema: PropTypes.func.isRequired,
  eventAbstractSchemaId: PropTypes.number,
  loadSchema: PropTypes.func.isRequired,
  schema: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  createSchema: PropTypes.func.isRequired,
  getTopics: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  topics: PropTypes.array.isRequired,
  createOrgTopic: PropTypes.func.isRequired,
  createOrgType: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { orgId, id, eventAbstractSchemaId } = state.api.events.edit.data;
  return {
    orgId,
    eventId: id,
    schemas: state.api.abstracts.schemas.data,
    eventAbstractSchemaId,
    schema: state.api.abstracts.currentSchema.data,
    fetching:
      state.api.abstracts.currentSchema.fetching ||
      state.api.abstracts.schemas.fetching,
    topics: state.api.abstracts.topics.data,
    types: state.api.abstracts.types.data,
    role: state.appuser.currentAccess.role,
  };
};

export default connect(mapStateToProps, {
  loadSchemas,
  loadSchema,
  assignSchema,
  createSchema,
  loadEventSchema,
  getTopics,
  createOrgTopic,
  getTypes,
  createOrgType,
})(Schemas);
