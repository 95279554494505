import React from "react";
import Select2 from "@layout/Select2";

import PropTypes from "prop-types";
import DateRange from "@layout/DateRange";

export default class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtype: {
        mode: props.subtype.mode,
        value: props.value.subtype ? props.value.subtype : "",
        options: props.subtypesOptions,
        label: "Policy Group",
      },
      subperiod: {
        mode: props.subperiod.mode,
        value: props.value.subperiod ? props.value.subperiod : "",
        options: props.subperiodsOptions,
        label: "Subscription Period",
      },
    };

    this.columns = ["subtype", "subperiod"];
    this.setValue = this.setValue.bind(this);
  }

  setValue(c, type, value, isDate = false) {
    if (c === "subperiod" && this.state[c].mode === "new" && type !== "mode") {
      const key = isDate ? "date" : "name";

      value = Object.assign({}, this.state[c].value, {
        [key]: value,
      });
    }

    if (c === "subtype" && this.state[c].mode === "new" && type !== "mode") {
      const key = "name";
      value = Object.assign({}, this.state[c].value, {
        [key]: value,
      });
    }

    const obj = {
      [type]: value,
    };

    if (type === "mode") {
      obj["value"] = "";
    }

    this.setState({
      [c]: Object.assign({}, this.state[c], obj),
    });
  }

  render() {
    return (
      <div className="subscription-inner">
        {this.columns.map((c, i) => {
          return (
            <div
              key={`sub-${i}`}
              className={`form-group${
                this.props.hasErrors && !this.state[c].value ? " has-error" : ""
              }`}
            >
              <div
                className={`mode-${this.state[c].mode}-wrapper subscription-wrapper`}
              >
                {this.state[c].mode === "existing" && (
                  <div>
                    <label className="label-inner" htmlFor="">
                      Select a {this.state[c].label}
                    </label>
                    {c === "subperiod" && (
                      <div
                        className="form-group"
                        style={{ display: "flex", gap: 10 }}
                      >
                        <div style={{ display: "flex", flex: 1 }}>
                          <button
                            style={{ flex: 1 }}
                            className={`btn${
                              this.state[c].mode === "existing"
                                ? " selected"
                                : ""
                            }`}
                            type="button"
                            onClick={() => this.setValue(c, "mode", "existing")}
                          >
                            Select from existing
                          </button>
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>
                          <button
                            style={{ flex: 1 }}
                            className={`btn${
                              this.state[c].mode === "new" ? " selected" : ""
                            }`}
                            type="button"
                            onClick={() => this.setValue(c, "mode", "new")}
                          >
                            Create New
                          </button>
                        </div>
                      </div>
                    )}
                    <Select2
                      options={this.state[c].options}
                      onChange={(val) => this.setValue(c, "value", val)}
                      value={this.state[c].value}
                    />
                  </div>
                )}
                {this.state[c].mode === "new" && (
                  <div>
                    <div className="field-type type-text kmb-text">
                      <label className="label-inner" htmlFor="newSubscription">
                        New {this.state[c].label}
                      </label>
                      {c === "subperiod" && (
                        <div
                          className="form-group"
                          style={{ display: "flex", gap: 10 }}
                        >
                          <div style={{ display: "flex", flex: 1 }}>
                            <button
                              style={{ flex: 1 }}
                              className={`btn${
                                this.state[c].mode === "existing"
                                  ? " selected"
                                  : ""
                              }`}
                              type="button"
                              onClick={() =>
                                this.setValue(c, "mode", "existing")
                              }
                            >
                              Select from existing
                            </button>
                          </div>
                          <div style={{ display: "flex", flex: 1 }}>
                            <button
                              style={{ flex: 1 }}
                              className={`btn${
                                this.state[c].mode === "new" ? " selected" : ""
                              }`}
                              type="button"
                              onClick={() => this.setValue(c, "mode", "new")}
                            >
                              Create New
                            </button>
                          </div>
                        </div>
                      )}
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="newSubscriptionType"
                          placeholder={`Please type the name of the subscription ${c.substring(
                            3
                          )}`}
                          id="newSubscriptionType"
                          onChange={(e) =>
                            this.setValue(c, "value", e.target.value)
                          }
                        />
                        <span className="icon icon-edit"></span>
                      </div>
                    </div>
                    {c === "subperiod" && (
                      <div className="kmb-range">
                        <DateRange
                          from={{ label: "From" }}
                          hasErrors={
                            this.props.hasErrors && !this.state[c].value.date
                          }
                          onChange={(date) =>
                            this.setValue(c, "value", date, true)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

Subscription.propTypes = {
  subtype: PropTypes.object.isRequired,
  subperiod: PropTypes.object.isRequired,
  subperiodsOptions: PropTypes.object.isRequired,
  subtypesOptions: PropTypes.object.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
