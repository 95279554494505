import React from "react";
import { connect } from "react-redux";
import {
  getParticipants,
  loadUsers,
  updateUser,
  printCard,
  getUserBadge,
  exportUsers,
  getUserCertificate,
  getUsersCertificates,
  showUserEvents,
  hideUserEvents,
  getGroups,
  loadRooms,
  loadUser,
} from "@actions/users";
import { changeTab } from "@actions/editmenu";
import {
  showModal,
  loadEvent,
  hideModal,
  addNotification,
  createUser,
  setupPrinters,
  deleteItem,
  disableNativePrinting,
  enableNativePrinting,
} from "@actions";
import Users from "./Users";
import ApiHelper from "@helpers/api-helper";
import CreateUser from "@components/events/edit/sections/registration/AccessPolicies/partials/CreateUser/CreateUser";
import { getUserBadges, loadPolicy } from "@actions/users";

const mapStateToProps = (state) => {
  return {
    contentLoaded: state.page.contentLoaded,
    policyId: state.api.accesspolicies.selectedPolicy.id,
    eventId: state.api.events.edit.data.id,
    users: state.users.data,
    fetching:
      state.users.fetching ||
      state.api.users.create.fetching ||
      state.api.users.update.fetching ||
      state.api.users.delete.fetching,
    policy: state.api.accesspolicies.selectedPolicy,
    columns: state.users.columns,
    rooms: state.users.rooms,
    activeSort: state.users.orderBy,
    meta: state.users.meta,
    printers: state.page.printers,
    timestamp: state.users.timestamp,
    userSet:
      state.users.advancedSearch ||
      state.users.meta.orderBy !== "id" ||
      state.users.meta.order !== "DESC" ||
      (state.users.meta.hasOwnProperty("search") &&
        state.users.meta.search !== ""),
    userEvents: state.users.userEvents,
    groupId:
      ((state.users.groupId || {})[state.api.events.edit.data.id] || {})[
        state.api.accesspolicies.selectedPolicy.id
      ] || "",

    advancedSearchOptions:
      (state.api.advancedSearchOptions[state.api.events.edit.data.id] || {})[
        state.api.accesspolicies.selectedPolicy.id
      ] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    loadUsers: (eventId, policyId, criteria = null) => {
      return dispatch(
        loadUsers(
          eventId,
          policyId,
          criteria,
          false,
          false,
          false,
          false,
          false,
          false,
          true
        )
      );
    },
    loadEvent: (eventId) => {
      dispatch(loadEvent(eventId));
    },
    getParticipants: (options) => dispatch(getParticipants(options, true)),
    enableNativePrinting: (item, content, callback) =>
      dispatch(enableNativePrinting(item, content, callback)),
    disableNativePrinting: () => dispatch(disableNativePrinting()),
    loadPolicy: (eventId, policyId) => dispatch(loadPolicy(policyId, eventId)),
    loadUser(eventId, userId) {
      return dispatch(
        loadUser(eventId, userId, "?subscriptions=true&info=true")
      );
    },
    sortUsers(eventId, policyId, key) {
      dispatch(loadUsers(eventId, policyId, { orderBy: key }, true));
    },
    changePage(eventId, policyId, data) {
      dispatch(loadUsers(eventId, policyId, data, false));
    },
    changeTab(tab) {
      dispatch(changeTab(tab));
    },

    hideModal: () => {
      dispatch(hideModal());
    },
    getGroups: (eventId, policyId) => {
      dispatch(getGroups(eventId, policyId));
    },
    loadRooms: (eventId) => {
      dispatch(loadRooms(eventId));
    },
    fetchMinutes: (eventId, policyId, meta) => {
      let data = Object.assign({}, meta);
      data.points = meta.hasOwnProperty("points") && meta.points ? true : false;
      data.info = false;
      data = ApiHelper.normalizeUserCriteria(data);
      dispatch(loadUsers(eventId, policyId, data, false, false, true));
    },

    showUserEvents: (userId) => {
      dispatch(showUserEvents(userId));
    },

    hideUserEvents: () => dispatch(hideUserEvents()),

    editUser(policy, user, createUser) {
      dispatch(
        showModal(
          <CreateUser
            hideModal={dispatcher.hideModal}
            policy={policy}
            createUser={createUser}
            user={user}
          />
        )
      );
    },

    updateUser: (
      data,
      policyId,
      eventId,
      userId,
      saveAndPrint = false,
      action,
      active
    ) => {
      return dispatch(
        updateUser(
          data,
          policyId,
          eventId,
          userId,
          saveAndPrint,
          action,
          active
        )
      ).then((data) => data);
    },

    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },

    createUser: (
      data,
      policyId,
      eventId,
      userId,
      saveAndPrint = false,
      deletePhoto,
      selectedUserId
    ) => {
      if (userId) {
        return dispatch(
          updateUser(data, policyId, eventId, userId, saveAndPrint, deletePhoto)
        ).then(() => dispatch(loadUsers(eventId, policyId)));
      }
      return dispatch(
        createUser(
          data,
          policyId,
          eventId,
          saveAndPrint,
          undefined,
          selectedUserId
        )
      ).then(() => dispatch(loadUsers(eventId, policyId)));
    },

    addUser: (policy) => {
      dispatch(
        showModal(
          <CreateUser
            hideModal={dispatcher.hideModal}
            policy={policy}
            createUser={dispatcher.createUser}
          />
        )
      );
    },

    setupPrinters: () => {
      dispatch(setupPrinters(true)).catch((e) => console.error(e));
    },

    deleteItem: (eventId, policyId, userId) => {
      return dispatch(deleteItem({ eventId, policyId, userId }, "users")).then(
        () => dispatch(loadUsers(eventId, policyId))
      );
    },

    exportUsers: (
      eventId,
      policyId,
      columns,
      userSet = [],
      all = false,
      groupId = null
    ) => {
      const notExported = [
        "card",
        "certificate",
        "subscription",
        "speaker",
        "actions",
        "crmUserProfilePhoto",
        "password",
        "events",
      ];
      columns = columns.filter((k) => notExported.indexOf(k) < 0);
      dispatch(exportUsers(eventId, policyId, columns, userSet, all, groupId));
    },

    printCard: (user) => dispatch(printCard(user)),
    getUserCertificate: (
      eventId,
      policyId,
      userId,
      overridePoints,
      certPoints,
      noPoints
    ) =>
      dispatch(
        getUserCertificate(
          eventId,
          policyId,
          userId,
          overridePoints,
          certPoints,
          noPoints
        )
      ),
    getUsersCertificates: (
      eventId,
      policyId,
      users,
      overridePoints,
      certPoints,
      noPoints
    ) =>
      dispatch(
        getUsersCertificates(
          eventId,
          policyId,
          users,
          overridePoints,
          certPoints,
          noPoints
        )
      ),
    getUserBadge: (eventId, policyId, userId) =>
      dispatch(getUserBadge(eventId, policyId, userId)),
    getUserBadges: (eventId, policyId, users) =>
      dispatch(getUserBadges(eventId, policyId, users)),
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
