import React from "react";
import Table from "@layout/Table";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadCrmUsers, loadCrmSchemas, loadCrmSchema } from "@actions/crm";
import { isEmpty } from "@helpers";

import { hideModal } from "@actions";

class Listing extends React.Component {
  constructor(props) {
    super(props);
    ["onSort"].forEach((fn) => (this[fn] = this[fn].bind(this)));

    this.fetched = false;
    this.state = {};
    this.values = {};
    this.extraFields = [];
  }

  componentDidMount() {
    this.props.loadUsers(this.props.orgId, this.props.mode);
    this.props.loadSchemas(this.props.orgId);
    // this.props.loadSchema( this.props.orgId, null, null, 1 )
  }
  onSort(orderBy) {
    this.props.loadUsers(
      this.props.orgId,
      this.props.mode,
      { ...this.props.meta, orderBy },
      false
    );
  }
  render() {
    const columns = {
      cosmetic: {
        name: "",
      },
      id: {
        name: "ID",
        sortable: true,
      },
    };

    const { data, activeSort, mode } = this.props;
    const users = [];
    const yesNoFields = [];
    this.props.schemas.orglist &&
      Object.keys(this.props.schemas.orglist.data).forEach(() => {
        Object.keys(this.props.schemas.orglist.data.meta).forEach((entry) => {
          if (this.props.schemas.orglist.data.meta[entry].type === "yes_no") {
            yesNoFields.push(
              this.props.schemas.orglist.data.meta[entry].name.toLowerCase()
            );
          }
        });
      });
    Object.entries(data).map((user) => {
      Object.keys(user[1].info).forEach((key) => {
        if (yesNoFields.indexOf(key) !== -1) {
          if (user[1].info[key] === "1") {
            user[1].info[key] = "Yes";
          } else if (user[1].info[key] === "0") {
            user[1].info[key] = "No";
          }
        }
      });
      users.push({
        ...user[1].info,
        id: user[1].id,
        orgId: user[1].orgId,
        active: user[1].active,
        createdAt: user[1].createdAt,
        updatedAt: user[1].updatedAt,
        registered: user[1].registered === 1 ? "Yes" : "No",
        crmUserProfilePhoto: isEmpty(user[1].crmUserProfilePhoto)
          ? undefined
          : user[1].crmUserProfilePhoto,
      });
    });
    this.props.schemas.orglist &&
      Object.keys(this.props.schemas.orglist.data).forEach((key) => {
        const columnName = this.props.schemas.orglist.data[key].name;
        if (
          columnName &&
          columnName !== "Resume" &&
          columnName !== "Profile Photo"
        ) {
          columns[key] = {
            name: this.props.schemas.orglist.data[key].name,
            sortable: true,
          };
        }
      });

    this.props.schemas.orglist &&
      Object.keys(this.props.schemas.orglist.data).forEach(() => {
        if (this.props.schemas.orglist.data["meta"]) {
          Object.keys(this.props.schemas.orglist.data["meta"]).forEach(
            (keyMeta) => {
              this.values[keyMeta] =
                this.props.schemas.orglist.data["meta"][keyMeta];
              if (this.props.schemas.orglist.data["meta"][keyMeta].name) {
                columns[keyMeta] = {
                  name: this.props.schemas.orglist.data["meta"][keyMeta].name,
                  sortable: true,
                };
              }
            }
          );
        }
      });

    // The above process could be fit in one loop easily, just copy the second if to the first one
    // but we do not achieve the desired ordering. The linear time is O(n^2) if we fit it in one loop
    // In this case we have O(n) + O(n^2), the difference is negligible, but we get the right order
    columns["createdAt"] = { name: "Created At", sortable: true };
    columns["registered"] = { name: "Registered", sortable: true };
    columns["active"] = { name: "Active", sortable: true };
    columns["actions"] = { name: "" };
    if (mode === "persons") {
      delete columns["phoneNumber"];
      delete columns["assignRoles"];
    }
    if (columns.hasOwnProperty(activeSort)) {
      columns[activeSort]["activeSort"] = true;
    }
    if (columns["password"]) {
      delete columns["password"];
    }
    const newColumns = {};
    Object.keys(columns).forEach((k) => {
      if (
        this.props.crmColumns[k] &&
        this.props.crmColumns[k].checked === true
      ) {
        newColumns[k] = columns[k];
      }
    });
    newColumns["actions"] = { name: "" };

    switch (this.props.type) {
      case "listing": {
        return (
          <div className="main-wrapper">
            <React.Fragment>
              <Table
                id={"crm-list"}
                items={users}
                columns={newColumns}
                onRender={this.props.onRender}
                onSort={this.onSort}
                updating={!this.props.dataReady || this.props.fetching}
                containerClassName={`table-container`}
              />
            </React.Fragment>
          </div>
        );
      }
    }
  }
}

Listing.propTypes = {
  orgId: PropTypes.number,
  loadUsers: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  dataReady: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  columns: PropTypes.array,
  users: PropTypes.array,
  p: PropTypes.any,
  rpp: PropTypes.any,
  totalRows: PropTypes.any,
  onRender: PropTypes.func.isRequired,
  loadSchema: PropTypes.func.isRequired,
  loadSchemas: PropTypes.func.isRequired,
  schemas: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  activeSort: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  crmColumns: PropTypes.object,
};

const mapStateToProps = (state) => {
  const mode = "crm";
  return {
    orgId: state.appuser.ready ? state.appuser.data.orgId : null,
    data: state.api[mode].list.data,
    schemas: state.api["schemas"],
    // schema: state.api[ "schemas" ].orgList.data,
    crmColumns: state.api.crmColumns,
    dataReady: state.api[mode].list.ready,
    fetching: state.api[mode].list.fetching,
    mode,
    activeSort: state.api[mode].list.meta.orderBy || "id",
    meta: state.api[mode].list.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: (orgId, mode, meta = false, cache = false) => {
      // It enters here
      // and in the dispatch
      // the third parameter is equal to the endpoint when it tries to make the call
      dispatch(loadCrmUsers(orgId, meta, mode, cache));
    },

    loadSchemas: (orgId, meta = false, cache = false) => {
      dispatch(loadCrmSchemas(orgId, meta, cache));
    },
    loadSchema: (orgId, meta = false, cache = false, schemaId) => {
      dispatch(loadCrmSchema(orgId, meta, cache, schemaId));
    },

    hideModal: () => {
      dispatch(hideModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
