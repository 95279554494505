const formFields = {
  getValue: (key, field, values) => {
    if (field.hasOwnProperty("path")) {
      // nested value
      const split = field.path.split("/");
      split.push(key);
      let val = Object.assign({}, values);
      split.map((s) => {
        if (val.hasOwnProperty(s)) {
          val = val[s];
        }
      });
      return val;
    } else {
      if (values.hasOwnProperty(key)) {
        return values[key];
      }
    }

    return false;
  },

  getFields: function (component, formName, values) {
    const grouped = {};
    const conditional = new Array();

    //values = rootLevelObject( values );

    if (!this.forms.hasOwnProperty(component)) {
      return false;
    }

    if (!this.forms[component].hasOwnProperty(formName)) {
      return false;
    }

    const cloneForm = {};
    Object.entries(this.forms[component][formName]).forEach(([key, field]) => {
      cloneForm[key] = this.cloneField(field);
      if (this.forms[component][formName][key].callback) {
        cloneForm[key].callback = this.forms[component][formName][key].callback;
      }
    });

    for (const [key, field] of Object.entries(cloneForm)) {
      if (field.hasOwnProperty("condition")) {
        field.condition.map((f) => {
          if (!conditional.includes(f.key)) conditional.push(f.key);
        });
      }

      if (field.hasOwnProperty("conditionSchema")) {
        field.conditionSchema.filters?.forEach((block) => {
          block.forEach((f) => {
            if (!conditional.includes(f.key)) conditional.push(f.key);
          });
        });
      }

      if (field.type === "group") {
        for (const [groupKey] of Object.entries(field.fields)) {
          grouped[groupKey] = { group: key };
          if (values.hasOwnProperty(groupKey)) {
            cloneForm[key]["fields"][groupKey]["value"] = values[groupKey];
            cloneForm[key]["fields"][groupKey]["callback"] =
              this.forms[component][formName][key]["fields"][groupKey][
                "callback"
              ];
          }
        }
      } else if (field.type === "range") {
        for (const [rangeKey, rangeValue] of Object.entries(field)) {
          if (rangeKey === "from" && values.hasOwnProperty(rangeValue.name)) {
            cloneForm[key].from.value = values[rangeValue.name];
          } else if (
            rangeKey === "to" &&
            values.hasOwnProperty(rangeValue.name)
          ) {
            cloneForm[key].to.value = values[rangeValue.name];
          } else if (
            rangeKey === "fromTime" &&
            values.hasOwnProperty(rangeValue.name)
          ) {
            cloneForm[key].fromTime.value = values[rangeValue.name];
          } else if (
            rangeKey === "toTime" &&
            values.hasOwnProperty(rangeValue.name)
          ) {
            cloneForm[key].toTime.value = values[rangeValue.name];
          }
        }
      } else {
        const val = formFields.getValue(key, field, values);
        if (val !== false) {
          cloneForm[key]["value"] = formFields.getValue(key, field, values);
        }
        const hasCallback = this.forms[component][formName][key]["callback"];
        if (hasCallback) {
          cloneForm[key]["callback"] = hasCallback;
        }
      }
    }

    return { fields: cloneForm, grouped, conditional };
  },

  cloneField: function (field) {
    const clonedField = { ...field };
    if (field.type === "group") {
      clonedField.fields = {};
      Object.entries(field.fields).forEach(([key, childField]) => {
        clonedField.fields[key] = this.cloneField(childField);
      });
    }
    return clonedField;
  },

  forms: {
    schemas: {
      single: {
        firstName: {
          id: "firstName",
          name: "firstName",
          type: "text",
          required: true,
          label: "First Name",
        },
        lastName: {
          id: "lastName",
          name: "lastName",
          type: "text",
          required: true,
          label: "Last Name",
        },
      },
    },
    sponsors: {
      single: {
        name: {
          id: "name",
          name: "name",
          type: "text",
          required: true,
          label: "Sponsor Name",
        },
        country: {
          id: "country",
          name: "country",
          type: "text",
          required: true,
          label: "Country",
        },
        sponsorThumbnail: {
          id: "sponsorThumbnail",
          name: "sponsorThumbnail",
          type: "dropzone",
          label: "Photos",
          styleType: "simple",
          max: 1,
        },
        profession: {
          id: "profession",
          name: "profession",
          type: "text",
          required: false,
          label: "Profession",
        },
        vat: {
          id: "vat",
          name: "vat",
          type: "text",
          required: false,
          label: "V.A.T Number",
        },
        address1: {
          id: "address1",
          name: "address1",
          type: "text",
          required: false,
          label: "Address 1",
        },
        address2: {
          id: "address2",
          name: "address2",
          type: "text",
          required: false,
          label: "Address 2",
        },
        city: {
          id: "city",
          name: "city",
          type: "text",
          required: false,
          label: "City",
        },
        state: {
          id: "state",
          name: "state",
          type: "text",
          required: false,
          label: "State",
        },
        notes: {
          id: "notes",
          name: "notes",
          type: "textarea",
          required: false,
          label: "Notes",
        },
      },
      persons: {
        firstName: {
          id: "firstName",
          name: "firstName",
          type: "text",
          required: true,
          label: "First Name",
        },
        lastName: {
          id: "lastName",
          name: "lastName",
          type: "text",
          required: true,
          label: "Last Name",
        },
        email: {
          id: "email",
          name: "email",
          type: "email",
          required: true,
          label: "Email",
        },
        phoneNumber: {
          id: "phoneNumber",
          name: "phoneNumber",
          type: "text",
          required: true,
          label: "Phone Number",
        },
        notes: {
          id: "notes",
          name: "notes",
          type: "textarea",
          label: "Notes",
        },
      },
    },
    clientPanel: {
      callToAction: {
        name: {
          id: "name",
          name: "name",
          type: "text",
          label: "Text",
          required: true,
        },
        url: {
          id: "url",
          name: "url",
          type: "text",
          label: "Action URL",
        },
      },
      sponsorsTypes: {
        name: {
          id: "name",
          name: "name",
          type: "text",
          label: "Name",
          required: true,
        },
      },
      homeSponsors: {
        url: {
          id: "url",
          name: "url",
          type: "text",
          label: "Action URL",
        },
        clientHomeSponsorImage: {
          required: true,
          id: "clientHomeSponsorImage",
          name: "clientHomeSponsorImage",
          type: "dropzone",
          label: "Image",
          styleType: "simple",
          max: 1,
        },
      },
      sponsors: {
        eventClientPanelSponsorTypeId: {
          id: "eventClientPanelSponsorTypeId",
          name: "eventClientPanelSponsorTypeId",
          type: "select",
          required: true,
          label: "Sponsor Type",
          options: {},
        },
        url: {
          id: "url",
          name: "url",
          type: "text",
          label: "Action URL",
        },
        clientSponsorImage: {
          id: "clientSponsorImage",
          name: "clientSponsorImage",
          type: "dropzone",
          label: "Image",
          styleType: "simple",
          max: 1,
        },
      },
      ebag: {
        url: {
          id: "url",
          name: "url",
          type: "text",
          label: "Action URL",
        },
        clientEbagImage: {
          id: "clientEbagImage",
          name: "clientEbagImage",
          type: "dropzone",
          label: "Image",
          styleType: "simple",
          max: 1,
          required: true,
        },
        clientEbagBrochure: {
          id: "clientEbagBrochure",
          name: "clientEbagBrochure",
          type: "dropzone",
          label: "Brochure",
          styleType: "simple",
          max: 1,
          accept:
            "image/gif,image/png,image/jpeg,image/pjpeg,image/bmp,image/x-windows-bmp,image/vnd.wap.wbmptext/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
        },
      },
      videoSponsors: {
        url: {
          id: "url",
          name: "url",
          type: "text",
          label: "Action URL",
        },
        clientVideoSponsorImage: {
          id: "clientVideoSponsorImage",
          name: "clientVideoSponsorImage",
          type: "dropzone",
          label: "Image",
          styleType: "simple",
          max: 1,
          required: true,
        },
      },
      footerSponsors: {
        title: {
          id: "title",
          name: "title",
          type: "text",
          label: "Title",
        },
        description: {
          id: "description",
          name: "description",
          type: "textarea",
          label: "Description",
        },
        url: {
          id: "url",
          name: "url",
          type: "text",
          label: "Action URL",
        },
        clientFooterSponsorImage: {
          id: "clientFooterSponsorImage",
          name: "clientFooterSponsorImage",
          type: "dropzone",
          label: "Image",
          styleType: "simple",
          max: 1,
        },
      },
    },
    exhibitors: {
      bucket: {
        exhibitorsBucket: {
          subtitle:
            "Note that deleting a file will permanently delete a file and saving will upload the files to your bucket.",
          name: "exhibitorsBucket",
          type: "dropzone",
          styleType: "simple",
          max: 20,
          id: "exhibitorsBucket",
          label: "Exhibitor's Bucket",
          accept:
            "application/pdf, video/mp4,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/gif,image/png,image/jpeg,image/pjpeg,image/bmp,image/x-windows-bmp,image/vnd.wap.wbmptext/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
        },
      },
    },
    abstracts: {
      edit: {
        status: {
          name: "status",
          type: "select",
          id: "status",
          required: true,
          placeholder: "Select",
          label: "Abstract Status",
          options: {
            awaiting_resubmission: "Awaiting Resubmission",
            draft: "Draft",
            submitted: "Submitted",
          },
        },
        published: {
          id: "published",
          name: "published",
          type: "switch",
          label: "Published",
        },
        publishedId: {
          id: "publishedId",
          name: "publishedId",
          type: "text",
          label: "Published Id",
        },
        publishedAbstractThumbnail: {
          name: "publishedAbstractThumbnail",
          type: "dropzone",
          styleType: "simple",
          max: 1,
          id: "publishedAbstractThumbnail",
          label: "Abstract Thumbnail",
        },
        publishedAbstractFile: {
          name: "publishedAbstractFile",
          type: "dropzone",
          styleType: "simple",
          max: 1,
          id: "publishedAbstractFile",
          label: "Abstract File",
          accept:
            "application/pdf, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/gif, image/png, image/jpeg",
        },
      },
      published: {
        publishedAbstractId: {
          name: "publishedAbstractId",
          type: "text",
          id: "publishedAbstractId",
          label: "Published Id",
          required: true,
        },
        publishedAbstractThumbnail: {
          name: "publishedAbstractThumbnail",
          type: "dropzone",
          styleType: "simple",
          max: 1,
          id: "publishedAbstractThumbnail",
          label: "Abstract Thumbnail",
        },
        name: {
          name: "name",
          type: "textarea",
          id: "name",
          label: "Title",
          required: true,
        },
        type: {
          name: "type",
          type: "text",
          id: "type",
          label: "Type",
        },
        authors: {
          name: "authors",
          type: "textarea",
          id: "authors",
          label: "Authors",
        },
        abstractText: {
          name: "abstractText",
          type: "textarea",
          id: "abstractText",
          label: "Abstract Text",
        },
        url: {
          name: "url",
          type: "text",
          id: "url",
          label: "Action Url",
        },
        publishedAbstractFile: {
          name: "publishedAbstractFile",
          type: "dropzone",
          styleType: "simple",
          max: 1,
          id: "publishedAbstractFile",
          label: "Abstract File",
          accept:
            "application/pdf, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/gif, image/png, image/jpeg",
        },
      },
    },
    events: {
      basicInfo: {
        duration: {
          id: "duration",
          type: "range",
          class: "col-sm-6",
          name: "duration",
          label: "",
          hasTime: true,
          required: true,
          autoTime: false,
          from: {
            name: "startDate",
            required: true,
            className: "form-container-label",
            label: "Start Date",
            value: new Date(),
          },
          to: {
            name: "endDate",
            className: "form-container-label",
            required: true,
            label: "End Date",
            value: null,
          },
          fromTime: {
            name: "durationStartTime",
            className: "form-container-label",
            required: true,
            label: "Start Time",
          },
          toTime: {
            name: "durationEndTime",
            className: "form-container-label",
            required: true,
            label: "End Time",
          },
        },
        eventSupportInfo: {
          id: "eventSupportInfo",
          name: "eventSupportInfo",
          type: "group",
          className: "col-xs-12 col-sm-6",
          fields: {
            supportEmail: {
              name: "supportEmail",
              type: "email",
              inputClass: "",
              id: "supportEmail",
              placeholder: "Please type the support email of the event",
              label: "Support Email",
            },
            supportPhoneNumber: {
              name: "supportPhoneNumber",
              type: "text",
              inputClass: "",
              id: "supportPhoneNumber",
              placeholder: "Please type the support number of the event",
              label: "Support Phone Number",
            },
          },
        },

        supportContactInfo: {
          name: "supportContactInfo",
          type: "textarea",
          inputClass: "",
          id: "supportContactInfo",
          placeholder: "Please type the support contact info of the event",
          label: "Other contact info",
        },
      },
      basicInfoCreate: {
        name: {
          name: "name",
          type: "text",
          required: true,
          inputClass: "",
          id: "name",
          placeholder: "Please type the title of the event",
          label: "Name",
        },
        location: {
          name: "location",
          type: "text",
          inputClass: "",
          id: "location",
          placeholder: "Please type the location of the event",
          label: "Location",
        },
        timezone: {
          name: "timezone",
          type: "select",
          isSearchable: true,
          inputClass: "",
          id: "timezone",
          placeholder: "Please select the event timezone",
          label: "Event's Timezone",
          required: true,
          locateMe: () => {},
        },
        supportEmail: {
          name: "supportEmail",
          type: "email",
          inputClass: "",
          id: "supportEmail",
          required: true,
          placeholder: "Please type the support email of the event",
          label: "Support Email",
        },
        urlAlias: {
          name: "urlAlias",
          type: "text",
          inputClass: "",
          id: "urlAlias",
          placeholder: "Please type the url alias of the event",
          label: "Url Alias",
          subtitle:
            "If you don't provide a url alias, the platform will autogenerate one, based on the event id.",
        },
        duration: {
          id: "duration",
          type: "range",
          class: "col-sm-3",
          name: "duration",
          label: "Duration",
          hasTime: true,
          required: true,
          autoTime: false,
          from: {
            name: "startDate",
            required: true,
            label: "Start Date",
            value: new Date(),
          },
          to: {
            name: "endDate",
            required: true,
            label: "End Date",
            value: null,
          },
          fromTime: {
            name: "durationStartTime",
            required: true,
            label: "Start Time",
          },
          toTime: {
            name: "durationEndTime",
            required: true,
            label: "End Time",
          },
        },
      },
      clientPanel: {
        freeAccess: {
          id: "freeAccess",
          name: "freeAccess",
          type: "switch",
          label: "Free Access",
          className: "col-sm-6 col-md-4",
        },
        urlAlias: {
          name: "urlAlias",
          type: "text",
          id: "urlAlias",
          placeholder: "Please type the url alias of the event",
          label: "Url Alias",
          subtitle:
            "If you don't provide a url alias, the platform will autogenerate one, based on the event id.",
          className: "col-sm-6 col-md-4",
        },
        clientPanelLanguage: {
          name: "clientPanelLanguage",
          type: "select",
          id: "clientPanelLanguage",
          placeholder: "Select",
          label: "Select a language for the client panel",
          options: {
            english: "English",
            greek: "Greek",
          },
          className: "col-sm-6 col-md-4",
        },
      },
      home: {
        homeShowNote: {
          id: "homeShowNote",
          name: "homeShowNote",
          type: "switch",
          label: "Show Note",
        },
        homeShowKeySpeakers: {
          id: "homeShowKeySpeakers",
          name: "homeShowKeySpeakers",
          type: "switch",
          label: "Show Key Speakers",
        },
        homeShowPromoVideo: {
          id: "homeShowPromoVideo",
          name: "homeShowPromoVideo",
          type: "switch",
          label: "Show Promo Video",
        },
        homePromoVideoUrl: {
          id: "homePromoVideoUrl",
          name: "homePromoVideoUrl",
          type: "text",
          label: "Promo Video Url",
          placeholder: "Enter the promo video url",
          condition: [
            {
              key: "homeShowPromoVideo",
              value: 1,
              expression: "===",
            },
          ],
        },
        homeShowSubscriptions: {
          id: "homeShowSubscriptions",
          name: "homeShowSubscriptions",
          type: "switch",
          label: "Show Pricing",
        },
        homeShowSponsors: {
          id: "homeShowSponsors",
          name: "homeShowSponsors",
          type: "switch",
          label: "Show Sponsors",
        },
      },
      options: {
        abstractManagement: {
          id: "abstractManagement",
          name: "abstractManagement",
          type: "switch",
          label: "Abstracts Management",
        },
        hotelManagement: {
          id: "hotelManagement",
          name: "hotelManagement",
          type: "switch",
          label: "Accommodation Management",
        },

        timeManagement: {
          id: "timeManagement",
          name: "timeManagement",
          type: "switch",
          label: "CEs (Εducational Credits)",
        },
        minutes: {
          id: "minutes",
          name: "minutes",
          type: "number",
          min: 1,
          label: "Minutes required for certification",
          condition: [
            {
              key: "timeManagement",
              value: 1,
              expression: "===",
            },
          ],
          path: "settings/time",
        },
        points: {
          id: "points",
          name: "points",
          type: "number",
          min: 1,
          label: "Points provided",
          condition: [
            {
              key: "timeManagement",
              value: 1,
              expression: "===",
            },
          ],
          path: "settings/time",
        },
        proportional: {
          id: "proportional",
          name: "proportional",
          type: "switch",
          label: "Proportional Point Calculation",
          condition: [
            {
              key: "timeManagement",
              value: 1,
              expression: "===",
            },
          ],
          path: "settings/time",
        },
        minMinutes: {
          id: "minMinutes",
          name: "minMinutes",
          type: "number",
          min: 1,
          label: "Minutes required for limited certification",
          subtitle:
            "Minimum minutes for certification with limited points. * After those minutes the points will be calculated proportionally until the participant reaches the minimum minutes for full points certification",
          condition: [
            {
              key: "timeManagement",
              value: 1,
              expression: "===",
            },
            {
              key: "proportional",
              value: 1,
              expression: "===",
            },
          ],
          path: "settings/time",
        },
        minPoints: {
          id: "minPoints",
          name: "minPoints",
          type: "number",
          min: 1,
          label: "Minimum points provided",
          condition: [
            {
              key: "timeManagement",
              value: 1,
              expression: "===",
            },
            {
              key: "proportional",
              value: 1,
              expression: "===",
            },
          ],
          path: "settings/time",
        },
      },
      administratorEmail: {
        emailHeader: {
          id: "emailHeader",
          name: "emailHeader",
          type: "editor",
          label: "Email Header",
          placeholder: "Enter the email's Header",
        },
        emailFooter: {
          id: "emailFooter",
          name: "emailFooter",
          type: "editor",
          label: "Email Footer",
          placeholder: "Enter the email's Footer",
        },
        emailSignature: {
          id: "emailSignature",
          name: "emailSignature",
          type: "editor",
          label: "Email Signature",
          placeholder: "Enter the email's Signature",
        },
      },
      emailTemplates: {
        registrationEmail: {
          id: "registrationEmail",
          name: "registrationEmail",
          type: "editor",
          label: "Registration Email",
          placeholder:
            "Type the content of the email. Use *|firstName|*, *|lastName|* for dynamic injection.",
        },
      },
      payments: {
        currencyId: {
          id: "currencyId",
          name: "currencyId",
          type: "select",
          label: "Currency",
          options: {
            1: "Euro / €",
            // 2: "US Dollar / $",
            // 3: "Great Britain Pound / £"
          },
        },
        bank: {
          id: "bank",
          name: "bank",
          type: "switch",
          label: "Bank Transfer",
        },
        bankOptions: {
          name: "bankOptions",
          type: "select",
          id: "bankOptions",
          required: false,
          multi: true,
          placeholder: "Select",
          label: "Bank supported payments",
          options: {},
          condition: [
            {
              key: "bank",
              value: 1,
              expression: "===",
            },
          ],
        },
        iban: {
          id: "iban",
          name: "iban",
          type: "iban",
          label: "IBAN",
          required: true,
          condition: [
            {
              key: "bank",
              value: 1,
              expression: "===",
            },
          ],
        },
        bankInfo: {
          id: "bankInfo",
          name: "bankInfo",
          type: "textarea",
          label: "Bank Info",
          condition: [
            {
              key: "bank",
              value: 1,
              expression: "===",
            },
          ],
        },
        depositExpirationDays: {
          id: "depositExpirationDays",
          name: "depositExpirationDays",
          type: "number",
          label: "Bank transfer expiration in days",
          condition: [
            {
              key: "bank",
              value: 1,
              expression: "===",
            },
          ],
        },
        creditCard: {
          id: "creditCard",
          name: "creditCard",
          type: "switch",
          label: "Credit/Debit Card",
        },
        collaborator: {
          name: "collaborator",
          type: "select",
          id: "collaborator",
          required: false,
          placeholder: "Select",
          label: "Credit/Debit Provider",
          options: {},
          condition: [
            {
              key: "creditCard",
              value: 1,
              expression: "===",
            },
          ],
        },
        creditCardInfo: {
          id: "creditCardInfo",
          name: "creditCardInfo",
          type: "textarea",
          label: "Credit/Debit Card Info",
          condition: [
            {
              key: "creditCard",
              value: 1,
              expression: "===",
            },
          ],
        },
        paypal: {
          id: "paypal",
          name: "paypal",
          type: "switch",
          label: "Pay Pal",
        },
        paypalAccount: {
          name: "paypalAccount",
          type: "select",
          id: "paypalAccount",
          required: false,
          placeholder: "Select",
          label: "Paypal Account",
          options: {},
          condition: [
            {
              key: "paypal",
              value: 1,
              expression: "===",
            },
          ],
        },
        cardOptions: {
          name: "cardOptions",
          type: "select",
          id: "cardOptions",
          required: false,
          multi: true,
          placeholder: "Select",
          label: "Credit/Debit Card or Paypal supported payments",
          options: {},
          condition: [
            {
              key: "creditCard",
              value: 1,
              expression: "===",
              operator: "or",
            },
            {
              key: "paypal",
              value: 1,
              expression: "===",
            },
          ],
        },
        cancellationPolicy: {
          id: "cancellationPolicy",
          name: "cancellationPolicy",
          type: "textarea",
          label: "Cancellation Policy",
        },
      },
      subscriptions: {
        subscription: {
          id: "subscription",
          name: "subscription",
          type: "subscription",
          label: "Subscription",
          subtypesOptions: {},
          subperiodsOptions: {},
          required: true,
        },
        price: {
          id: "price",
          name: "price",
          type: "number",
          label: "Price",
          required: true,
          placeholder: "Please type the price of the subscription",
        },
      },
      subscriptionTypes: {
        name: {
          id: "name",
          name: "name",
          label: "Type Name",
          type: "text",
          required: true,
          placeholder: "Please type the name of the subscription type",
        },
      },
      subscriptionPeriods: {
        name: {
          id: "name",
          name: "name",
          type: "text",
          label: "Period Name",
          required: true,
          placeholder: "Please type the name of the subscription period",
        },
        datetimeRange: {
          id: "datetimeRange",
          name: "datetimeRange",
          type: "range",
          required: true,
          from: {
            name: "startDate",
            required: true,
            label: "Start Date",
          },
          to: {
            name: "endDate",
            required: true,
            label: "End Date",
          },
        },
      },
    },
    accesspolicies: {
      newpolicy: {
        name: {
          name: "name",
          type: "text",
          required: true,
          inputClass: "",
          id: "name",
          placeholder: "Please type the name of the policy",
          label: "Name",
        },
        default: {
          name: "default",
          type: "switch",
          id: "default",
          placeholder: "",
          label: "Primary way",
        },
        type: {
          name: "type",
          type: "select",
          required: true,
          placeholder: "Please select",
          label: "Type",
          options: {
            public: "Public",
            private: "Private",
            crm: "Crm",
          },
        },
        onlineRegistration: {
          name: "onlineRegistration",
          type: "switch",
          id: "onlineRegistration",
          label: "Online Registration",
          condition: [
            {
              key: "type",
              value: "public",
              expression: "!==",
            },
          ],
        },
        mandatoryPayment: {
          name: "mandatoryPayment",
          type: "switch",
          id: "mandatoryPayment",
          label: "Mandatory Payment for Registration",
          condition: [
            {
              key: "type",
              value: "public",
              expression: "!==",
            },
          ],
        },
        maxUsers: {
          id: "maxUsers",
          name: "maxUsers",
          type: "number",
          min: 1,
          label: "Maximum allowed number of users",
          placeholder: "Please type the number of users",
        },
        concurrentConnectionsLimit: {
          id: "concurrentConnectionsLimit",
          name: "concurrentConnectionsLimit",
          type: "switch",
          min: 1,
          label: "Disable client panel concurrent connections",
          condition: [
            {
              key: "type",
              value: "public",
              expression: "!==",
            },
          ],
        },
        loginInfo: {
          id: "loginInfo",
          name: "loginInfo",
          type: "textarea",
          label: "Login Info",
          placeholder: "Leave empty for no login info",
        },
        registrationInfo: {
          id: "registrationInfo",
          name: "registrationInfo",
          type: "textarea",
          label: "Registration Info",
          placeholder: "Leave empty for no registration info",
        },
        disclaimer: {
          id: "disclaimer",
          name: "disclaimer",
          type: "textarea",
          label: "Disclaimer",
          placeholder: "Leave empty for no disclaimer",
        },
      },
      editpolicy: {
        name: {
          name: "name",
          type: "text",
          required: true,
          inputClass: "",
          id: "name",
          placeholder: "Please type the name of the policy",
          label: "Name",
        },
        default: {
          name: "default",
          type: "switch",
          id: "default",
          placeholder: "",
          label: "Primary way",
        },
        type: {
          name: "type",
          type: "select",
          required: true,
          disabled: true,
          placeholder: "Please select",
          label: "Type",
          options: {
            public: "Public",
            private: "Private",
            crm: "Crm",
          },
        },
        onlineRegistration: {
          name: "onlineRegistration",
          type: "switch",
          id: "onlineRegistration",
          label: "Online Registration",
          condition: [
            {
              key: "type",
              value: "public",
              expression: "!==",
            },
          ],
        },
        mandatoryPayment: {
          name: "mandatoryPayment",
          type: "switch",
          id: "mandatoryPayment",
          label: "Mandatory Payment for Registration",
          condition: [
            {
              key: "type",
              value: "public",
              expression: "!==",
            },
          ],
        },
        maxUsers: {
          id: "maxUsers",
          name: "maxUsers",
          type: "number",
          min: 1,
          label: "Maximum allowed number of users",
          placeholder: "Please type the number of users",
        },
        concurrentConnectionsLimit: {
          id: "concurrentConnectionsLimit",
          name: "concurrentConnectionsLimit",
          type: "switch",
          min: 1,
          label: "Disable client panel concurrent connections",
          condition: [
            {
              key: "type",
              value: "public",
              expression: "!==",
            },
          ],
        },
        loginInfo: {
          id: "loginInfo",
          name: "loginInfo",
          type: "textarea",
          label: "Login Info",
          placeholder: "Leave empty for no login info",
        },
        registrationInfo: {
          id: "registrationInfo",
          name: "registrationInfo",
          type: "textarea",
          label: "Registration Info",
          placeholder: "Leave empty for no registration info",
        },
        disclaimer: {
          id: "disclaimer",
          name: "disclaimer",
          type: "textarea",
          label: "Disclaimer",
          placeholder: "Leave empty for no disclaimer",
        },
      },
      createuser: {},
    },
    datasetEntries: {
      single: {},
    },
    invoices: {
      single: {
        status: {
          id: "status",
          name: "status",
          type: "select",
          label: "Status",
          required: true,
          options: {
            pending: "Pending",
            issued: "Issued",
            canceled: "Canceled",
          },
        },
        issuedAt: {
          id: "issuedAt",
          name: "issuedAt",
          type: "datetime",
          hasTime: true,
          label: "Issued At",
          required: true,
          condition: [
            {
              key: "status",
              value: "issued",
              expression: "===",
            },
          ],
        },
        invoiceNumber: {
          id: "invoiceNumber",
          name: "invoiceNumber",
          type: "text",
          label: "Invoice Number",
          required: true,
          condition: [
            {
              key: "status",
              value: "issued",
              expression: "===",
            },
          ],
        },
        invoiceUrl: {
          id: "invoiceUrl",
          name: "invoiceUrl",
          type: "text",
          label: "Invoice Url",
          required: true,
          condition: [
            {
              key: "status",
              value: "issued",
              expression: "===",
            },
          ],
        },
        vat: {
          id: "vat",
          name: "vat",
          type: "text",
          label: "VAT",
          required: true,
        },
        city: {
          id: "city",
          name: "city",
          type: "text",
          label: "City",
          required: true,
        },
        taxOffice: {
          id: "taxOffice",
          name: "taxOffice",
          type: "text",
          label: "Tax Office",
          required: true,
        },
        name: {
          id: "name",
          name: "name",
          type: "text",
          label: "Name",
          required: true,
        },
        profession: {
          id: "profession",
          name: "profession",
          type: "text",
          label: "Profession",
          required: true,
        },
        street: {
          id: "street",
          name: "street",
          type: "text",
          label: "Street",
          required: true,
        },
        streetNumber: {
          id: "streetNumber",
          name: "streetNumber",
          type: "text",
          label: "Street Number",
          required: true,
        },
        state: {
          id: "state",
          name: "state",
          type: "text",
          label: "State",
          required: true,
        },
        postalCode: {
          id: "postalCode",
          name: "postalCode",
          type: "text",
          label: "Postal Code",
          required: true,
        },
        notes: {
          id: "notes",
          name: "notes",
          type: "textarea",
          label: "Notes",
          required: false,
        },
      },
    },

    products: {
      single: {
        name: {
          name: "name",
          type: "text",
          id: "name",
          required: true,
          label: "Name",
        },
        active: {
          name: "active",
          type: "switch",
          id: "active",
          label: "Active",
        },

        eventSessionId: {
          id: "eventSessionId",
          name: "eventSessionId",
          label: "Event session Id",
          condition: [
            {
              key: "type",
              value: "participation",
              expression: "===",
            },
          ],
          type: "select",
          options: {},
        },
        description: {
          id: "description",
          name: "description",
          label: "Product description",
          type: "textarea",
        },
        quantity: {
          id: "quantity",
          name: "quantity",
          label: "Quantity",
          type: "number",
          required: true,
        },
        maxQuantityPerUser: {
          id: "maxQuantityPerUser",
          name: "maxQuantityPerUser",
          label: "Max quantity per user",
          type: "number",
        },
        availableOnlineQuantity: {
          id: "availableOnlineQuantity",
          name: "availableOnlineQuantity",
          label: "Available online quantity",
          type: "number",
        },
        buyingPrice: {
          id: "buyingPrice",
          name: "buyingPrice",
          label: "Buying price",
          type: "number",
          required: true,
        },
      },
      prices: {
        name: {
          name: "name",
          type: "text",
          id: "name",
          required: true,
          label: "Name",
        },
        eventPolicyGroupId: {
          name: "eventPolicyGroupId",
          type: "select",
          id: "eventPolicyGroupId",
          disabled: false,
          placeholder: "All",
          label: "Event Policy Group",
          isSearchable: true,
          subtitle: "Leave empty to target all users",
          options: {},
        },
        minQuantity: {
          name: "minQuantity",
          type: "number",
          id: "minQuantity",
          required: true,
          label: "Minimum Quantity",
        },
        sellingPrice: {
          name: "sellingPrice",
          type: "number",
          id: "sellingPrice",
          required: true,
          label: "Selling Price",
        },
        availableOnline: {
          name: "availableOnline",
          type: "switch",
          id: "availableOnline",
          required: false,
          label: "Available Online",
        },
        active: {
          name: "active",
          type: "switch",
          id: "active",
          required: false,
          label: "Active",
        },
      },
      data: {
        quantity: {
          name: "quantity",
          type: "number",
          id: "quantity",
          required: true,
          label: "Quantity",
        },
        eventProductPriceId: {
          name: "eventProductPriceId",
          type: "select",
          id: "eventProductPriceId",
          required: true,
          label: "Product Price Id",
          options: {},
        },
      },
    },
    rentables: {
      single: {
        name: {
          name: "name",
          type: "text",
          id: "name",
          required: true,
          label: "Name",
        },
        eventPolicyGroupId: {
          name: "eventPolicyGroupId",
          type: "select",
          id: "eventPolicyGroupId",
          disabled: false,
          placeholder: "All",
          label: "Event Policy Group",
          isSearchable: true,
          subtitle: "Leave empty to target all users",
          options: {},
        },
        description: {
          id: "description",
          name: "description",
          label: "Product description",
          type: "textarea",
        },
        quantity: {
          id: "quantity",
          name: "quantity",
          label: "Quantity",
          type: "number",
          required: true,
        },
      },

      data: {
        rentedAtDate: {
          id: "rentedAtDate",
          name: "rentedAtDate",
          type: "datetime",
          required: true,
          label: "Rented At",
        },
        returnedAtDate: {
          id: "returnedAtDate",
          name: "returnedAtDate",
          type: "datetime",
          label: "Returned At",
        },

        isLost: {
          id: "isLost",
          name: "isLost",
          type: "switch",
          required: false,
          label: "It is lost",
        },
        notes: {
          name: "notes",
          type: "textarea",
          inputClass: "",
          id: "notes",
          placeholder: "Type notes...",
          label: "Notes",
        },
      },
    },
    bankAccounts: {
      single: {
        collaborator: {
          id: "collaborator",
          name: "collaborator",
          type: "select",
          options: {
            "alpha-bank": "Alpha Bank",
            "piraeus-bank": "Piraeus Bank",
            paypal: "PayPal",
          },
          required: true,
          placeholder: "Please select a bank collaborator",
          label: "Bank Collaborator",
        },
        merchantId: {
          id: "merchantId",
          name: "merchantId",
          type: "text",
          required: true,
          placeholder: "Please type the Merchant Id of the account",
          label: "Merchant Id",
          condition: [
            {
              key: "collaborator",
              value: "paypal",
              expression: "===",
              operator: "or",
            },
            {
              key: "collaborator",
              value: "alpha-bank",
              expression: "===",
              operator: "or",
            },
            {
              key: "collaborator",
              value: "piraeus-bank",
              expression: "===",
              operator: "or",
            },
          ],
        },
        clientId: {
          id: "clientId",
          required: true,
          name: "clientId",
          type: "password",
          placeholder: "Please type the Client ID of the account",
          label: "Client ID",
          condition: [
            {
              key: "collaborator",
              value: "paypal",
              expression: "===",
            },
          ],
        },
        secret: {
          id: "secret",
          required: true,
          name: "secret",
          type: "password",
          placeholder: "Please type the Secret Shared Key of the account",
          label: "Secret Shared Key",
          condition: [
            {
              key: "collaborator",
              value: "alpha-bank",
              expression: "===",
            },
          ],
        },
        acquirerId: {
          id: "acquirerId",
          name: "acquirerId",
          required: true,
          type: "text",
          placeholder: "Please type the Acquirer Id of the account",
          label: "Acquirer Id",
          condition: [
            {
              key: "collaborator",
              value: "piraeus-bank",
              expression: "===",
            },
          ],
        },
        posId: {
          id: "posId",
          name: "posId",
          required: true,
          type: "text",
          placeholder: "Please type the Pos Id of the account",
          label: "POS Id",
          condition: [
            {
              key: "collaborator",
              value: "piraeus-bank",
              expression: "===",
            },
          ],
        },
        username: {
          id: "username",
          name: "username",
          required: true,
          type: "text",
          placeholder: "Please type the username of the account",
          label: "Username",
          condition: [
            {
              key: "collaborator",
              value: "piraeus-bank",
              expression: "===",
            },
          ],
        },
        password: {
          id: "password",
          name: "password",
          type: "password",
          placeholder: "Please type password of the account",
          subtitle: "Leave empty if you wish to stay the same",
          label: "Password (*)",
          condition: [
            {
              key: "collaborator",
              value: "piraeus-bank",
              expression: "===",
            },
          ],
        },
      },
    },
    sessions: {
      speakersChairpersons: {
        jobTitle: {
          name: "jobTitle",
          type: "text",
          inputClass: "",
          id: "jobTitle",
          placeholder: "Please type the job title",
          label: "Job Title",
        },
        institution: {
          name: "institution",
          type: "text",
          inputClass: "",
          id: "institution",
          placeholder: "Please type the institution",
          label: "Institution",
        },
        shortBio: {
          name: "shortBio",
          type: "textarea",
          inputClass: "",
          id: "shortBio",
          placeholder: "Please type the short bio",
          label: "Short Bio",
        },
        featured: {
          id: "featured",
          name: "featured",
          type: "switch",
          label: "Add to keynote speakers",
        },
        speakerThumbnail: {
          name: "speakerThumbnail",
          type: "dropzone",
          styleType: "simple",
          subtitle:
            "Please provide an image with ratio 1:1 at least 250px:250px",
          max: 1,
          label: "Photo",
        },
      },

      singleSession: {
        name: {
          name: "name",
          type: "textarea",
          required: true,
          inputClass: "",
          id: "name",
          placeholder: "Please type the title of the session",
          label: "Name",
        },
        description: {
          name: "description",
          type: "textarea",
          inputClass: "",
          id: "description",
          placeholder: "Please type the description of the session",
          label: "Description",
        },
        orgPersonId: {
          name: "orgPersonId",
          type: "speaker",
          inputClass: "",
          id: "orgPersonId",
          placeholder: "Please select the chairperson(s)",
          label: "Chairperson(s)",
          options: {},
        },
        eventRoomId: {
          name: "eventRoomId",
          type: "select",
          required: true,
          placeholder: "Please select a room",
          label: "Room",
          options: {},
        },
        datetimeRange: {
          id: "datetimeRange",
          name: "datetimeRange",
          type: "range",
          hasTime: true,
          required: true,
          from: {
            name: "startDate",
            required: true,
            label: "Start Date",
          },
          to: {
            name: "endDate",
            required: true,
            label: "End Date",
          },
          fromTime: {
            name: "startDateTime",
            required: true,
            label: "Start Time",
          },
          toTime: {
            name: "endDateTime",
            required: true,
            label: "End Time",
          },
        },
        enableVideos: {
          id: "enableVideos",
          name: "enableVideos",
          type: "switch",
          label: "Show video settings",
        },
        videoSessionThumbnail: {
          id: "videoSessionThumbnail",
          name: "videoSessionThumbnail",
          type: "dropzone",
          styleType: "simple",
          label: "Video Thumbnail",
          multiple: false,
          max: 1,
          accept:
            "image/gif,image/png,image/svg+xml,image/jpeg,image/pjpeg,image/tiff,image/webp,image/x-tiff,image/bmp,image/x-windows-bmp,image/vnd.wap.wbmp",
          condition: [
            {
              key: "enableVideos",
              value: 1,
              expression: "===",
            },
          ],
        },
        chatSection: {
          id: "chatSection",
          name: "chatSection",
          className: "col-sm-12",
          type: "group",
          condition: [
            {
              key: "enableVideos",
              value: 1,
              expression: "===",
            },
          ],
          fields: {
            chat: {
              id: "chat",
              className: "margin-bottom-15",
              name: "chat",
              type: "switch",
              label: "Chat Channel",
              condition: [
                {
                  key: "enableVideos",
                  value: 1,
                  expression: "===",
                },
              ],
            },
            chatCoordinator: {
              name: "chatCoordinator",
              type: "searchUser",
              inputClass: "",
              id: "chatCoordinator",
              placeholder: "Please select the coordinator(s)",
              subtitle: "Please type to search a chat coordinator user",
              label: "Chat Coordinator(s)",
              options: {},
              callback: () => {},
              value: [],
            },
          },
        },
        selfHosting: {
          id: "selfHosting",
          name: "selfHosting",
          type: "group",
          className: "col-sm-12",
          condition: [
            {
              key: "enableVideos",
              value: 1,
              expression: "===",
            },
          ],
          fields: {
            selfHostingVideo: {
              id: "selfHostingVideo",
              name: "selfHostingVideo",
              type: "switch",
              label: "Use Video Source",
              className: "margin-bottom-15",
            },
            videoUrl: {
              id: "videoUrl",
              name: "videoUrl",
              type: "text",
              label: "Video Url",
              placeholder: "Enter the video url",
              className: "margin-bottom-15",
              subtitle:
                "In case you would like to use a self hosted stream/video service, enter the appropriate url",
              condition: [
                {
                  key: "selfHostingVideo",
                  value: 1,
                  expression: "===",
                },
              ],
            },
            urlStream: {
              id: "urlStream",
              name: "urlStream",
              type: "text",
              label: "Broadcast url on error",
              placeholder: "Enter the stream url",
              subtitle:
                "In case you would like to use a self hosted stream/video service, we recommend you enter a backup url in case of stream/video failure",
            },
          },
        },
        thirdParty: {
          id: "thirdParty",
          name: "thirdParty",
          type: "group",
          className: "col-sm-12",
          condition: [
            {
              key: "enableVideos",
              value: 1,
              expression: "===",
            },
          ],
          fields: {
            thirdPartyHosting: {
              id: "thirdPartyHosting",
              className: "margin-bottom-15",
              name: "thirdPartyHosting",
              type: "switch",
              label: "Use Iframe Source",
            },
            srcUrl: {
              id: "srcUrl",
              name: "srcUrl",
              type: "text",
              label: "Source Url",
              placeholder: "Enter the source url",
              subtitle:
                "In case you would like to use a third party video hosting app, enter the embedded source url",
            },
          },
        },

        userQuestions: {
          id: "userQuestions",
          name: "userQuestions",
          type: "group",
          className: "col-sm-12",
          condition: [
            {
              key: "enableVideos",
              value: 1,
              expression: "===",
            },
          ],
          fields: {
            questions: {
              id: "questions",
              name: "questions",
              type: "switch",
              className: "margin-bottom-15",
              label: "User can ask questions",
            },
            questionsEmail: {
              id: "questionsEmail",
              name: "questionsEmail",
              type: "email",
              label: "Questions Email",
              placeholder: "Enter the email",
              subtitle:
                "In case you support questions, enter the email you would like to use to receive them",
            },
          },
        },
        enableVideoMinutes: {
          id: "enableVideoMinutes",
          name: "enableVideoMinutes",
          type: "switch",
          label: "Enable tracking video minutes",
          value: 1,
          condition: [
            {
              key: "enableVideos",
              value: 1,
              expression: "===",
            },
          ],
        },
        setCallToAction: {
          id: "setCallToAction",
          name: "setCallToAction",
          type: "group",
          className: "col-sm-12",
          condition: [
            {
              key: "enableVideos",
              value: 1,
              expression: "===",
            },
          ],
          fields: {
            callToAction: {
              className: "margin-bottom-15",
              id: "callToAction",
              name: "callToAction",
              type: "switch",
              label: "Call To Action",
            },
            callToActionTitle: {
              id: "callToActionTitle",
              className: "margin-bottom-15",
              name: "callToActionTitle",
              type: "text",
              label: "Call To Action Title",
              placeholder: "Enter the title",
            },
            callToActionDesc: {
              id: "callToActionDesc",
              className: "margin-bottom-15",
              name: "callToActionDesc",
              type: "textarea",
              label: "Call To Action Description",
              placeholder: "Enter the description",
            },
            callToActionButton: {
              id: "callToActionButton",
              className: "margin-bottom-15",
              name: "callToActionButton",
              type: "text",
              label: "Call To Action Button Text",
              placeholder: "Enter the button text",
            },
            callToActionUrl: {
              id: "callToActionUrl",
              name: "callToActionUrl",
              type: "text",
              label: "Call To Action URL",
              placeholder: "Enter the URL",
            },
          },
        },
        sessionFiles: {
          id: "sessionFiles",
          name: "sessionFiles",
          styleType: "simple",
          type: "dropzone",
          max: 2,
          label: "Slides",
          multiple: true,
          accept:
            "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      },
      singleSpeech: {
        name: {
          name: "name",
          type: "textarea",
          required: true,
          inputClass: "",
          id: "name",
          placeholder: "Please type the title of the speech",
          label: "Title",
        },
        eventUsersIds: {
          name: "eventUsersIds",
          type: "searchUser",
          multi: true,
          inputClass: "",
          id: "eventUsersIds",
          required: false,
          placeholder: "Type to search speaker(s)",
          subtitle: "Please select speaker(s)",
          label: "Speakers",
          filters: {
            callback: () => {},
            options: [],
          },
          options: {},
          callback: () => {},
          value: [],
          onChange: () => {},
        },
        firstName: {
          name: "firstName",
          type: "text",
          required: true,
          inputClass: "",
          id: "firstName",
          placeholder: "Speaker's first name",
          label: "First Name:",
          condition: [
            {
              key: "newSpeaker",
              value: 1,
              expression: "===",
            },
          ],
        },
        datetimeRange: {
          id: "datetimeRange",
          name: "datetimeRange",
          type: "range",
          hasTime: true,
          required: true,
          min: null,
          max: null,
          from: {
            name: "startDate",
            required: true,
            label: "Start Date",
          },
          to: {
            name: "endDate",
            required: true,
            label: "End Date",
          },
          fromTime: {
            name: "startDateTime",
            required: true,
            label: "Start Time",
          },
          toTime: {
            name: "endDateTime",
            required: true,
            label: "End Time",
          },
        },
        videoUrl: {
          id: "videoUrl",
          name: "videoUrl",
          type: "text",
          label: "Video Url",
          placeholder: "Enter the video url",
        },
        videoSpeechThumbnail: {
          id: "videoSpeechThumbnail",
          name: "videoSpeechThumbnail",
          type: "dropzone",
          styleType: "simple",
          label: "Video Thumbnail",
          multiple: false,
          max: 1,
          accept:
            "image/gif,image/png,image/svg+xml,image/jpeg,image/pjpeg,image/tiff,image/webp,image/x-tiff,image/bmp,image/x-windows-bmp,image/vnd.wap.wbmp",
        },
        speechFiles: {
          id: "speechFiles",
          name: "speechFiles",
          styleType: "simple",
          type: "dropzone",
          label: "Slides",
          multiple: true,
          accept:
            "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          max: 100,
          maxFileSize: 5,
        },
      },
      abstractSpeech: {
        name: {
          name: "name",
          type: "textarea",
          required: true,
          inputClass: "",
          id: "name",
          placeholder: "Please type the title of the speech",
          label: "Title",
        },
        presenter: {
          name: "presenter",
          type: "searchUser",
          disabled: true,
          inputClass: "",
          id: "presenter",
          placeholder: "Please select the presenter",
          label: "Presenter",
          options: {},
          callback: () => {},
          value: [],
        },
        datetimeRange: {
          id: "datetimeRange",
          name: "datetimeRange",
          type: "range",
          hasTime: true,
          required: true,
          min: null,
          max: null,
          from: {
            name: "startDate",
            required: true,
            label: "Start Date",
          },
          to: {
            name: "endDate",
            required: true,
            label: "End Date",
          },
          fromTime: {
            name: "startDateTime",
            required: true,
            label: "Start Time",
          },
          toTime: {
            name: "endDateTime",
            required: true,
            label: "End Time",
          },
        },
        videoUrl: {
          id: "videoUrl",
          name: "videoUrl",
          type: "text",
          label: "Video Url",
          placeholder: "Enter the video url",
        },
        videoSpeechThumbnail: {
          id: "videoSpeechThumbnail",
          name: "videoSpeechThumbnail",
          type: "dropzone",
          styleType: "simple",
          label: "Video Thumbnail",
          multiple: false,
          max: 1,
          accept:
            "image/gif,image/png,image/svg+xml,image/jpeg,image/pjpeg,image/tiff,image/webp,image/x-tiff,image/bmp,image/x-windows-bmp,image/vnd.wap.wbmp",
        },
        speechFiles: {
          id: "speechFiles",
          name: "speechFiles",
          styleType: "simple",
          type: "dropzone",
          label: "Slides",
          multiple: true,
          accept:
            "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          max: 100,
        },
      },
      scheduleSettings: {
        publishVideos: {
          name: "publishVideos",
          type: "switch",
          id: "publishVideos",
          label: "Publish/hide the video",
        },

        publishSchedule: {
          id: "publishSchedule",
          name: "publishSchedule",
          type: "switch",
          label: "Publish/hide the schedule",
        },

        publishSpeakers: {
          id: "publishSpeakers",
          name: "publishSpeakers",
          type: "switch",
          label: "Publish/hide the speakers",
        },

        scheduleFile: {
          id: "scheduleFile",
          name: "scheduleFile",
          styleType: "simple",
          type: "dropzone",
          label: "Schedule in pdf",
          accept:
            "image,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          max: 1,
        },
      },
    },
    staff: {
      single: {
        firstName: {
          name: "firstName",
          type: "text",
          required: true,
          inputClass: "",
          id: "firstName",
          placeholder: "First Name",
          label: "First Name",
        },
        lastName: {
          name: "lastName",
          type: "text",
          required: true,
          inputClass: "",
          id: "lastName",
          placeholder: "Last Name",
          label: "Last Name",
        },
        email: {
          id: "email",
          name: "email",
          type: "email",
          label: "Email",
          required: true,
          placeholder: "Enter the person's email",
        },
        phoneNumber: {
          name: "phoneNumber",
          type: "text",
          inputClass: "",
          id: "phoneNumber",
          placeholder: "Phone Number",
          label: "Phone Number",
        },
        accessCrm: {
          name: "accessCrm",
          type: "switch",
          id: "accessCrm",
          label: "Access CRM",
        },
      },
    },
    persons: {
      single: {
        firstName: {
          name: "firstName",
          type: "text",
          required: true,
          inputClass: "",
          id: "firstName",
          placeholder: "First Name",
          label: "First Name:",
        },
        lastName: {
          name: "lastName",
          type: "text",
          required: true,
          inputClass: "",
          id: "firstName",
          placeholder: "Last Name",
          label: "Last Name:",
        },
        email: {
          id: "email",
          name: "email",
          type: "email",
          label: "Email",
          placeholder: "Enter the person's email",
        },
        company: {
          name: "company",
          type: "text",
          inputClass: "",
          id: "company",
          placeholder: "Company",
          label: "Company",
        },
        resume: {
          name: "resume",
          type: "textarea",
          id: "resume",
          placeholder: "Short Bio",
          label: "Short Bio",
        },
        personThumbnail: {
          name: "personThumbnail",
          type: "dropzone",
          styleType: "simple",
          max: 1,
          label: "Photo",
        },
      },
    },
    hotels: {
      singleEventHotelRoom: {
        datetimeRange: {
          id: "datetimeRange",
          name: "datetimeRange",
          type: "range",
          required: true,
          from: {
            name: "startDate",
            required: true,
            label: "Available from",
          },
          to: {
            name: "endDate",
            required: true,
            label: "Available to",
          },
        },
        quantity: {
          id: "quantity",
          required: true,
          name: "quantity",
          type: "number",
          label: "Number of available rooms",
        },
        buyingPrice: {
          id: "buyingPrice",
          required: true,
          name: "buyingPrice",
          type: "number",
          label: "Buying Price (per night)",
        },
        sellingPrice: {
          id: "sellingPrice",
          required: true,
          name: "sellingPrice",
          type: "number",
          label: "Selling Price (per night)",
        },
        active: {
          name: "active",
          type: "switch",
          id: "active",
          required: false,
          label: "Available Online",
        },
        notes: {
          name: "notes",
          type: "textarea",
          inputClass: "",
          id: "notes",
          placeholder: "Type notes...",
          label: "Notes",
        },
      },
      singleHotelRoom: {
        name: {
          name: "name",
          type: "text",
          inputClass: "",
          id: "name",
          placeholder: "Hotel's room name",
          label: "Name",
          required: true,
        },
        datetimeRange: {
          id: "datetimeRange",
          name: "datetimeRange",
          type: "range",
          required: true,
          from: {
            name: "startDate",
            required: true,
            label: "Available from",
          },
          to: {
            name: "endDate",
            required: true,
            label: "Available to",
          },
        },
        description: {
          name: "description",
          type: "textarea",
          inputClass: "",
          id: "description",
          placeholder: "Hotel's room description",
          label: "Description",
        },
        facilities: {
          id: "facilities",
          name: "facilities",
          type: "textarea",
          label: "Facilities",
        },
        capacity: {
          id: "capacity",
          name: "capacity",
          type: "number",
          required: true,
          label: "Capacity",
        },
        quantity: {
          id: "quantity",
          required: true,
          name: "quantity",
          type: "number",
          label: "Number of available rooms",
        },
        buyingPrice: {
          id: "buyingPrice",
          required: true,
          name: "buyingPrice",
          type: "number",
          label: "Buying Price (per night)",
        },
        sellingPrice: {
          required: true,
          id: "sellingPrice",
          name: "sellingPrice",
          type: "number",
          label: "Selling Price (per night)",
        },
        active: {
          name: "active",
          type: "switch",
          id: "active",
          required: false,
          label: "Available Online",
        },
        hotelRoomImages: {
          id: "hotelRoomImages",
          name: "hotelRoomImages",
          type: "dropzone",
          label: "Photos",
          styleType: "simple",
          max: 5,
        },
        notes: {
          name: "notes",
          type: "textarea",
          inputClass: "",
          id: "notes",
          placeholder: "Type notes...",
          label: "Notes",
        },
      },
      singleRoom: {
        name: {
          name: "name",
          required: true,
          type: "text",
          inputClass: "",
          id: "name",
          placeholder: "Hotel's room name",
          label: "Name",
        },
        description: {
          name: "description",
          type: "textarea",
          inputClass: "",
          id: "description",
          placeholder: "Hotel's room description",
          label: "Description",
        },
        facilities: {
          id: "facilities",
          name: "facilities",
          type: "textarea",
          label: "Facilities",
        },
        capacity: {
          id: "capacity",
          required: true,
          name: "capacity",
          type: "number",
          label: "Capacity",
        },
        hotelRoomImages: {
          id: "hotelRoomImages",
          name: "hotelRoomImages",
          type: "dropzone",
          label: "Photos",
          styleType: "simple",
          max: 5,
        },
      },
      singleOrg: {
        address: {
          name: "address",
          type: "address",
          withMap: true,
          id: "address",
          placeholder: "Address",
          required: true,
        },
        rating: {
          name: "rating",
          type: "rating",
          label: "Star Rating",
          required: true,
        },
        description: {
          name: "description",
          type: "textarea",
          inputClass: "",
          id: "description",
          placeholder: "Hotel's Description",
          label: "Description",
        },
        facilities: {
          name: "facilities",
          type: "textarea",
          inputClass: "",
          id: "facilities",
          placeholder: "Hotel's facilities",
          label: "Facilities",
        },
        active: {
          id: "active",
          name: "active",
          type: "switch",
          label: "Active",
          value: 1,
        },
        hotelImages: {
          id: "hotelImages",
          name: "hotelImages",
          type: "dropzone",
          label: "Photos",
          styleType: "simple",
          max: 5,
        },
      },
      single: {
        address: {
          name: "address",
          type: "address",
          withMap: true,
          id: "address",
          placeholder: "Address",
          required: true,
        },
        rating: {
          name: "rating",
          type: "rating",
          label: "Star Rating",
          required: true,
        },
        description: {
          name: "description",
          type: "textarea",
          inputClass: "",
          id: "description",
          placeholder: "Hotel's Description",
          label: "Description",
        },
        facilities: {
          name: "facilities",
          type: "textarea",
          inputClass: "",
          id: "facilities",
          placeholder: "Hotel's facilities",
          label: "Facilities",
        },
        active: {
          id: "active",
          name: "active",
          type: "switch",
          label: "Available Online",
          value: 1,
        },
        hotelImages: {
          id: "hotelImages",
          name: "hotelImages",
          type: "dropzone",
          label: "Photos",
          styleType: "simple",
          max: 5,
        },
        notes: {
          id: "notes",
          name: "notes",
          type: "textarea",
          label: "Notes",
        },
      },
    },
    email: {
      edit: {
        name: {
          name: "name",
          type: "text",
          inputClass: "",
          id: "name",
          placeholder: "Name",
          label: "Name",
        },
      },
    },
    contacts: {
      single: {
        category: {
          id: "category",
          name: "category",
          type: "checkboxes",
          required: true,
          label: "",
          options: {
            admins: "Contact the Admins of the Event",
            groups: "Contact a Group of the Event",
            emails: "Contact specific Emails",
          },
          value: [],
        },
        adminRoles: {
          id: "adminRoles",
          name: "adminRoles",
          type: "select",
          required: true,
          label: "Please select an Admin Role",
          placeholder: "Please select",
          options: {
            organisation_admin: "Organisation Admin",
            event_admin: "Event Admin",
            event_judge: "Event Judge",
            any_role: "Any Role",
          },
          condition: [
            {
              key: "category",
              value: "admins",
              expression: "in",
            },
          ],
        },
        groupsCategory: {
          id: "groupsCategory",
          name: "groupsCategory",
          type: "select",
          required: true,
          label: "Please select one group",
          isSearchable: true,
          options: {},
          condition: [
            {
              key: "category",
              value: "groups",
              expression: "in",
            },
          ],
          value: [],
        },
        usersCategory: {
          id: "usersCategory",
          name: "usersCategory",
          type: "checkboxes",
          required: true,
          label: "Please select one or more categories to define Users",
          options: {
            speaker: "Contact the Speakers",
            chairperson: "Contact the Chairpersons",
            allPolicyUsers: "Contact all Policy Users",
          },
          condition: [
            {
              key: "category",
              value: "users",
              expression: "in",
            },
          ],
          value: [],
        },
        emailsValue: {
          id: "emailsValue",
          name: "emailsValue",
          type: "tagsInput",
          required: true,
          label: "Please enter specific Emails",
          placeholder: "Add emails",
          condition: [
            {
              key: "category",
              value: "emails",
              expression: "in",
            },
          ],
          value: [],
        },
      },
      double: {
        category: {
          id: "category",
          name: "category",
          type: "checkboxes",
          required: true,
          label: "",
          options: {
            groups: "Contact a Group of the Event",
          },
          value: [],
        },
        adminRoles: {
          id: "adminRoles",
          name: "adminRoles",
          type: "select",
          required: true,
          label: "Please select an Admin Role",
          placeholder: "Please select",
          options: {
            organisation_admin: "Organisation Admin",
            event_admin: "Event Admin",
            event_judge: "Event Judge",
            any_role: "Any Role",
          },
          condition: [
            {
              key: "category",
              value: "admins",
              expression: "in",
            },
          ],
        },
        groupsCategory: {
          id: "groupsCategory",
          name: "groupsCategory",
          type: "select",
          required: true,
          label: "Please select one group",
          options: {},
          condition: [
            {
              key: "category",
              value: "groups",
              expression: "in",
            },
          ],
          value: [],
        },
        usersCategory: {
          id: "usersCategory",
          name: "usersCategory",
          type: "checkboxes",
          required: true,
          label: "Please select one or more categories to define Users",
          options: {
            allPolicyUsers: "Contact all Policy Users",
          },
          condition: [
            {
              key: "category",
              value: "users",
              expression: "in",
            },
          ],
          value: [],
        },
        emailsValue: {
          id: "emailsValue",
          name: "emailsValue",
          type: "tagsInput",
          required: true,
          label: "Please enter specific Emails",
          placeholder: "Add emails",
          condition: [
            {
              key: "category",
              value: "emails",
              expression: "in",
            },
          ],
          value: [],
        },
      },
    },
  },
};

export default formFields;
