import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import Switch from "@layout/Switch";
import TableActions from "@layout/TableActions";
import SingleHotel from "./SingleHotel/SingleHotel";
import Rooms from "./Rooms/";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@layout/Select2";
import Confirm from "@layout/Confirm";

export default class Hotels extends React.Component {
  constructor(props) {
    super(props);
    this.columns = {
      id: {
        name: "ID",
      },
      name: {
        name: "Hotel name",
      },
      stars: {
        name: "Stars",
      },
      totalRooms: {
        name: "Room Reservations",
      },
      totalNights: {
        name: "Night Reservations",
      },
      setupRooms: {
        name: "Setup Rooms",
      },
      active: {
        name: props.mode === "orgHotels" ? "Status" : "Available online",
      },
      actions: {
        name: "",
      },
    };

    if (props.mode === "orgHotels") {
      delete this.columns["rooms"];
    }

    ["onRender", "customActions"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.fetched = false;
    this.state = {
      selectedHotelId: null,
    };
  }

  customActions(item) {
    if (this.props.mode === "orgHotels") return null;

    const unassignBody = (
      <div>
        <p>
          This action will unassign the <strong>{item.name}</strong> from this
          event.
        </p>
        <p>
          You can reassign it anytime you want, but the related event data will
          be lost.
        </p>
        <p>Are you sure that you want to do this?</p>
      </div>
    );

    return (
      <span className="confirm-holder">
        <Confirm
          onConfirm={() => this.props.unassignHotel(item.id)}
          body={unassignBody}
          className="delete-event"
          confirmText="Confirm Unassign"
          title="Unassign Action! Are you sure?"
          onShow={() => document.body.click()}
        >
          <Tooltip title="Unassign from event">
            <button type="button" className="btn circle">
              <span className="icon-all-out"></span>
            </button>
          </Tooltip>
        </Confirm>
      </span>
    );
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return (
          <div>
            <span className="icon-text-edit-bullets"></span>
          </div>
        );
      }
      case "id": {
        return <div>{item.id}</div>;
      }
      case "stars": {
        const html = [];
        for (let i = 1; i <= 6; i++) {
          if (i <= col.value) {
            html.push(
              <i
                key={`star-${item.id}-${i}`}
                className={`fa fa-star${" active"}`}
                aria-hidden="true"
              ></i>
            );
          }
        }
        return html;
      }

      case "rooms": {
        let quantity = 0;
        col.value.map((v) => (quantity += v.quantity));
        return quantity;
      }

      case "setupRooms": {
        return (
          <button
            className="btn transparent table-button"
            onClick={() => this.setState({ selectedHotelId: item.id })}
          >
            <span className="icon-text-edit-bullets"></span>
            Setup Room Types
          </button>
        );
      }

      case "active": {
        const { saveHotel, orgId, mode, reorderHotel } = this.props;
        return (
          <Switch
            id={`hotel-active-${item.id}`}
            isActive={col.value}
            isLive={true}
            onChange={(active) =>
              mode === "eventHotels"
                ? reorderHotel({ active }, item.id).then(() => {
                    this.props.addNotification(
                      "successfully updated hotel",
                      "success"
                    );
                    const { getHotels, eventId } = this.props;
                    getHotels(eventId);
                  })
                : saveHotel(
                    orgId,
                    { active },
                    item.orgHotelId || item.id,
                    false,
                    undefined,
                    undefined,
                    true
                  )
            }
          />
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="hotel"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SingleHotel
                  value={item}
                  orgMode={this.props.mode === "orgHotels"}
                  mode={"edit"}
                  eventHotelId={item.id}
                />
              )
            }
            onDelete={() =>
              this.props.deleteItem(
                this.props.orgId,
                this.props.mode === "eventHotels" ? item.orgHotelId : item.id,
                this.props.eventId
              )
            }
            custom={(toggled) => this.customActions(item, toggled)}
            delete={this.props.mode === "orgHotels"}
          />
        );
      }
    }
  }

  componentDidMount() {
    this.props.mode === "eventHotels"
      ? this.props.getHotels(this.props.eventId)
      : this.props.getOrgHotels(this.props.orgId);
  }

  render() {
    const { reorderHotel, mode } = this.props;
    const hotelItems = this.props.hotels;
    if (this.state.selectedHotelId) {
      return (
        <Rooms
          hotelId={this.state.selectedHotelId}
          {...this.props}
          resetSelectedHotelId={() => this.setState({ selectedHotelId: null })}
        />
      );
    }
    if (this.props.mode === "eventHotels") {
      if (hotelItems.length !== 0) {
        hotelItems.map((hotel) => {
          let totalCurrentNight = 0,
            totalAvailableNight = 0,
            totalAvailableRoom = 0,
            totalCurrentRoom = 0;
          if (hotel.rooms.length !== 0) {
            hotel.rooms.map((room) => {
              const roomRes = room.totalRoomReservations.split("/");
              const nightsRes = room.totalNightsReservations.split("/");
              const currentNightRes = parseInt(nightsRes[0], 10);
              const currentRoomRes = parseInt(roomRes[0], 10);
              const availableNightRes = parseInt(nightsRes[1], 10);
              const availableRoomRes = parseInt(roomRes[1], 10);
              totalAvailableNight += availableNightRes;
              totalAvailableRoom += availableRoomRes;
              totalCurrentRoom += currentRoomRes;
              totalCurrentNight += currentNightRes;
            });
          }
          const formattedNights =
            totalCurrentNight.toString() + "/" + totalAvailableNight.toString();
          const formattedRoom =
            totalCurrentRoom.toString() + "/" + totalAvailableRoom.toString();
          hotel["totalNights"] = formattedNights;
          hotel["totalRooms"] = formattedRoom;
        });
      }
    } else {
      delete this.columns.totalRooms;
      delete this.columns.totalNights;
    }
    const options = {
      user: "Export to Excel ( Per User )",
      hotel: "Export to Excel ( Per Hotel )",
    };
    return (
      <>
        <div className="tab-header">
          <div className="left">
            <Select
              selectedBackground="#f0f0f4"
              placeholder={[
                <div
                  key="placeholder-select-group"
                  className="select-button-placeholder"
                >
                  <span className="icon-more-options"></span>
                </div>,
              ]}
              isSearchable={false}
              className="more-actions select-button"
              isDropDownButton={true}
              isClearable={false}
              placeholderInsideSelect={false}
              options={options}
              onChange={(action) =>
                this.props.exportReservation(this.props.eventId, action)
              }
            />
          </div>
          <div className="right">
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SingleHotel
                    saveHotel={this.props.saveHotel}
                    orgMode={this.props.mode === "orgHotels"}
                  />
                )
              }
            >
              Create
            </button>
          </div>
        </div>
        <div className="tab-content">
          <Table
            id={"hotels"}
            columns={this.columns}
            items={hotelItems}
            sortable={mode === "eventHotels"}
            updating={this.props.fetching}
            onRender={this.onRender}
            containerClassName={`table-container `}
            onSortEnd={(index, item) => {
              reorderHotel({ index }, item.id).then((data) => {
                if (data.data === true) {
                  const { getHotels, getOrgHotels, eventId, mode, orgId } =
                    this.props;
                  mode === "eventHotels"
                    ? getHotels(eventId)
                    : getOrgHotels(orgId);
                }
              });
            }}
          />
        </div>
      </>
    );
  }
}

Hotels.propTypes = {
  getHotels: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  hotels: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  saveHotel: PropTypes.func.isRequired,
  reorderHotel: PropTypes.func.isRequired,
  orgId: PropTypes.number,
  deleteItem: PropTypes.func.isRequired,
  unassignHotel: PropTypes.func.isRequired,
  orgAdmin: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  getOrgHotels: PropTypes.func.isRequired,
  exportReservation: PropTypes.func,
  addNotification: PropTypes.func.isRequired,
};
