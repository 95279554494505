import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import KMBLoader from "@layout/KMBLoader";
import { connect } from "react-redux";
import TableActions from "@layout/TableActions";
import { getSponsorshipStatus, deleteSponsorship } from "@actions/sponsors";
import { exportSponsorships } from "@actions/payments";
import { HeaderPagination } from "@layout/Pagination";
import DataViewSponsorships from "./partials/DataviewSponsorships";
import FiltersSponsorships from "./partials/FiltersSponsorships";
import Select from "@layout/Select";

class Sponsorships extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
  }
  componentDidMount() {
    this.props.sponsorshipsStatus(this.props.eventId);
  }
  onRender(col, item) {
    switch (col.key) {
      case "amount": {
        return col.value.toFixed(2);
      }
      case "actions": {
        const meta = {
          rpp: this.props.sponsorshipsMeta.rpp,
          p: this.props.sponsorshipsMeta.p,
        };
        const newMeta = this.assignMeta(meta);
        return (
          <TableActions
            entity="session"
            item={item}
            edit={false}
            onDelete={() =>
              this.props.deleteSponsorship(this.props.eventId, item.id, newMeta)
            }
          />
        );
      }
    }
  }
  assignMeta(meta) {
    if (this.props.sponsorshipsMeta.type) {
      meta["type"] = this.props.sponsorshipsMeta.type;
    }
    if (this.props.sponsorshipsMeta.orgSponsorId) {
      meta["orgSponsorId"] = this.props.sponsorshipsMeta.orgSponsorId;
    }
    if (this.props.sponsorshipsMeta.eventUserId) {
      meta["eventUserId"] = this.props.sponsorshipsMeta.eventUserId;
    }
    return meta;
  }
  findIfDifferent(val, identifier) {
    let newVal = 0;
    if (identifier === "rpp") {
      if (this.props.sponsorshipsMeta.rpp !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.sponsorshipsMeta.rpp;
      }
    } else if (identifier === "p") {
      if (this.props.sponsorshipsMeta.p !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.sponsorshipsMeta.p;
      }
    } else if (identifier === "totalRows") {
      if (this.props.sponsorshipsMeta.totalRows !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.sponsorshipsMeta.totalRows;
      }
    }
    return newVal;
  }
  render() {
    const columns = {
      id: {
        name: "ID",
      },
      orgSponsorName: {
        name: "Company",
      },
      orgSponsorPersonFullName: {
        name: "Sponsor",
      },
      referenceId: {
        name: "Participant ID",
      },
      eventUserFullName: {
        name: "Participant",
      },
      email: {
        name: "Sponsor Email",
      },
      amount: {
        name: `Amount (${this.props.currency})`,
      },
      type: {
        name: "Type",
      },
      actions: {
        name: "",
      },
    };
    const filters = {};
    for (const m in this.props.meta) {
      if (m === "type" || m === "orgSponsorId" || m === "eventUserId") {
        filters[m] = this.props.meta[m];
      }
    }

    return (
      <>
        <div className="tab-header">
          <div className="left">
            <Select
              placeholder={"More Actions"}
              className="more-actions"
              isDropDownButton={true}
              placeholderInsideSelect={false}
              options={{ user: "Export to Excel" }}
              onChange={() =>
                this.props.exportSponsorships(this.props.eventId, filters)
              }
            />
            <FiltersSponsorships />
          </div>
          <div className="right">
            <HeaderPagination
              fetching={this.props.fetching}
              p={this.props.sponsorshipsMeta.p}
              rpp={this.props.sponsorshipsMeta.rpp}
              totalRows={this.props.sponsorshipsMeta.totalRows}
              onChangePage={(data) => {
                const meta = {
                  p: data.p,
                  rpp: data.rpp,
                };
                const newMeta = this.assignMeta(meta);
                this.props.sponsorshipsStatus(this.props.eventId, newMeta);
              }}
            />
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-users">
            <div className="row" style={{ paddingTop: 0 }}>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <DataViewSponsorships
                  data={this.props.sponsorshipsMeta.statisticData}
                  currency={this.props.currency}
                />
              </div>

              <div className="col-md-12">
                {this.props.fetching == true ? (
                  <KMBLoader rows={15} padding={24} height={53} />
                ) : (
                  <>
                    <Table
                      id={"sponsorships"}
                      columns={columns}
                      items={this.props.sponsorships}
                      onRender={this.onRender}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Sponsorships.propTypes = {
  updateEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  eventId: PropTypes.number,
  sponsorshipsStatus: PropTypes.func.isRequired,
  sponsorships: PropTypes.array.isRequired,
  deleteSponsorship: PropTypes.func.isRequired,
  sponsorshipsMeta: PropTypes.object,
  currency: PropTypes.string.isRequired,
  fetching: PropTypes.bool,
  exportSponsorships: PropTypes.func.isRequired,
  meta: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    sponsorships: state.api.sponsors.sponsorships.data,
    currency: state.api.events.edit.data.currencySymbol,
    sponsorshipsMeta: state.api.sponsors.sponsorships.meta,
    fetching: state.api.sponsors.sponsorships.fetching,
    meta: state.api.sponsors.sponsorships.meta,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sponsorshipsStatus: (eventId, meta) => {
      dispatch(getSponsorshipStatus(eventId, meta));
    },
    deleteSponsorship: (eventId, sponsorshipId, meta) => {
      dispatch(deleteSponsorship(eventId, sponsorshipId, meta));
    },
    exportSponsorships: (eventId, filter) => {
      dispatch(exportSponsorships(eventId, filter));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sponsorships);
