import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { connect } from "react-redux";
import TableActions from "@layout/TableActions";
import { getPayments, deletePayment } from "@actions/payments";
import SinglePayment from "./SinglePayment";
import moment from "moment";
import DataView from "./partials/Dataview";
import ApiHelper from "@helpers/api-helper";
import { HeaderPagination } from "@layout/Pagination";
import Filters from "./partials/Filters";
import { loadEventPayments, showModal, hideModal } from "@actions";
import { getSponsors } from "@actions/sponsors";

class PaymentsList extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
  }
  componentDidMount() {
    this.props.getPayments(this.props.eventId, undefined, true);
    this.props.getPayments(this.props.eventId);
    this.props.loadSponsors(this.props.accessEvents[this.props.eventId].orgId, {
      rpp: -1,
    });
    this.props.loadEventPayments(this.props.eventId);
  }
  onRender(col, item) {
    switch (col.key) {
      case "eventUserId": {
        let id = "";
        if (item.type === "sponsorship") {
          id = item.orgSponsorId;
        } else {
          id = item.eventUserId;
        }
        return id;
      }
      case "amount": {
        return col.value.toFixed(2);
      }
      case "eventUserFullName": {
        let name = "";
        if (item.type === "sponsorship") {
          name = item.orgSponsorName;
        } else {
          name = item.eventUserFullName;
        }
        return name;
      }
      case "actions": {
        const meta = {
          p: this.props.paymentsListMeta.p,
          rpp: this.props.paymentsListMeta.rpp,
        };
        const newMeta = this.assignMeta(meta);
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SinglePayment
                  item={item}
                  newMeta={this.assignMeta(meta)}
                  hideModal={this.props.hideModal}
                />
              )
            }
            onDelete={() =>
              this.props.deletePayment(this.props.eventId, item.id, newMeta)
            }
          />
        );
      }
      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "transactionDate": {
        if (!col.value) {
          return <div>N/A</div>;
        } else {
          return moment(item.transactionDate).format(ApiHelper.dateFormat);
        }
      }
    }
  }
  assignMeta(meta) {
    if (this.props.paymentsListMeta.type) {
      meta["type"] = this.props.paymentsListMeta.type;
    }
    if (this.props.paymentsListMeta.way) {
      meta["way"] = this.props.paymentsListMeta.way;
    }
    if (this.props.paymentsListMeta.orgSponsorId) {
      meta["orgSponsorId"] = this.props.paymentsListMeta.orgSponsorId;
    }
    if (this.props.paymentsListMeta.eventUserId) {
      meta["eventUserId"] = this.props.paymentsListMeta.eventUserId;
    }
    if (this.props.paymentsListMeta.dateRange) {
      meta["dateRange"] = this.props.paymentsListMeta.dateRange;
    }
    return meta;
  }
  findIfDifferent(val, identifier) {
    let newVal = 0;
    if (identifier === "rpp") {
      if (this.props.paymentsListMeta.rpp !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.paymentsListMeta.rpp;
      }
    } else if (identifier === "p") {
      if (this.props.paymentsListMeta.p !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.paymentsListMeta.p;
      }
    } else if (identifier === "totalRows") {
      if (this.props.paymentsListMeta.totalRows !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.paymentsListMeta.totalRows;
      }
    }
    return newVal;
  }
  render() {
    const columns = {
      id: {
        name: "ID",
      },
      eventUserId: {
        name: "_ID",
      },
      eventUserFullName: {
        name: "Name",
      },
      createdBy: {
        name: "Created By",
      },
      type: {
        name: "Type",
      },
      way: {
        name: "Way",
      },
      createdAt: {
        name: "Created At",
      },
      transactionDate: {
        name: "Transaction Date",
      },
      amount: {
        name: "Amount Paid",
      },
      actions: {
        name: "",
      },
    };

    return (
      <>
        <div className="tab-header">
          <div className="left">
            <Filters />
          </div>
          <div className="right">
            <HeaderPagination
              fetching={this.props.fetching}
              p={this.props.paymentsListMeta.p}
              rpp={this.props.paymentsListMeta.rpp}
              totalRows={this.props.paymentsListMeta.totalRows}
              onChangePage={(data) => {
                const meta = {
                  p: data.p,
                  rpp: data.rpp,
                };
                const newMeta = this.assignMeta(meta);
                this.props.getPayments(this.props.eventId, newMeta);
              }}
            />
            <button
              type="button"
              className="btn create rounded"
              onClick={() => this.props.showModal(<SinglePayment />)}
            >
              Create
            </button>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-payments">
            <div className="row" style={{ paddingTop: 0 }}>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <DataView
                  data={this.props.paymentsListMeta.statisticData}
                  currency={this.props.currency}
                />
              </div>

              <div className="col-md-12">
                <Table
                  id={"subscriptions"}
                  columns={columns}
                  items={this.props.paymentsList}
                  onRender={(col, item) =>
                    this.onRender(col, item, "subscriptions")
                  }
                  updating={!this.props.paymentsListReady}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

PaymentsList.propTypes = {
  getPayments: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  eventId: PropTypes.number,
  paymentsListReady: PropTypes.bool,
  paymentsList: PropTypes.array,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  deletePayment: PropTypes.func.isRequired,
  paymentsListMeta: PropTypes.object,
  currency: PropTypes.string,
  loadEventPayments: PropTypes.func,
  loadSponsors: PropTypes.func,
  accessEvents: PropTypes.object,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    paymentsList: state.api.payments.data,
    paymentsListReady: state.api.payments.ready,
    paymentsListMeta: state.api.payments.meta,
    currency: state.api.events.edit.data.currencySymbol,
    accessEvents: state.appuser.data.accessEvents,
  };
};
const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    getPayments: (eventId, meta, statistics) => {
      dispatch(getPayments(eventId, meta, statistics));
    },
    hideModal: () => dispatch(hideModal()),
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        )
      );
    },
    deletePayment: (eventId, paymentId, meta) => {
      dispatch(deletePayment(eventId, paymentId, meta));
    },
    loadEventPayments: (eventId) => {
      dispatch(loadEventPayments(eventId, undefined, undefined, false));
    },
    loadSponsors: (orgId, meta) => {
      dispatch(getSponsors(orgId, meta));
    },
  };
  return dispatcher;
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsList);
