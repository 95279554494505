import React from "react";
import Select from "@layout/Select";
import PropTypes from "prop-types";

export default class SessionType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props.mode,
      value: props.value,
    };
    this.setValue = this.setValue.bind(this);
  }

  setValue(type, value) {
    this.setState({
      [type]: value,
    });
  }

  render() {
    return (
      <div className="sessiontype-inner">
        <div className="clearfix">
          <div className="col-sm-6">
            <button
              className={`btn${
                this.state.mode === "existing" ? " selected" : ""
              }`}
              type="button"
              onClick={() => this.setValue("mode", "existing")}
            >
              Select from existing
            </button>
          </div>
          <div className="col-sm-6">
            <button
              className={`btn${this.state.mode === "new" ? " selected" : ""}`}
              type="button"
              onClick={() => this.setValue("mode", "new")}
            >
              Create New
            </button>
          </div>
        </div>
        <div className={`mode-${this.state.mode}-wrapper sessiontype-wrapper`}>
          {this.state.mode === "existing" && (
            <div>
              <label className="label-inner" htmlFor="">
                Select a session type
              </label>
              <Select
                options={this.props.options}
                onChange={(val) => this.setValue("value", val)}
                value={this.state.value}
              />
            </div>
          )}
          {this.state.mode === "new" && (
            <div>
              <div className="field-type type-text kmb-text">
                <label className="label-inner" htmlFor="newSessionType">
                  New Session Type
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="newSessionType"
                    placeholder="Please type the name of the new session type"
                    id="newSessionType"
                    onChange={(e) => this.setValue("value", e.target.value)}
                  />
                  <span className="icon icon-edit"></span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SessionType.propTypes = {
  mode: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.object.isRequired,
};
