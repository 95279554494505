import React, { useMemo, useState } from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import { useDispatch, useSelector } from "react-redux";
import SearchAndSelect from "@layout/SearchAndSelect";
import { hideModal } from "@actions";
import { loadUsers } from "@actions/users";
import { createRentData, updateRentData } from "@actions/rentables";
import moment from "moment";

const yesNoKeys = [];
const SingleRentData = ({ h2, eventId, rentableId, item, rentableGroupId }) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(
    item ? `${item.eventUserId} - ${item.eventUserFullName}` : ""
  );
  const parsedItem = useMemo(() => {
    const parsedItem = { ...item };

    if (parsedItem) {
      parsedItem.rentedAtDate = parsedItem.rentedAtDate
        ? moment(parsedItem.rentedAtDate, "YYYY-MM-DD HH:mm").format(
            "DD-MM-YYYY HH:mm"
          )
        : null;
      parsedItem.returnedAtDate = parsedItem.returnedAtDate
        ? moment(parsedItem.returnedAtDate, "YYYY-MM-DD HH:mm").format(
            "DD-MM-YYYY HH:mm"
          )
        : null;
    }

    return parsedItem;
  }, [item]);

  const [userId, setUserId] = useState(item ? item.eventUserId : null);

  const policyId = useSelector((state) => state.page.params.pid);

  const onSelectChange = (userId = null, userName = "") => {
    setUserId(userId);
    setUserName(userName);
  };

  const handleSearch = (query) => {
    return dispatch(
      loadUsers(
        eventId,
        policyId,
        {
          search: query,
          points: false,
          minutes: false,
          groupId: rentableGroupId,
          currentRoom: false,
          subscriptions: false,
          accomodation: false,
          products: false,
          abstracts: false,
        },
        null,
        null,
        null,
        true,
        true
      )
    );
  };

  const handleSubmit = (data) => {
    if (isEmpty(data.errors)) {
      const transformedData = Object.entries(data.value).reduce(
        (acc, [k, v]) => {
          acc[k] = yesNoKeys.includes(k) ? v === "yes" : v;
          return acc[k] === "" ? acc : { ...acc, [k]: acc[k] };
        },
        {}
      );
      if (!transformedData.rentedAtDate) transformedData.rentedAtDate = null;
      if (!transformedData.returnedAtDate)
        transformedData.returnedAtDate = null;

      if (h2 === "Edit") {
        dispatch(
          updateRentData(eventId, rentableId, item.id, transformedData, userId)
        );
      } else {
        dispatch(createRentData(eventId, rentableId, transformedData, userId));
      }
    }
  };

  return (
    <>
      <h2>{h2 === "Edit" ? "Edit data" : "Create new data"}</h2>
      <br />
      <div className="new-staff form-container">
        {
          <div className="form-group">
            <label>Search and select the user from the list*</label>
            <SearchAndSelect
              onSelectChange={onSelectChange}
              selectValue={userName}
              onSearch={(query) => {
                return handleSearch(query);
              }}
              placeholder={userName || "Search participant..."}
            />
          </div>
        }
        <Form
          componentName="rentables"
          formName="data"
          value={h2 === "Edit" ? parsedItem : {}}
          onSubmit={(data) => {
            handleSubmit(data);
          }}
          onCancel={() => dispatch(hideModal())}
        />
      </div>
    </>
  );
};

SingleRentData.propTypes = {
  h2: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  rentableId: PropTypes.number,
  item: PropTypes.object,
  rentableGroupId: PropTypes.number,
};

export default SingleRentData;
