import React from "react";
import PropTypes from "prop-types";
import Search from "@layout/Search";
import TableActions from "@layout/TableActions";
import Switch from "@layout/Switch";
import SingleUser from "./partials/SingleUser";
import { capitalizeFirstLetter } from "@helpers";
import ApiHelper from "@helpers/api-helper";
import { HeaderPagination } from "@layout/Pagination";
import moment from "moment";
import SelectCheckboxes from "@layout/SelectCheckboxes";
import Select from "@layout/Select2";

import { Link } from "react-router-dom";
import Listing from "./Listing";
import Schema from "./Schema";

export default class Crm extends React.Component {
  constructor(props) {
    super(props);
    this.fetched = false;
    this.name = capitalizeFirstLetter(props.mode);
    ["onRender", "onSort"].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.state = {
      tabPressed: "listing",
      search: "",
    };
    this.values = {};
    this.fields = [];
    this.extraFields = [];
  }

  onSort(orderBy) {
    this.props.loadUsers(this.props.orgId, this.props.mode, { orderBy }, false);
  }
  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return (
          <div>
            {item.crmUserProfilePhoto ? (
              <img
                src={item.crmUserProfilePhoto[0].url}
                style={{
                  width: 35,
                  height: 35,
                  borderRadius: 35,
                  objectFit: "cover",
                }}
              />
            ) : (
              <span className="icon-users"></span>
            )}
          </div>
        );
      }

      case "active": {
        return (
          <Switch
            id={`staff-active-${item.id}`}
            isActive={col.value}
            onChange={(active) =>
              this.props.save(
                this.props.orgId,
                { active },
                item.id,
                "json",
                "crm",
                true
              )
            }
          />
        );
      }
      case "assignRoles": {
        return (
          <Link
            className="btn transparent table-button"
            to={`/staff/${item.id}/roles`}
          >
            <span className="icon-manage-users"></span>Assign Roles
          </Link>
        );
      }
      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "updatedAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "speaker": {
        let value = "No";
        if (item.speaker === 1) {
          value = "Yes";
        }
        return value;
      }
      case "actions": {
        return (
          <TableActions
            entity="CRM User"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SingleUser
                  endpoint={"schemas"}
                  h2={"Edit"}
                  orgId={this.props.orgId}
                  id={item.id}
                  value={item}
                  hideModal={this.props.hideModal}
                  schema={this.props.schemas.orglist}
                />
              )
            }
            onDelete={() => this.props.delete(this.props.orgId, item.id)}
          />
        );
      }
    }
  }
  render() {
    const prompt = <span className="icon-columns"></span>;
    const { data, meta } = this.props;
    const name = this.name === "Persons" ? "Person" : this.name;
    const { p, rpp, totalRows } = meta;
    const users = [];
    Object.entries(data).map((user) => {
      users.push({
        ...user[1].info,
        id: user[1].id,
        orgId: user[1].orgId,
        active: user[1].active,
        createdAt: user[1].createdAt,
        updatedAt: user[1].updatedAt,
        verified: user[1].registered,
      });
    });
    this.props.schemas.orglist &&
      Object.keys(this.props.schemas.orglist.data).forEach(() => {
        if (this.props.schemas.orglist.data["meta"]) {
          Object.keys(this.props.schemas.orglist.data["meta"]).forEach(
            (keyMeta) => {
              this.values[keyMeta] =
                this.props.schemas.orglist.data["meta"][keyMeta];
            }
          );
        }
      });
    // The above process could be fit in one loop easily, just copy the second if to the first one
    // but we do not achieve the desired ordering. The linear time is O(n^2) if we fit it in one loop
    // In this case we have O(n) + O(n^2), the difference is negligible, but we get the right order
    const options = {
      ExportUsersAll: "Export to Excel (All Users)",
    };
    if (this.state.search !== "") {
      options["ExportCurrent"] = "Export to Excel ( Current Users )";
    }
    const columns = {};
    Object.entries(this.props.columns).map(([k, v]) => {
      if (v.hasOwnProperty("filter")) {
        v.checked = v.filter ? true : false;
        columns[k] = v;
      }
    });
    delete columns["orgId"];
    delete columns["crmUserProfilePhoto"];
    delete columns["company"];
    delete columns["cosmetic"];
    delete columns["resume"];
    return (
      <div className="list-crm-main">
        <div className="top-bar">
          <div>
            <div className="left"></div>
            <div className="middle">
              <ul>
                <li
                  onClick={() => this.setState({ tabPressed: "listing" })}
                  className={
                    this.state.tabPressed === "listing" ? "selected" : ""
                  }
                >
                  Customers
                </li>
                <li
                  onClick={() => this.setState({ tabPressed: "schema" })}
                  className={
                    this.state.tabPressed === "schema" ? "selected" : ""
                  }
                >
                  Fields
                </li>
              </ul>
            </div>
            <div className="right"></div>
          </div>
          <div>
            <div className="left">
              {this.state.tabPressed === "listing" && (
                <>
                  <SelectCheckboxes
                    static={[
                      "firstName",
                      "lastName",
                      "email",
                      "id",
                      "createdAt",
                    ]}
                    reset={[
                      "cosmetic",
                      "firstName",
                      "lastName",
                      "id",
                      "email",
                      "subscription",
                      "createdAt",
                    ]}
                    className="filters"
                    options={columns}
                    onChange={this.props.changeCrmFilters}
                    fixedPrompt={true}
                    sortable={false}
                    placeholder={prompt}
                  />

                  <Select
                    className="more-actions select-button"
                    border="none"
                    isDropDownButton={true}
                    isSearchable={false}
                    hoverBackgroundColor="#f0f0f4"
                    color={"#516673"}
                    hoverColor={"#153447"}
                    fontWeight={"500"}
                    selectedBackground="#f0f0f4"
                    placeholder={[
                      <span
                        key={"more-options"}
                        style={{ fontSize: 16 }}
                        className="icon-more-options"
                      ></span>,
                    ]}
                    isClearable={false}
                    placeholderInsideSelect={false}
                    options={options}
                    onChange={() => {
                      this.props.addNotification(
                        "This feature is currently disabled! Please request assistance from an administator!",
                        "error"
                      );
                    }}
                  />
                </>
              )}
              {this.state.tabPressed === "listing" && (
                <>
                  <Search
                    onSearch={(search) => {
                      this.setState({ search });
                      this.props.loadUsers(
                        this.props.orgId,
                        this.props.mode,
                        { ...this.props.meta, p: 1, search },
                        false,
                        this.props.meta
                      );
                    }}
                    visible={true}
                    timeout={400}
                  />
                </>
              )}
            </div>

            <div className="right">
              {this.state.tabPressed === "listing" && (
                <HeaderPagination
                  fetching={this.props.fetching}
                  p={meta.p}
                  rpp={meta.rpp}
                  totalRows={meta.totalRows}
                  onChangePage={(data) => {
                    this.props.loadUsers(
                      this.props.orgId,
                      this.props.mode,
                      { ...this.props.meta, p: data.p, rpp: data.rpp },
                      false
                    );
                  }}
                />
              )}
              {this.state.tabPressed === "listing" && (
                <div className="create-crm-user">
                  <button
                    className="btn create"
                    disabled={!this.props.schemas?.orglist?.data?.id}
                    onClick={() =>
                      this.props.showModal(
                        <SingleUser
                          endpoint={"schemas"}
                          h2={name}
                          orgId={this.props.orgId}
                          id={false}
                          schema={this.props.schemas.orglist}
                        />
                      )
                    }
                  >
                    Create new Crm User
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="list-view">
          {this.state.tabPressed === "listing" && (
            <Listing
              p={p}
              rpp={rpp}
              totalRows={totalRows}
              onRender={this.onRender}
              type={"listing"}
            />
          )}
          {this.state.tabPressed === "schema" && (
            <React.Fragment>
              <div className="main-wrapper">
                <Schema />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
Crm.propTypes = {
  orgId: PropTypes.number,
  schemas: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  loadUsers: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  updateSchema: PropTypes.func.isRequired,
  createSchema: PropTypes.func.isRequired,
  updateFields: PropTypes.func.isRequired,
  dataReady: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  activeSort: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  exportAllUsers: PropTypes.func,
  columns: PropTypes.object,
  changeCrmFilters: PropTypes.func,
};
