import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import Confirm from "@layout/Confirm";
import Switch from "@layout/Switch";
import { Link } from "react-router-dom";
import TableActions from "@layout/TableActions";

class Rooms extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.state = {
      wordConfirmed: null,
      toggledCells: [],
    };
    this.handleBody = this.handleBody.bind(this);
    this.excludedArea = null;
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleBody);
    this.props.loadRooms(this.props.eventId);
    this.props.getMassiveEvents(this.props.eventId);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBody);
  }

  toggleActions(e, id) {
    this.excludedArea = e.target.parentNode;

    const toggledCells = [id];

    this.setState({ toggledCells });
  }

  handleBody(e) {
    if (
      this.state.toggledCells.length > 0 &&
      !this.excludedArea.contains(e.target)
    ) {
      this.setState({ toggledCells: [] });
    }
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return <span className="icon-sessions-1"></span>;
      }

      case "allOut": {
        const alloutBody = (
          <div>
            <p>
              This action will permanently remove the users from room with ID:{" "}
              <strong>{item.id}</strong>
            </p>
            <p>
              <span>{item.name}</span>
            </p>
            <p>Are you sure that you want to do this?</p>
          </div>
        );

        return (
          <span className="confirm-holder">
            <Confirm
              onConfirm={() => {
                this.setState(
                  {
                    wordConfirmed: false,
                  },
                  () => this.props.allOut(this.props.event.id, item.id)
                );
              }}
              body={alloutBody}
              className="delete-event"
              confirmText="Confirm All Out"
              disabled={false}
              title="All Out Action! Are you sure?"
            >
              <button type="button" className="btn table-button">
                <span className="icon-all-aout"></span>All Out
              </button>
            </Confirm>
          </span>
        );
      }

      case "scanning": {
        return (
          <Link
            to={`/events/${item.eventId}/${item.id}/scanning`}
            className="btn table-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon-scanning"></span>Scanning
          </Link>
        );
      }
      case "capacity": {
        if (item.capacity) {
          return <div>{item.capacity}</div>;
        } else {
          return <div>N/A</div>;
        }
      }
      case "lockScanner": {
        return (
          <Switch
            id={`lock-${item.id}`}
            isActive={item.lockScanner}
            isLive={true}
            onChange={(lockScanner) =>
              this.props.editRoom(item.eventId, item.id, { lockScanner })
            }
          />
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="room"
            item={item}
            onEdit={() => this.props.roomModal(this.props.event.id, item)}
            onDelete={() =>
              this.props.deleteItem(
                { eventId: item.eventId, roomId: item.id },
                "rooms"
              )
            }
          />
        );
      }
    }
  }

  render() {
    const columns = {
      id: {
        name: "ID",
      },
      name: {
        name: "Room",
      },
      allOut: {
        name: "All Out",
      },
      scanning: {
        name: "Scanning",
      },
      lockScanner: {
        name: "Lock Scanning",
      },
      capacity: {
        name: "Capacity",
      },
      actions: {
        name: "",
      },
    };
    return (
      <>
        <div className="rooms-header tab-header">
          <div className="left">
            <button
              type="button"
              className="btn tab-header-btn"
              onClick={() => this.props.undoModal(this.props.event.id)}
            >
              Undo Massive Events<span className="icon-undo-events"></span>
            </button>
            <button
              type="button"
              className="btn tab-header-btn"
              onClick={() => this.props.hitsModal(this.props.eventId)}
            >
              Upload Hits File<span className="icon-upload"></span>
            </button>
            <button
              type="button"
              className="btn tab-header-btn"
              onClick={this.props.allOutModal}
            >
              Apply All Out ( Past Date )<span className="icon-all-aout"></span>
            </button>
          </div>
          <div className="right">
            <button
              type="button"
              className="btn create rounded"
              onClick={() => this.props.roomModal(this.props.event.id)}
            >
              Create
            </button>
          </div>
        </div>
        <div className="tab-content">
          <Table
            id={"rooms"}
            columns={columns}
            items={this.props.rooms}
            updating={this.props.fetching}
            onRender={this.onRender}
            containerClassName={`table-container `}
          />
        </div>
      </>
    );
  }
}

Rooms.propTypes = {
  allOut: PropTypes.func.isRequired,
  loadRooms: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  getMassiveEvents: PropTypes.func.isRequired,
  event: PropTypes.object,
  editRoom: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  undoModal: PropTypes.func.isRequired,
  roomModal: PropTypes.func.isRequired,
  hitsModal: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  allOutModal: PropTypes.func.isRequired,
};

export default Rooms;
