import {
  CHANGE_SECTION,
  CHANGE_TAB,
  ADJUST_SECTION,
  RESET_MENU,
  RESTORE_SECTIONS,
} from "@actions/editmenu";
import {
  UPDATING_TAB,
  TAB_UPDATED,
  RESTRICT_MENU,
  MENU_FULL_ACCESS,
} from "@actions";
import { RECEIVED_EVENTS_EDIT, FAILED_REQUEST } from "@actions/ajax";
import { getSectionKey, isArray } from "@helpers";

const initialState = {
  sections: {
    basic_info: {
      title: "Basic Setup",
      icon: "icon-setup",
      items: {
        BasicInfo: "Event Info",
        // Cover: "Cover",
        Options: "Features",
        Rooms: "Venue Rooms",
        Policies: "Access Policies",
      },
    },
    registration: {
      title: "Participants",
      icon: "icon-participants",
      items: {
        Participants: "List",
        PolicyGroups: "Groups",
        Pricing: "Subscriptions",
        Fields: "Fields",
        RegistrationSettings: "Settings",
      },
    },
    schedule: {
      icon: "icon-schedule",
      title: "Schedule",
      items: {
        Sessions: "Sessions List",
        SpeakersChairpersons: "Speakers / Chairpersons",
        ScheduleSettings: "Settings",
      },
    },
    accommodation: {
      icon: "icon-accomodation",
      title: "Accommodation",
      items: {
        Hotels: "Hotels List",
        Reservations: "Reservations",
      },
    },

    abstracts: {
      icon: "icon-abstracts",
      title: "Abstracts",
      items: {
        AbstractsList: {
          title: "Abstracts List",
          children: {
            SingleAbstract: {
              title: "View Abstract",
              vertical: false,
            },
          },
        },
        Judges: "Judges",
        Schemas: {
          title: "Settings",
          disableTitle: true,
        },
      },
    },
    products: {
      icon: "icon-products",
      title: "Products",
      items: {
        ProductsList: "Products List",
        RentablesList: "Rentables List",
      },
    },
    client_builder: {
      title: "Client Panel",
      items: {
        AccessLanguage: "Access/Language",
      },
    },
    communication: {
      icon: "icon-communication",
      title: "Communication",
      items: {
        Hooks: "Hook Emails",
        Presets: "Preset Emails",
        Questionnaires: "Questionnaire Emails",
        SendEmails: "Sent Emails",
        EmailSettings: "Settings",
      },
    },
    statistics: {
      visible: false,
      icon: "icon-statistics",
      title: "Statistics",
      items: {
        Statistics: "Statistics",
        Charts: "Charts",
        TotalScans: "Total Scans",
        Peak: "Peak",
      },
    },
    payments: {
      icon: "icon-payments",
      title: "Payments",
      items: {
        PaymentsList: "Payments List",
        Sponsorships: "Sponsorships",
        // Invoices: "Invoices",
        PaymentOptions: "Settings",
      },
    },
    exhibition: {
      visible: true,
      icon: "icon-exhibition-hall",
      title: "Exhibition Hall",
      items: {
        ExhibitorsList: "Exhibitors List",
        DigitalCategories: "Categories",
        DigitalSlots: "Digital Slots",
      },
    },
    settings: {
      icon: "icon-other-settings",
      title: "Other Settings",
      items: {
        CertPrinting: "Certification Settings",
        CardPrinting: "Badge Settings",
        Printers: "Badge Printers",
        ScanningBanner: "Scanning Banner",
        Api: "API",
      },
    },
  },
  activeSection: "basic_info",
  activeTab: "BasicInfo",
  fakeChild: null,
  updating: false,
  exclude: [],
  excludeTabs: [],
  ready: false,
  hasFullAccess: false,
};

const editmenu = (state = initialState, action) => {
  let exclude;

  switch (action.type) {
    case MENU_FULL_ACCESS: {
      const activeTab = Object.keys(
        initialState.sections[action.activeSection].items
      )[0];

      return Object.assign({}, state, {
        ready: true,
        hasFullAccess: true,
        activeSection: action.hasOwnProperty("activeSection")
          ? action.activeSection
          : initialState.activeSection,
        activeTab: action.hasOwnProperty("activeTab")
          ? action.activeTab
          : activeTab,
      });
    }

    case RESTRICT_MENU: {
      const exclude = [];
      const excludeTabs = [];

      let activeSection = state.activeSection;
      let activeTab = state.activeTab;

      for (const [s] of Object.entries(state.sections)) {
        if (!action.access.hasOwnProperty(s)) {
          exclude.push(s);
          if (s === activeSection) {
            activeSection = Object.keys(action.access)[0];
            activeTab = null;
          }
        }
      }

      const items = state.sections[activeSection].items;

      if (isArray(action.access[activeSection])) {
        // we have tab restrictions
        // as well lets find
        // the first available
        for (const [key] of Object.entries(items)) {
          if (!action.access[activeSection].includes(key)) {
            excludeTabs.push(key);
            if (key === activeTab) {
              activeTab = null;
            }
          }
        }
      } else if (action.access[activeSection] === 1) {
        activeTab = Object.keys(items)[0];
      }

      if (activeTab === null) {
        activeTab = action.access[activeSection][0];
      }

      return Object.assign({}, state, {
        exclude,
        activeSection,
        activeTab,
        excludeTabs,
        ready: true,
      });
    }

    case RESET_MENU: {
      return Object.assign({}, state, {
        activeSection: initialState.activeSection,
        activeTab: initialState.activeTab,
        sections: initialState.sections,
        ready: false,
      });
    }

    case RECEIVED_EVENTS_EDIT:
    case RESTORE_SECTIONS:
      exclude = [];
      Object.entries(action.response.data).map(([k, v]) => {
        const result = getSectionKey(k);
        if (result && parseInt(v) === 0) {
          exclude.push(result);
        }
      });

      return Object.assign({}, state, {
        exclude,
      });

    case CHANGE_SECTION:
      return Object.assign({}, state, {
        activeSection: action.section,
        activeTab: action.tab,
        fakeChild: action.fakeChild,
      });

    case CHANGE_TAB:
      return Object.assign({}, state, {
        activeTab: action.tab,
        fakeChild: action.fakeChild,
      });

    case UPDATING_TAB:
      return Object.assign({}, state, {
        updating: true,
      });

    case TAB_UPDATED:
    case FAILED_REQUEST:
      return Object.assign({}, state, {
        updating: false,
      });

    case ADJUST_SECTION:
      exclude = [...state.exclude];
      if (action.action) {
        exclude.splice(exclude.indexOf(action.section), 1);
      } else {
        exclude.push(action.section);
      }

      return Object.assign({}, state, {
        exclude,
      });

    default:
      return state;
  }
};
export default editmenu;
