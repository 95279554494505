import React from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import KMBDropzone from "@layout/KMBDropzone";
import PropTypes from "prop-types";
import XLSHelper from "@helpers/xls-helper";
import KMBLoader from "@layout/KMBLoader";
import Select from "@layout/Select";
import Radio from "@layout/Radio";
import { downloadFile } from "@helpers";
import SubmitContainer from "@layout/SubmitContainer";

const SortableItem = SortableElement(({ value }) => {
  return <li>{value.name}</li>;
});

const SortableList = SortableContainer(({ items, mergedSettings }) => {
  return (
    <ol className="fields-wrapper">
      {items.map((k, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={mergedSettings[k]}
          />
        );
      })}
    </ol>
  );
});

export default class BulkImportUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFields: [],
      file: false,
      preview: [],
      buildingPreview: false,
      extension: null,
      seperator: "comma",
      ignoreFirstRow: false,
    };

    this.selectField = this.selectField.bind(this);
    this.mergeSettings = this.mergeSettings.bind(this);
    this.processCsv = this.processCsv.bind(this);
    this.mergedSettings = this.mergeSettings(props.policy);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.changeSeperator = this.changeSeperator.bind(this);
    this.ignoreFirstRow = this.ignoreFirstRow.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
  }

  mergeSettings(policy) {
    return Object.assign({}, policy.settings, policy.extraSettings);
  }

  exportTemplate(e, excel = false) {
    e.preventDefault();
    const rows = [];
    const header = [];
    const demoRow = [];
    const { urlAlias } = this.props.event;
    const fileName = `${urlAlias}-import-template`;
    for (let i = 0; i < this.state.activeFields.length; i++) {
      const field = this.state.activeFields[i];

      header.push(
        this.mergedSettings[field].required === 1
          ? `${this.mergedSettings[field].name} *`
          : this.mergedSettings[field].name
      );
      demoRow.push("Dummy Data");
    }

    rows.push(header);
    rows.push(demoRow);
    if (excel) {
      // excel template
      return XLSHelper.exportDefaultXLS(rows, fileName);
    }
    // csv template
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach((rowArray) => {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });
    downloadFile(
      encodeURI(csvContent),
      fileName + ".csv",
      document.getElementsByClassName("exportables")[0]
    );
  }

  ignoreFirstRow(value) {
    const ignoreFirstRow = value === "yes";
    this.setState({ ignoreFirstRow }, () => {
      this.previewFile(this.state.file[0], this.state.seperator).then(
        ({ preview }) => this.setState({ preview })
      );
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState({
      activeFields: arrayMove(this.state.activeFields, oldIndex, newIndex),
    });
  }

  changeSeperator(seperator) {
    this.setState(
      {
        seperator,
      },
      () => {
        this.previewFile(this.state.file[0], seperator).then(({ preview }) =>
          this.setState({ preview, buildingPreview: false })
        );
      }
    );
  }

  selectField(e) {
    const id = e.target.id;
    const activeFields = [...this.state.activeFields];
    const index = activeFields.indexOf(id);
    if (index !== -1) {
      activeFields.splice(index, 1);
    } else {
      activeFields.push(id);
    }
    this.setState({ activeFields });
  }

  previewFile(file, seperator = "comma") {
    return new Promise((resolve, reject) => {
      const extension = file.name.split(".").pop();
      const reader = new FileReader();
      // Read file into memory as UTF-8

      extension === "csv"
        ? reader.readAsText(file)
        : reader.readAsBinaryString(file);

      const { ignoreFirstRow } = this.state;

      // Handle errors load
      reader.onload = (e) => {
        const tfile = e.target.result;
        const preview =
          extension === "csv"
            ? this.processCsv(tfile, seperator, ignoreFirstRow)
            : XLSHelper.read(tfile, ignoreFirstRow);
        preview !== false && resolve({ preview, extension });
      };
      reader.onerror = (e) => {
        reject(e.target.error.name);
      };
    });
  }

  processCsv(csv, seperator = "comma", ignoreFirstRow = false) {
    seperator = seperator === "comma" ? "," : ";";

    const rows = ignoreFirstRow ? 2 : 1;

    const allTextLines = csv.split(/\r\n|\n/);
    const preview = [];
    if (allTextLines.length === 0) {
      return preview;
    }
    for (let i = 0; i <= rows; i++) {
      if (ignoreFirstRow && i === 0) continue;
      const data = allTextLines[i].split(seperator);
      const tarr = [];
      for (let j = 0; j < data.length; j++) {
        tarr.push(data[j]);
      }
      preview.push(tarr);
    }

    return preview;
  }

  render() {
    const policy = this.props.policy;
    const { extension, seperator } = this.state;

    return (
      <div className="form-container bulk-import">
        <h2>Import Users</h2>
        <p className="subtitle">for {policy.name} Policy</p>
        <div className="guide">
          <h3>Fields Selection</h3>
          <p>
            Please select from the above list the fields that are present to the
            CSV or XLS file that you would like to select by checking. You can
            rearrange the order in which the fileds are present in the file. If
            the file uploaded has fields that you do not wish to include in the
            created users data, just uncheck the field. Once you are happy with
            the order click the import button.
          </p>
        </div>
        <div className="available-fields">
          <h3>Available User Fields</h3>
          <ul className="fields-wrapper">
            {Object.entries(this.mergedSettings).map(([k, v]) => {
              const isCrmAndFieldIsSpeaker =
                policy.saveUsersToCrm && k === "speaker";
              if (
                v.used === 0 ||
                v.system_gen === 1 ||
                k === "password" ||
                isCrmAndFieldIsSpeaker
              ) {
                return null;
              }
              return (
                <li className="kmb-checkbox" key={k}>
                  <input
                    type="checkbox"
                    id={k}
                    onChange={this.selectField}
                    checked={this.state.activeFields.includes(k)}
                  />
                  <label htmlFor={k}>
                    {v.name}
                    {v.required === 1 ? " *" : ""}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="selected-fields">
          <h3>Selected Fields in CSV or XLS file</h3>
          {this.state.activeFields.length > 0 && (
            <SortableList
              items={this.state.activeFields}
              mergedSettings={this.mergedSettings}
              onSortEnd={this.onSortEnd}
              lockAxis="y"
            />
          )}
          {this.state.activeFields.length === 0 && (
            <p>No fields selected yet.</p>
          )}
          {this.state.activeFields.length > 0 && (
            <span className="exportables">
              <a
                href="#"
                className="export-template"
                onClick={(e) => this.exportTemplate(e)}
              >
                Download CSV Template
              </a>
              <a
                href="#"
                className="export-template"
                onClick={(e) => this.exportTemplate(e, true)}
              >
                Download XLSX Template
              </a>
            </span>
          )}
        </div>
        <div className="drop-area">
          <h3>CSV or XLS file</h3>
          <KMBDropzone
            active={this.state.activeFields.length > 0}
            promptMessage={[
              <span key="decorative" className="icon-cloud"></span>,
            ]}
            accept=".csv,.xls,.xlsx"
            styleType="simple"
            onFileRemove={() => this.setState({ file: false, preview: [] })}
            onDropRejected={(message) => {
              this.props.addNotification(message, "error");
              this.setState({
                file: false,
                preview: [],
                extension: null,
                seperator: "comma",
              });
            }}
            onDropAccepted={(file) => {
              this.setState(
                {
                  file,
                  buildingPreview: true,
                  extension: null,
                  seperator: "comma",
                },
                () => {
                  this.previewFile(this.state.file[0])
                    .then(({ preview, extension }) =>
                      this.setState({
                        preview,
                        buildingPreview: false,
                        extension,
                      })
                    )
                    .catch((err) => {
                      this.setState(
                        { buildingPreview: false },
                        this.props.addNotification("error", err)
                      );
                    });
                }
              );
            }}
            style={{
              width: "100%",
              height: "auto",
              borderWidth: 1,
              borderColor: "#1DA4CF",
              borderStyle: "dashed",
              borderRadius: 4,
              backgroundImage: "none",
              padding: "25px 5px",
              backgroundColor: "#fff",
            }}
            activeStyle={{
              borderStyle: "dashed",
              borderColor: "#1DA4CF",
            }}
            acceptStyle={{
              borderStyle: "dashed",
              borderColor: "#14DA9E",
            }}
            rejectStyle={{
              borderStyle: "dashed",
              borderColor: "blue",
            }}
          ></KMBDropzone>
          {this.state.buildingPreview && (
            <KMBLoader rows={15} padding={24} height={53} />
          )}
          {this.state.preview.length > 0 && (
            <div className="preview-panel">
              {extension === "csv" && (
                <div className="csv-seperator">
                  <h3>CSV Seperator</h3>
                  <Select
                    options={{
                      comma: "Comma",
                      semicolon: "Semicolon",
                    }}
                    value={seperator}
                    placeholderInsideSelect={false}
                    onChange={(sep) => this.changeSeperator(sep)}
                  />
                </div>
              )}
              <div className="ignore-first">
                <h3>Ignore First Row ( Header )</h3>
                <div className="form-container">
                  <div className="field-type type-radio kmb-radio">
                    <Radio
                      checked={this.state.ignoreFirstRow ? "yes" : "no"}
                      onChange={(val) => this.ignoreFirstRow(val)}
                    />
                  </div>
                </div>
              </div>
              <h3>Preview File ( Two first rows )</h3>
              <div
                className="med-table-container "
                style={{ overflow: "auto" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      {this.state.activeFields.map((f, i) => (
                        <th key={`a-${i}`}>{this.mergedSettings[f].name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.preview.map((d, index) => (
                      <tr key={`row-${index}`}>
                        {this.state.activeFields.map((f, i) => (
                          <td key={`pre-${index}-${i}`}>{d[i]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p>If you are happy with the result, please click import.</p>
            </div>
          )}
        </div>
        <SubmitContainer
          onCancel={this.props.hideModal}
          onSubmit={() =>
            this.props.importUsers(
              {
                fields: this.state.activeFields,
                file: this.state.file[0],
                seperator:
                  this.state.extension === "csv" ? this.state.seperator : null,
                ignoreFirstRow: this.state.ignoreFirstRow,
              },
              policy.id,
              policy.eventId
            )
          }
        />
      </div>
    );
  }
}

BulkImportUsers.propTypes = {
  policy: PropTypes.object.isRequired,
  addNotification: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  importUsers: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};
